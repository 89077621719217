import * as CL from '@design-system/component-library';
import { CompanyInfoResponse, ContactRole, ContactStatus, ContactType } from '../../generated/api/models.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { addEmptyFieldValidationError } from '../../common/utils/errorUtils.js';
import { addMsg, firstNameMsg, lastNameMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { CommonError } from '../../common/types/errors.js';
import type { Contact, ContactPerson } from '../../generated/api/models.js';

export interface UpsertContactDialogProps {
  header: string;
  body?: string;
  isSubmitInProgress?: boolean;
  disableEditing?: UpsertContactFields[];
  errors?: CommonError[];
  contact?: Contact;
  saveText?: string;

  onCloseDialog: () => void;
  onCloseDialogHook?: () => void;
  onSubmitDialog: (contact: Contact, validationErrors?: CommonError[]) => void;
}

export enum UpsertContactFields {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export function getErrorsFromUpsertContactDialog(
  contact: Contact,
  customerType?: CompanyInfoResponse.CustomerTypeEnum,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (contact.person) {
    // If passivating contact, then bypass all validations. There are contacts that do not have email and
    // phoneNumber information even though contact person's schema says these are mandatory because such
    // validation does not exist while creating new contact during ordering. When doing upsert with contactStatus as
    // PASSIVE, form validation triggers errors if empty fields and user is not able to remove contact.
    if (contact.contactStatus === ContactStatus.PASSIVE) {
      return validationErrors;
    }
    if (customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS && !contact.person.costCenter) {
      addEmptyFieldValidationError(errors, 'costCenter');
    }
    if (!contact.person.email) {
      addEmptyFieldValidationError(errors, 'person.email');
    }
    if (contact.person.firstName.length === 0) {
      addEmptyFieldValidationError(errors, 'person.firstName');
    }
    if (contact.person.lastName.length === 0) {
      addEmptyFieldValidationError(errors, 'person.lastName');
    }
    if (!contact.person.phoneNumber) {
      addEmptyFieldValidationError(errors, 'person.phoneNumber');
    }
  } else if (contact.userContact) {
    if (contact.userContact.firstName.length === 0) {
      addEmptyFieldValidationError(errors, 'contact.firstName');
    }
    if (contact.userContact.lastName.length === 0) {
      addEmptyFieldValidationError(errors, 'contact.lastName');
    }
    if (!contact.userContact.phoneNumber) {
      addEmptyFieldValidationError(errors, 'contact.phoneNumber');
    }
    if (!contact.userContact.email) {
      addEmptyFieldValidationError(errors, 'contact.email');
    }
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export interface UpsertContactFormProps {
  contact?: Contact;
  onSubmit: (contact: Contact) => void;
  onCancel: () => void;
  isSubmitInProgress?: boolean;
  disableEditing?: UpsertContactFields[];
  saveText?: string;
}

const UpsertContactForm = ({
  contact,
  onSubmit,
  onCancel,
  isSubmitInProgress,
  disableEditing,
  saveText,
}: UpsertContactFormProps) => {
  const methods = useForm<ContactPerson>({ mode: 'all', defaultValues: { ...contact?.person } });
  const { handleSubmit } = methods;

  const onSubmitForm = async (formValues: ContactPerson) => {
    onSubmit({
      ...contact,
      contactType: ContactType.PERSON,
      person: { ...formValues, roles: contact?.person?.roles || [ContactRole.BILLING_CONTACT] },
    });
  };

  return (
    <FormProvider {...methods}>
      <form className={dsClass.MARGIN_TOP_3} onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <Name
          name="firstName"
          label={t.AIK7(firstNameMsg)}
          placeholder={t.AIK7(firstNameMsg)}
          disabled={disableEditing && disableEditing.includes(UpsertContactFields.FIRST_NAME)}
        />
        <Name
          name="lastName"
          label={t.Y41S(lastNameMsg)}
          placeholder={t.Y41S(lastNameMsg)}
          disabled={disableEditing && disableEditing.includes(UpsertContactFields.LAST_NAME)}
        />
        <PhoneNumber disabled={disableEditing && disableEditing.includes(UpsertContactFields.PHONE)} />
        <Email disabled={disableEditing && disableEditing.includes(UpsertContactFields.EMAIL)} />
        <ModalCloseAndSubmitButtons
          onCancel={onCancel}
          onSubmit={() => {}}
          submitButtonText={saveText ? saveText : t.VKFM(addMsg)}
          submitting={isSubmitInProgress}
        />
      </form>
    </FormProvider>
  );
};

export const UpsertContactDialog = ({
  header,
  body,
  contact,
  disableEditing,
  onCloseDialog,
  onCloseDialogHook,
  onSubmitDialog,
  isSubmitInProgress,
  errors,
  saveText,
}: UpsertContactDialogProps) => {
  // create new defaults to ContactType.PERSON
  const obj: Contact = contact
    ? contact
    : {
        contactType: ContactType.PERSON,
        person: {
          email: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          roles: [ContactRole.BILLING_CONTACT],
        },
      };

  if (![ContactType.LIMITED_INFORMATION_PERSON, ContactType.PERSON].includes(obj.contactType)) {
    throw new Error(
      `Only editing contact of type PERSON / LIMITEDINFORMATIONPERSON allowed and current is ${obj.contactType}`
    );
  }

  const generalErrors: CommonError[] | undefined = errors ? errors.filter(error => !error.field) : undefined;
  return (
    <DialogWrapper
      closeable
      header={header}
      onCloseDialog={() => {
        if (onCloseDialogHook) {
          onCloseDialogHook();
        }
        onCloseDialog();
      }}
    >
      {body && <p>{body}</p>}
      {generalErrors &&
        generalErrors.map((error, i) => (
          <CL.Disclaimer
            className={dsClass.TEXT_M}
            icon={<CL.Icon icon="warning" />}
            key={i}
            text={error.message}
            visible
          />
        ))}
      {
        obj.contactType === ContactType.PERSON && obj.person ? (
          <UpsertContactForm
            contact={contact}
            onSubmit={onSubmitDialog}
            onCancel={onCloseDialog}
            isSubmitInProgress={isSubmitInProgress}
            disableEditing={disableEditing}
            saveText={saveText}
          />
        ) : null /* TODO: Implement common function editing */
      }
    </DialogWrapper>
  );
};
