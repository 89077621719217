import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';
import type { Subscription } from '../../../generated/api/subscription.js';

export interface EppPendingRedeemDisclaimerProps {
  subscription: Subscription;
}

export const EppPendingRedeemDisclaimer = ({ subscription }: EppPendingRedeemDisclaimerProps) => {
  return (
    <CL.Disclaimer
      title={t.WYE7('There is an open redeem request for the current device')}
      icon={<CL.Icon icon="clock" color="blue-600" />}
      className={`${dsClass.BACKGROUND_COLOR_BLUE_100} ${dsClass.MARGIN_BOTTOM_5}`}
      visible
    >
      <div className={dsClass.TEXT_S}>{`${t.LN7R('User has submitted a redeem request of device')}: ${
        subscription.subscriptionName
      }. ${t.VQKT('The redeem request must be approved separately.')}`}</div>
    </CL.Disclaimer>
  );
};
