import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { inUseMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';

export const SupportCaseNotification = () => (
  <CL.Disclaimer className={dsClass.MARGIN_VERTICAL_3} icon={<CL.Icon icon="information" aria-hidden="true" />} visible>
    <p>
      {t.JNNJ(
        'Changes to the setting must be made through Elisa customer service. Leave a message to customer service.'
      )}
    </p>
    <br />
    <CL.Link linkHref={paths.SUPPORT_CASE_NEW}>{t.WVHG('Leave a message to customer service')}</CL.Link>
  </CL.Disclaimer>
);

export const MfaAlreadyEnabledNotification = () => (
  <>
    <h4 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_2}`}>{t.QQFY('Two-factor authentication')}</h4>
    <CL.Icon icon="record" type="filled" size="s" color="green-600" />
    <span className={dsClass.MARGIN_LEFT_2}>{t.V34H(inUseMsg)}</span>
    <SupportCaseNotification />
  </>
);
