import * as CL from '@design-system/component-library';
import * as React from 'react';
import { useFormikContext } from 'formik';
import type { CLSelectGroupProduct } from '../../../common/types/componentLibrary';
import type { FormikValues } from 'formik';

export interface ContactOrPurposeSelectGroupProps {
  children: React.ReactElement[];
  selectedItem?: string;
  className?: string;
  label?: string;
  name?: string;
}

export const ContactOrPurposeSelectGroup = (props: ContactOrPurposeSelectGroupProps) => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const products: CLSelectGroupProduct[] = props.children
    .filter(child => !child.props.hidden)
    .map(child => ({
      id: child.props.id,
      selected: child.props.selected,
      name: child.props.title,
      icon: child.props.icon,
    }));

  const selectedChildComponent =
    props.children.find(child => child.props.id === props.selectedItem) || props.children[0];

  const fieldName = props.name || 'selectGroup';
  return (
    <>
      <CL.SelectGroup
        className={props.className}
        name={props.name}
        products={products}
        onChange={value => {
          if (value) {
            setFieldValue(fieldName, value);
          }
        }}
        value={props.selectedItem}
      />
      {selectedChildComponent}
    </>
  );
};
