import * as CL from '@design-system/component-library';
import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  SearchFilters,
  Table,
  TableUrlParams,
} from '../Table/index.js';
import { Highlight } from '../Highlight/Highlight.js';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { ModificationStatus } from './ModificationStatus.js';
import { TimestampColumn } from './TimestampColumn.js';
import {
  companyMsg,
  companysShareOfFeeMsg,
  contractPeriodMsg,
  draftMsg,
  modifiedMsg,
  monthMsg,
  newDeviceListMsg,
  productTypeMsg,
  publishedMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCatalogStatus, getCorporateShareAsText, productTypeDetails } from '../../common/utils/catalogUtils.js';
import { getCompanyName, getPrimaryMdmId } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { CLTableRow } from '../../common/types/componentLibrary';
import type {
  Catalog,
  CatalogSearchResponse,
  VirtualCatalogAggregationsResponse,
  VirtualCatalogAggregationsResponseContractPeriod,
  VirtualCatalogAggregationsResponseProductType,
  VirtualCatalogAggregationsResponseStatus,
  VirtualCatalogHeader,
  VirtualCatalogStatus,
} from '../../generated/api/models.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';

import './CatalogList.scss';

export interface CatalogListProps {
  virtualCatalogs?: CatalogSearchResponse[];
  aggregations?: VirtualCatalogAggregationsResponse;
  totalItems: number;
  searchInput?: string;
  productTypeFilter?: string;
  contractPeriodFilter?: string;
  statusFilter?: string;
  authenticatedUser?: RealAuthenticatedUserState;
}

// Used in the type guard to let typescript know that either draft or published catalog (or both) exist
type VirtualCatalogWithPublishedOrDraft = VirtualCatalogHeader &
  (
    | { draft: Catalog; published: Catalog }
    | { draft: Catalog; published?: never }
    | { draft?: never; published: Catalog }
  );

const getColumns = () => [
  { key: 'publishedOrDraftName', label: t.C84H('Product'), sortable: true },
  { key: 'publishedOrDraftProductType', label: t.R6HP(productTypeMsg), sortable: true },
  { key: 'publishedOrDraftContractPeriod', label: t.KIEG(contractPeriodMsg), sortable: true },
  { key: 'publishedOrDraftCorporateShare', label: t.TH83(companysShareOfFeeMsg), sortable: true },
  { key: 'publishedOrDraftLastModified', label: t.PM1H('Last modified'), sortable: true },
  { key: 'publishedOrDraftStatus', label: t.ASQT(statusMsg), sortable: true },
  { key: 'company', label: t.KJTS(companyMsg), sortable: false },
];

const valueOrDash = (value?: string) => value ?? '—';

export const CatalogList = (props: CatalogListProps) => {
  const {
    virtualCatalogs = [],
    aggregations,
    totalItems,
    searchInput,
    productTypeFilter,
    contractPeriodFilter,
    statusFilter,
    authenticatedUser,
  } = props;
  const navigate = useNavigate();
  const searchTerms = searchInput?.split(' ') || [];
  const [filtersOpen, setFiltersOpen] = useState(false);
  const searchParams = useSearchParams<DefaultListSearchParams>();

  const rows: CLTableRow[] = virtualCatalogs.map((catalogSearchResponse: CatalogSearchResponse) => {
    const catalog = catalogSearchResponse.result as VirtualCatalogWithPublishedOrDraft;
    const { name, productType, contractPeriod, corporateShare, lastModified } = catalog.draft ?? catalog.published;
    return {
      publishedOrDraftName: (
        <Link
          to={`${generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode: catalog.catalogCode })}?mdmId=${catalog.accountMasterId}`}
        >
          <Highlight text={name} highlights={searchTerms} />
        </Link>
      ),
      publishedOrDraftProductType: productType && productTypeDetails()[productType].displayValue,
      publishedOrDraftContractPeriod: valueOrDash(contractPeriod ? `${contractPeriod} ${t.XXVX(monthMsg)}` : undefined),
      publishedOrDraftCorporateShare: valueOrDash(corporateShare ? getCorporateShareAsText(corporateShare) : undefined),
      publishedOrDraftLastModified: <TimestampColumn timestamp={lastModified} />,
      publishedOrDraftStatus: <ModificationStatus status={getCatalogStatus(catalog)} inTable={true} />,
      company: getCompanyName(authenticatedUser, catalog.accountMasterId),
    };
  });

  const getProductTypeFilterGroup = (): SearchFilterGroup => {
    const items = aggregations?.productType?.map(
      (productTypeAggregation: VirtualCatalogAggregationsResponseProductType) => {
        return {
          checked: productTypeFilter?.split('|').includes(productTypeAggregation.name.toString()),
          itemsCount: productTypeAggregation.count,
          label: () => productTypeDetails()[productTypeAggregation.name].displayValue,
          value: productTypeAggregation.name.toString(),
        };
      }
    );

    return {
      items: items || [],
      label: () => t.R6HP(productTypeMsg),
      value: 'productType',
    };
  };

  const getContractPeriodFilterGroup = (): SearchFilterGroup => {
    const items = aggregations?.contractPeriod?.map(
      (contractPeriodAggregation: VirtualCatalogAggregationsResponseContractPeriod) => {
        return {
          checked: contractPeriodFilter?.split('|').includes(contractPeriodAggregation.name.toString()),
          itemsCount: contractPeriodAggregation.count,
          label: () => `${contractPeriodAggregation.name} ${t.XXVX(monthMsg)}`,
          value: contractPeriodAggregation.name.toString(),
        };
      }
    );

    return {
      items: items || [],
      label: () => t.KIEG(contractPeriodMsg),
      value: 'contractPeriod',
    };
  };

  const getStatusTranslation = (status: VirtualCatalogStatus) => {
    switch (status) {
      case 'ACTIVE':
        return t.JN25(publishedMsg);
      case 'DRAFT':
        return t.ZJQG(draftMsg);
      case 'MODIFIED':
        return t.RUFL(modifiedMsg);
    }
  };

  const getStatusFilterGroup = (): SearchFilterGroup => {
    const items = aggregations?.status?.map((statusAggregation: VirtualCatalogAggregationsResponseStatus) => {
      return {
        checked: statusFilter?.split('|').includes(statusAggregation.name),
        itemsCount: statusAggregation.count,
        label: () => getStatusTranslation(statusAggregation.name),
        value: statusAggregation.name,
      };
    });

    return {
      items: items || [],
      label: () => t.ASQT(statusMsg),
      value: TableUrlParams.STATUS,
    };
  };

  const getCatalogSearchFilterGroups = () => {
    const productTypeFilterGroup = getProductTypeFilterGroup();
    const contractPeriodFilterGroup = getContractPeriodFilterGroup();
    const statusFilterGroup = getStatusFilterGroup();
    return contractPeriodFilterGroup.items.length > 0 && statusFilterGroup.items.length > 0
      ? [productTypeFilterGroup, contractPeriodFilterGroup, statusFilterGroup]
      : [];
  };

  return (
    <div className="of-catalog-list-container">
      <div className="of-catalog-list">
        <div
          className={`of-new-catalog-button ${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_FLEX_END} ${dsClass.MARGIN_TOP_4}`}
        >
          <CL.Button
            color="primary"
            onClick={() => {
              navigate(`${paths.COMPANY_INFO_CATALOG_LIST_NEW}?mdmId=${getPrimaryMdmId(authenticatedUser)}`, {
                state: { catalog: {} },
              });
            }}
          >
            {`+ ${t.MPFC(newDeviceListMsg).toUpperCase()}`}
          </CL.Button>
        </div>
        <ConsolidatedListView
          authenticatedUser={authenticatedUser}
          listElement={<Table columns={getColumns()} rows={rows} noItemsText={t.TFX1('No device lists')} />}
          listSearch={
            <ListSearch
              filtersOpen={filtersOpen}
              setFiltersOpen={setFiltersOpen}
              onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
            />
          }
          searchFilters={
            <SearchFilters
              displayActiveFiltersAsTiles={!filtersOpen}
              filterGroups={getCatalogSearchFilterGroups()}
              onModalClose={() => setFiltersOpen(false)}
            />
          }
          listPagination={<ListPagination totalItems={totalItems} />}
        />
      </div>
    </div>
  );
};
