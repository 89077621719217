import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import type { AccountManager } from '../../generated/api/models.js';

import './AccountManagerDetailsCard.scss';

export interface AccountManagerDetailsCardProps {
  accountManager: AccountManager;
}

interface ReserveMeetingButtonContentProps {
  reserveMeetingLink: string;
}

const AccountManagerCurrentStoreInfo = ({ accountManager }: AccountManagerDetailsCardProps) => (
  <div className={dsClass.MARGIN_BOTTOM_3}>
    <span className={dsClass.MARGIN_RIGHT_2}>{accountManager.currentStore}</span>
    <a className={dsClass.TEXT_NOWRAP} href="https://elisa.fi/myymalat/#map">
      <CL.Icon className="pin-icon" size="s" icon="pin" type="filled" />
      <span>{t.HAA2('Shop locations')}</span>
    </a>
  </div>
);

const AccountManagerAddressInfo = ({ accountManager }: AccountManagerDetailsCardProps) => (
  <div className={dsClass.MARGIN_BOTTOM_3}>
    <div>{accountManager.streetAddress}</div>
    <div>
      {accountManager.postalCode} {accountManager.city}
    </div>
  </div>
);

const AccountManagerPhoneInfo = ({ accountManager }: AccountManagerDetailsCardProps) => (
  <div className={dsClass.MARGIN_BOTTOM_1}>
    <span>{t.VG7P('Phone: ')}</span>
    <a href={`tel:${accountManager.phoneNumber}`}>{accountManager.phoneNumber}</a>
  </div>
);

const AccountManagerEmailInfo = ({ accountManager }: AccountManagerDetailsCardProps) => (
  <div>
    <span>Email: </span>
    <a href={`mailto:${accountManager.emailAddress}`} rel="noopener noreferrer" target="_blank">
      {accountManager.emailAddress}
    </a>
  </div>
);

const ReserveMeetingButtonContent = ({ reserveMeetingLink }: ReserveMeetingButtonContentProps) => (
  <div className={dsClass.MARGIN_TOP_3}>
    <CL.Link
      linkStyle="link-button"
      buttonColor="primary"
      buttonSize="m"
      linkTarget="_blank"
      linkHref={reserveMeetingLink}
    >
      <CL.Icon className={dsClass.BUTTON_ICON} icon="calendar" type="filled" />
      <span>{t.D2W8('Book appointment')}</span>
    </CL.Link>
  </div>
);

const AccountManagerDetailsContent = ({ accountManager }: AccountManagerDetailsCardProps) => (
  <>
    <h4 className={dsClass.MARGIN_BOTTOM_2}>{accountManager.name}</h4>
    {accountManager.currentStore && <AccountManagerCurrentStoreInfo accountManager={accountManager} />}
    {!accountManager.currentStore &&
      accountManager.streetAddress &&
      accountManager.postalCode &&
      accountManager.city && <AccountManagerAddressInfo accountManager={accountManager} />}
    {accountManager.phoneNumber && <AccountManagerPhoneInfo accountManager={accountManager} />}
    {accountManager.emailAddress && <AccountManagerEmailInfo accountManager={accountManager} />}
    {accountManager.reserveMeetingLink && (
      <ReserveMeetingButtonContent reserveMeetingLink={accountManager.reserveMeetingLink} />
    )}
  </>
);

export const AccountManagerDetailsCard = ({ accountManager }: AccountManagerDetailsCardProps) => (
  <div className="of-accountmanager-details-card">
    <CL.Card
      type="white"
      heading={<h3>{t.BTEU('Your account manager')}</h3>}
      content={<AccountManagerDetailsContent accountManager={accountManager} />}
    />
  </div>
);
