import { AddOnPurpose, CommercialProductType, OnlineModelCategory } from '../../../generated/api/models.js';
import { AdditionalFieldType, ProductType } from './shoppingCartEnums.js';
import { LEGACY_SALES_PRODUCT_NAME, getConfiguredCommercialProducts } from '../../../common/utils/cartProductUtils.js';
import {
  activationFeeWithVarMsg,
  activationFeesWithVarMsg,
  deductibleMsg,
  monthlyChargeSummaryMsg,
  monthlyChargesMsg,
  monthsMsg,
  noFixedTermMsg,
  oneTimePaymentMsg,
  recurringChargesMsg,
  singleFaresMsg,
  squareTradeDeductibleMsg,
  sumPerMonthMsg,
  t,
  vatPercentageMsg,
} from '../../../common/i18n/index.js';
import { centsToEuros, formatSum, formatSumToString } from '../../../common/utils/priceUtils.js';
import { getEffectiveVAT } from '../../../components/ProductDetails/utils/productDetailsUtils.js';
import { getShoppingCart } from '../localStorageUtils.js';
import { getTotalSums } from '../../../common/utils/commercialProductUtils.js';
import { isSquareTradeAddOn } from '../../../common/utils/addOnUtils.js';
import type { AddOn, AddOnAssociation, CommercialProduct, Offer, OnlineModel } from '../../../generated/api/models.js';
import type {
  AddOnBundle,
  BundleItemsEntity,
  CartItemType,
  PeriodicEntity,
  Product,
} from './shoppingCartInterfaces.js';
import type { AddToCartAction, UpdateCartItemAction, UpdateCartItemPaymentAction } from '../../actions/index.js';
import type { CLShoppingCartAddon } from '../../../common/types/componentLibrary.js';
import type { SelectedAddOnBundle } from '../../../components/ProductDetails/ProductDetails.js';
import type { ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../../../common/types/checkout.js';
import type { ShoppingCartProps } from '@design-system/component-library';

const normalizePrice = (price: number | undefined) => {
  return price ? centsToEuros(price) : 0;
};

interface PriceObj {
  onetime: {
    price: number;
  };
  periodic: PeriodicEntity[];
}

const createOnetimeAndPeriodicPricesForSubscriptions = (cp: CommercialProduct, offer: Offer) => {
  const onetimePrice = normalizePrice(
    offer.commercialProducts
      .flatMap(commercialProduct => commercialProduct.oneTimeCharge || 0)
      .reduce((previous, current) => (current ? current : previous))
  );
  const monthlyPrice = normalizePrice(
    offer.commercialProducts
      .flatMap(commercialProduct => commercialProduct.monthlyRecurringCharge || 0)
      .reduce((previous, current) => (current ? current : previous))
  );
  const onetime = {
    originalPrice: onetimePrice,
    price: onetimePrice,
  };
  const periodic = [
    {
      originalPrice: monthlyPrice,
      price: monthlyPrice,
      payments: 0,
      period: 1,
      billingPeriod: cp.billingPeriod,
    },
  ];
  return {
    onetime,
    periodic,
  };
};

const createOnetimeAndPeriodicPricesForDevices = (cp: CommercialProduct) => {
  const priceObj: PriceObj = {
    onetime: { price: 0 },
    periodic: [],
  };

  if (cp.payments) {
    if (cp.payments === 1) {
      priceObj.onetime = { price: normalizePrice(cp.oneTimeCharge) };
    } else if (cp.payments > 1) {
      const periodicObj = {
        payments: cp.payments,
        // this is a bit unclear, not available in new model and doesn't seem to serve purpose
        // always appears as 1
        period: 1,
        price: normalizePrice(cp.monthlyRecurringCharge),
        priceSubType: cp.productSubType,
      };
      priceObj.periodic = [periodicObj];
    }
  }
  return priceObj;
};

const createCartItemBundleItemPrice = (cp: CommercialProduct, offer: Offer) => {
  return {
    guids:
      cp.productType === CommercialProductType.SALES_PRODUCT
        ? offer.commercialProducts.flatMap(c => c.commercialProductCode)
        : [cp.commercialProductCode],
    ...(cp.productType === CommercialProductType.SALES_PRODUCT || cp.productType === CommercialProductType.MOBILE
      ? createOnetimeAndPeriodicPricesForSubscriptions(cp, offer)
      : createOnetimeAndPeriodicPricesForDevices(cp)),
  };
};

const getAddOnPurpose = (selectedAddOnBundle: SelectedAddOnBundle) =>
  selectedAddOnBundle.isMandatory ? AddOnPurpose.BY_DEFAULT : selectedAddOnBundle.addOnPurpose;

const getAddOnAssociationData = (
  addOn: AddOn,
  selectedAddOnBundles: SelectedAddOnBundle[],
  addOnAssociations: Array<AddOnAssociation>
) => {
  const selectedAddOnBundle = selectedAddOnBundles.find(sab => sab.addOnCode === addOn.addOnCode);
  if (selectedAddOnBundle) {
    return {
      addOnPurpose: getAddOnPurpose(selectedAddOnBundle),
      display: selectedAddOnBundle.display,
      addOnAssociationId: selectedAddOnBundle.addOnAssociationId,
      addOnAssociationCode: selectedAddOnBundle.addOnAssociationCode,
    };
  } else {
    const association = addOnAssociations.find(addOnAssociation => addOnAssociation.addOnCode === addOn.addOnCode);
    if (association) {
      return {
        addOnPurpose: association?.addOnPurpose,
        display: association?.display,
        addOnAssociationId: association?.addOnAssociationId,
        addOnAssociationCode: association?.addOnAssociationCode,
      };
    }
  }
  return undefined;
};

const createAddOnBundle = (
  onlineModel: OnlineModel,
  addOnAssociations: Array<AddOnAssociation>,
  selectedAddOnBundles: SelectedAddOnBundle[]
): AddOnBundle => {
  const addOns = onlineModel.addOns;
  const selectedAddOns = selectedAddOnBundles.map(selectedAddOnBundle => selectedAddOnBundle.addOnCode);
  const addOnCounts = selectedAddOnBundles.reduce((acc, cur, i) => {
    // @ts-ignore
    acc[selectedAddOnBundles[i].addOnCode] = selectedAddOnBundles[i].count;
    return acc;
  }, {});
  return {
    selectedAddons: selectedAddOns,
    addOnCounts: addOnCounts,
    availableAddOns:
      addOns?.flatMap(addOn => {
        const addOnAssociationData = getAddOnAssociationData(addOn, selectedAddOnBundles, addOnAssociations);
        if (addOnAssociationData) {
          return [
            {
              lastModified: addOn.lastModified,
              created: addOn.created,
              addOnType: addOn.addOnType,
              addOnProductName: addOn.addOnProductName,
              addOnOneTimeCharge: addOn.addOnOneTimeCharge,
              addOnMonthlyRecurringCharge: addOn.addOnMonthlyRecurringCharge,
              addOnId: addOn.addOnId,
              addOnGroup: addOn.addOnGroup,
              addOnCode: addOn.addOnCode,
              ...addOnAssociationData,
            },
          ];
        } else {
          return [];
        }
      }) || [],
  };
};

const getAddonDisclaimers = (addOn: ShoppingCartAddOn | AddOn): string[] => {
  const vatMessage = t.A0OJ(vatPercentageMsg, '0');
  if (isSquareTradeAddOn(addOn)) {
    return [`${t.VWSV(deductibleMsg)} ${squareTradeDeductibleMsg}`, t.XJMB(noFixedTermMsg), vatMessage];
  }
  return [vatMessage];
};

const getAddonPriceDisplayData = (addOn: ShoppingCartAddOn, quantity: number) => {
  if (addOn.addOnOneTimeCharge) {
    return {
      amount: formatSumToString(addOn.addOnOneTimeCharge * quantity, true),
      unit: '€',
    };
  } else if (addOn.addOnMonthlyRecurringCharge) {
    return {
      amount: formatSumToString(addOn.addOnMonthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  }
  return {
    amount: '',
    unit: '',
  };
};

export const getAddonsDisplayData = (addOns: ShoppingCartAddOn[], quantity: number): CLShoppingCartAddon[] =>
  addOns
    .filter(addOn => addOn.display)
    .map(addOn => ({
      disclaimer: getAddonDisclaimers(addOn),
      id: addOn.addOnAssociationCode,
      name: addOn.addOnProductName,
      price: getAddonPriceDisplayData(addOn, quantity),
      quantity,
    }));

export const getProductPriceDisplayData = (price: ShoppingCartItemForCheckout['price'], quantity: number) => {
  const onetimePrice = price.onetime?.price;
  const periodicPrice = price.periodic?.price;
  const billingPeriod = price.periodic?.billingPeriod;

  // Favor periodic price, as we'd want to show the monthly price for subscriptions and such
  if (periodicPrice !== undefined) {
    return {
      amount: formatSumToString(periodicPrice * quantity, true),
      name: billingPeriod ? '' : t.P6BC(monthlyChargesMsg),
      unit: billingPeriod ? `€/${billingPeriod} ${t.XXVX(monthsMsg)}` : t.YO7F(sumPerMonthMsg, '€'),
    };
  } else if (onetimePrice !== undefined) {
    return {
      amount: formatSumToString(onetimePrice * quantity, true),
      name: t.GOBY(oneTimePaymentMsg),
      unit: '€',
    };
  }
  return {
    amount: '',
    name: '',
    unit: '',
  };
};

// HeaderShoppingCart shows less information, thus the short option
export const getProductDisclaimers = (
  price: ShoppingCartItemForCheckout['price'],
  quantity: ShoppingCartItemForCheckout['quantity'],
  short?: boolean,
  isEmployee?: boolean
): string[] => {
  const vatMessage = t.A0OJ(vatPercentageMsg, getEffectiveVAT(isEmployee || false));
  const onetimePrice = price.onetime?.price;
  const periodicPayments = price.periodic?.payments;
  const periodicPrice = price.periodic?.price;
  const billingPeriod = price.periodic?.billingPeriod;
  if (!periodicPrice) {
    // Single fare
    if (short) {
      return [];
    }
    return [t.ASEI('Single fare'), vatMessage];
  }
  if (!periodicPayments && onetimePrice) {
    // Subscription
    const activationFeeStr =
      quantity === 1
        ? t.HWDU(activationFeeWithVarMsg, formatSumToString(onetimePrice))
        : t.HWDV(activationFeesWithVarMsg, formatSumToString(onetimePrice));
    if (short) {
      return [activationFeeStr];
    }
    return [activationFeeStr, vatMessage];
  }
  // N monthly payments
  if (short) {
    return [
      t.O108(
        monthlyChargeSummaryMsg,
        (periodicPayments || '').toString(),
        formatSum(periodicPayments === 1 ? onetimePrice : (periodicPayments || 0) * (periodicPrice || 0)) || '',
        getEffectiveVAT(isEmployee || false)
      ),
    ];
  }
  return [
    billingPeriod ? '' : t.O109('{} month agreement', (price.periodic?.payments || '').toString()),
    billingPeriod
      ? ''
      : t.W1RX(
          'Total charge {}',
          formatSum(
            price.periodic?.payments === 1
              ? price.onetime?.price
              : (price.periodic?.payments || 0) * (price.periodic?.price || 0)
          ) || ''
        ),
    vatMessage,
  ];
};

export const ID_ITEM_SEPARATOR = ',';
export const ID_GROUP_SEPARATOR = ':';

// We can have the same 'product' as multiple items in the cart, as each
// combination of payment option and selected addons gets its own item.
// ',' and ':' are used as separators, as all the used IDs are UUIDs
// (and thus don't contain those characters).
export const getCartItemId = (cartItem: ShoppingCartItemForCheckout) => {
  const { selectedAddOns, commercialProductCodes, offerCode, onlineModelCode } = cartItem;
  return `${onlineModelCode}${ID_GROUP_SEPARATOR}${offerCode}${ID_GROUP_SEPARATOR}${commercialProductCodes
    .sort()
    .join(ID_ITEM_SEPARATOR)}${ID_GROUP_SEPARATOR}${selectedAddOns
    .map(addOn => addOn.addOnCode)
    .sort()
    .join(ID_ITEM_SEPARATOR)}`;
};

export interface PrimitiveAdditionalField {
  type: AdditionalFieldType;
  name: string;
  required: boolean;
  label: {
    key: keyof typeof t;
    translation: string;
  };
}

export interface ComplexAdditionalField {
  type: AdditionalFieldType;
}

export type AdditionalField = PrimitiveAdditionalField | ComplexAdditionalField;

export const isRetainNumberProduct = (productName: string): boolean => {
  return productName !== undefined && productName.includes('säilytä');
};

export const getProductType = (onlineModel: OnlineModel): ProductType | undefined => {
  if (onlineModel.category === OnlineModelCategory.SALES_PRODUCT) {
    if (/^(?:Elisa )?(?:Yritysliittymä|Yritysdata|Laitenetti)/.test(onlineModel.onlineModelName)) {
      return ProductType.SUBSCRIPTION;
    }
    if (/^Elisa Yritystietoturva/.test(onlineModel.onlineModelName)) {
      return ProductType.PRODUCT;
    }
  }
  return undefined;
};

export const getAdditionalFields = (onlineModel: OnlineModel, offer: Offer): AdditionalField[] => {
  if (onlineModel.category === OnlineModelCategory.SALES_PRODUCT) {
    if (/^(?:Elisa )?(?:Yritysliittymä|Yritysdata)/.test(onlineModel.onlineModelName)) {
      return [
        {
          type: isRetainNumberProduct(offer.offerName)
            ? AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_EXISTING_PHONE_NUMBER
            : AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_NEW_PHONE_NUMBER,
        },
      ];
    }
    if (/^(?:Elisa )?Laitenetti/.test(onlineModel.onlineModelName)) {
      return [
        {
          type: AdditionalFieldType.SIM_CARD_SELECTION,
        },
      ];
    }
    if (/^Elisa Yritystietoturva/.test(onlineModel.onlineModelName)) {
      return [
        {
          label: {
            key: 'ILJ6',
            translation: 'Main user name',
          },
          name: 'mainUserName',
          required: true,
          type: AdditionalFieldType.STRING,
        },
        {
          label: {
            key: 'PUQA',
            translation: 'Main user email',
          },
          name: 'mainUserEmail',
          required: true,
          type: AdditionalFieldType.EMAIL,
        },
        {
          label: {
            key: 'P4OO',
            translation: 'Main user phone',
          },
          name: 'mainUserPhone',
          required: false,
          type: AdditionalFieldType.PHONE_NUMBER,
        },
      ];
    }
  }
  return [];
};

export const shouldShowTotalsInShoppingCartPopover = (
  cartItems: ShoppingCartItemForCheckout[]
): { showTotalOneTimeCharge: boolean; showTotalMonthlyRecurringCharge: boolean } => {
  const showTotalOneTimeCharge = cartItems.some(item => Boolean(item.productPrice.onetime?.price));
  const showTotalMonthlyRecurringCharge = cartItems.some(item => Boolean(item.productPrice.periodic?.price));
  return { showTotalOneTimeCharge, showTotalMonthlyRecurringCharge };
};

export const resolveTotalPrices = (cartItems: ShoppingCartItemForCheckout[]) => {
  // These include addons as well
  const { totalMonthlyRecurringCharge, totalOneTimeCharge } = getTotalSums(getConfiguredCommercialProducts(cartItems));
  const totalPrices: ShoppingCartProps['totals'] = [];
  const { showTotalOneTimeCharge, showTotalMonthlyRecurringCharge } = shouldShowTotalsInShoppingCartPopover(cartItems);

  const containsBillingPeriodItem = cartItems.some(ci => ci?.price?.periodic?.billingPeriod);

  // With employees there are use cases when we want to show the price even though it is 0.0
  // We use the actual cost as a fallback if the cart items are missing the productPrice for some reason
  if (showTotalOneTimeCharge || totalOneTimeCharge) {
    totalPrices.push({
      amount: formatSumToString(totalOneTimeCharge, true),
      name: t.GOBY(singleFaresMsg),
      unit: '€',
    });
  }

  if (showTotalMonthlyRecurringCharge || totalMonthlyRecurringCharge) {
    totalPrices.push({
      name: containsBillingPeriodItem ? t.J4WW(recurringChargesMsg) : t.P6BC(monthlyChargesMsg),
      amount: formatSumToString(totalMonthlyRecurringCharge, true),
      unit: containsBillingPeriodItem ? '€' : t.YO7F(sumPerMonthMsg, '€'),
    });
  }
  return totalPrices;
};

const getProductOf = (onlineModel: OnlineModel, offer: Offer): Product => {
  return {
    // Simple id: a0F0J00000y4yFsUAI
    id: offer.materialId || '',
    // guid: Offer scope id: c6ab3718-77b9-683f-9f39-a5861aacf309
    guid: offer.offerCode,
    // categoryGuid: Upper scope id: acc5d705-1fb4-0852-a4be-b4ea969cf644
    categoryGuid: onlineModel.onlineModelCode,
    name: offer.offerName,
    properties: offer.properties,
    priceOptions: offer.priceOptions,
    images: offer.images || [],
    // stockAvailability not available
    stockAvailability: 100,
    // new model has active, and old has inactive, that's probably mirrored version of it
    inactive: !offer.active,
  };
};

export const createCartItem = (uniqueId: string, addToCartAction: AddToCartAction): CartItemType => {
  const {
    cartItemContainer: {
      onlineModel,
      commercialProduct,
      offer,
      quantity,
      selectedAddOnBundles,
      additionalFields,
      productPath,
    },
  } = addToCartAction;

  return {
    id: uniqueId,
    quantity: quantity,
    // Missing from bundle
    // - parameters
    // Bundle productItems are all variants of the product
    // BundleItems product is the selected variant
    bundle: {
      addOnResource: [],
      category: (() => {
        if (onlineModel.category === OnlineModelCategory.SALES_PRODUCT) {
          return LEGACY_SALES_PRODUCT_NAME;
        }
        return onlineModel.category || '';
      })(),
      cmsPageId: onlineModel.cmsPageId || '',
      created: new Date(onlineModel.created).toISOString(),
      modified: new Date(onlineModel.lastModified).toISOString(),
      description: onlineModel.description || '',
      manufacturer: onlineModel.manufacturer || '',
      name: onlineModel.onlineModelName,
      guid: onlineModel.onlineModelCode,
      listingImage: onlineModel.listingImage || '',
      images: onlineModel.images,
      tags: onlineModel.tags,
      productItems: onlineModel.offers.map(o => getProductOf(onlineModel, o)),
    },
    bundleItems: [
      {
        addOnBundle: createAddOnBundle(onlineModel, commercialProduct.addOnAssociations || [], selectedAddOnBundles),
        price: createCartItemBundleItemPrice(commercialProduct, offer),
        product: getProductOf(onlineModel, offer),
        productPath,
        additionalFields: [...getAdditionalFields(onlineModel, offer), ...additionalFields],
        productType: getProductType(onlineModel),
      },
    ],
  };
};

const isMatchingCartItem = (cartItem: CartItemType, action: UpdateCartItemAction): boolean => {
  return (
    cartItem.bundleItems.length > 0 &&
    cartItem.bundleItems[0].price.guids[0] === action.cartItem.price.guid &&
    // for Sales product, name of the product need to be matched as transfer number and new number can have same guid.
    cartItem.bundleItems[0].product.name === action.cartItem.productName
  );
};

const isMatchingAddOnsCount = (cartItem: CartItemType, action: UpdateCartItemAction): boolean => {
  return (
    cartItem.bundleItems.length > 0 &&
    (cartItem.bundle.category === LEGACY_SALES_PRODUCT_NAME || // sales product does not have add ons
      action.cartItem.selectedAddOns.length === cartItem.bundleItems[0].addOnBundle.selectedAddons.length)
  );
};

export const getCartItems = () => JSON.parse(getShoppingCart());

export const updateCartItem = (action: UpdateCartItemAction) => {
  const cartItems: CartItemType[] = getCartItems();
  if (cartItems !== undefined && cartItems.length > 0) {
    cartItems.forEach((cartItem, index) => {
      if (isMatchingCartItem(cartItem, action) && isMatchingAddOnsCount(cartItem, action)) {
        if (action.newQuantity === 0) {
          cartItems.splice(index, 1);
        } else {
          action.cartItem.selectedAddOns.forEach(addOn => {
            if (
              action.addOnsToBeRemoved &&
              action.addOnsToBeRemoved.some(addOnToBeRemoved => addOnToBeRemoved === addOn.addOnCode)
            ) {
              // if there is another CP without addOn, then group the product with quantity update
              const matchingProductIndexWithoutAddOn = cartItems.findIndex(
                item =>
                  item.bundleItems[0].price.guids[0] === action.cartItem.price.guid &&
                  item.bundleItems[0].addOnBundle.selectedAddons.length === 0
              );
              if (matchingProductIndexWithoutAddOn !== -1) {
                // increase the quantity of the matching product
                cartItems[matchingProductIndexWithoutAddOn].quantity += action.newQuantity;
                // delete the current item
                cartItems.splice(index, 1);
              } else {
                cartItem.bundleItems[0].addOnBundle.selectedAddons.splice(
                  cartItem.bundleItems[0].addOnBundle.selectedAddons.indexOf(addOn.addOnCode)
                );
                delete cartItem.bundleItems[0].addOnBundle.addOnCounts[addOn.addOnCode];
              }
            } else {
              cartItem.bundleItems[0].addOnBundle.addOnCounts[addOn.addOnCode] = action.newQuantity;
            }
          });
          cartItem.quantity = action.newQuantity;
        }
      }
    });
  }
  return cartItems;
};

const isArrayItemsEqual = (arr1: string[], arr2: string[]) =>
  arr1.length === arr2.length && arr1.every(arr1Item => arr2.some(arr2Item => arr2Item === arr1Item));

const isEqualBundleProduct = (item1: BundleItemsEntity, item2: BundleItemsEntity) => {
  // If products has add-ons they need to match first, then check item's id itself.
  if (item1.addOnBundle && item2.addOnBundle) {
    if (!isArrayItemsEqual(item1.addOnBundle.selectedAddons, item2.addOnBundle.selectedAddons)) {
      return false;
    }
  }

  if (item1.product.id === item2.product.id) {
    return isArrayItemsEqual(item1.price.guids, item2.price.guids);
  }
  return false;
};

const isCartItemsEqual = (newCartItemBundle: CartItemType, existingCartItemBundle: CartItemType) => {
  if (existingCartItemBundle.bundleItems[0].product.guid !== newCartItemBundle.bundleItems[0].product.guid) {
    return false;
  }

  // All bundle items must be same
  return newCartItemBundle.bundleItems.every(newItemBundle =>
    existingCartItemBundle.bundleItems.some(existingItemBundle =>
      isEqualBundleProduct(existingItemBundle, newItemBundle)
    )
  );
};

const cartItemMatchesShoppingCartItem = (cartItem: CartItemType, shoppingCartItem: ShoppingCartItemForCheckout) => {
  const bundleItem = cartItem.bundleItems[0];
  const { addOnBundle, product } = bundleItem;
  return (
    product.categoryGuid === shoppingCartItem.onlineModelCode &&
    product.guid === shoppingCartItem.offerCode &&
    isArrayItemsEqual(bundleItem.price.guids, shoppingCartItem.commercialProductCodes) &&
    isArrayItemsEqual(
      addOnBundle.selectedAddons,
      shoppingCartItem.selectedAddOns.map(addOn => addOn.addOnCode)
    )
  );
};

const updateCartItemPrices = (cartItem: CartItemType, commercialProduct: CommercialProduct, offer: Offer) => {
  // Do changes, these are all the fields affected by commercialProductCode
  cartItem.bundleItems.forEach(bundleItem => {
    bundleItem.price = createCartItemBundleItemPrice(commercialProduct, offer);
  });
};

const joinMatchingCartItems = (
  cartItems: CartItemType[],
  changedCartItem: CartItemType,
  changedCartItemIdx: number
) => {
  // Find (possible) target cart item in cart (i.e. the one that shares all properties with the changed item)
  const matchingCartItem = cartItems.find(
    (cartItem, idx) => idx !== changedCartItemIdx && isCartItemsEqual(changedCartItem, cartItem)
  );
  // Follows the logic of updateCartItem
  if (matchingCartItem) {
    // increase the quantity of the matching product
    matchingCartItem.quantity += changedCartItem.quantity;
    for (const [addOnCode, count] of Object.entries(changedCartItem.bundleItems[0].addOnBundle.addOnCounts)) {
      matchingCartItem.bundleItems[0].addOnBundle.addOnCounts[addOnCode] += count;
    }
    // delete the current item
    cartItems.splice(changedCartItemIdx, 1);
  }
};

export const getCartItemsWithUpdatedPayment = (cartItems: CartItemType[], action: UpdateCartItemPaymentAction) => {
  if (!cartItems?.length) {
    return cartItems;
  }
  const cartItemToChangeIdx = cartItems.findIndex(ci => cartItemMatchesShoppingCartItem(ci, action.cartItem));
  if (cartItemToChangeIdx === -1) {
    // Cart item not found in cart, this should not happen
    return cartItems;
  }
  const cartItemToChange = cartItems[cartItemToChangeIdx];
  const { newCommercialProducts, offer, onlineModel } = action;
  if (cartItemToChange.bundleItems[0].product.categoryGuid !== onlineModel.onlineModelCode) {
    // Online model changed, this should not be done with this action
    return cartItems;
  }
  if (cartItemToChange.bundleItems[0].product.guid !== offer.offerCode) {
    // Offer changed, this should not be done with this action
    return cartItems;
  }
  const newCommercialProductCodes = newCommercialProducts.map(cp => cp.commercialProductCode);
  if (!offer.priceOptions || offer.priceOptions.every(po => !isArrayItemsEqual(po, newCommercialProductCodes))) {
    // None of the offer's priceOptions matches the given commercial products, or we don't have price options
    return cartItems;
  }

  // We should have an array for these, it's just accidental that no normal
  // product has more than one CP, and sales products are unnecessarily
  // hacked to work with more than one (i.e. the CP selection is ignored).
  const commercialProduct = newCommercialProducts[0];
  updateCartItemPrices(cartItemToChange, commercialProduct, offer);
  joinMatchingCartItems(cartItems, cartItemToChange, cartItemToChangeIdx);
  return cartItems;
};

// Only counts actual products, not their addons
export const getTotalProductQuantity = (cartItems: ShoppingCartItemForCheckout[]) => {
  return cartItems.reduce((acc, curr) => acc + curr.quantity, 0);
};

export const addToShoppingCart = (cartItem: CartItemType, shoppingCartJson: CartItemType[]) => {
  let isCartUpdated = false;
  if (shoppingCartJson.length) {
    for (const el of shoppingCartJson) {
      if (isCartItemsEqual(cartItem, el)) {
        el.quantity += cartItem.quantity;
        isCartUpdated = true;
      }
    }
  }
  if (!isCartUpdated) {
    shoppingCartJson.push(cartItem);
  }
};
