import { OpenFormLayoutCanvas } from './OpenFormLayoutCanvas.js';
import { OpenFormLayoutOnline } from './OpenFormLayoutOnline.js';
import { OpenFormProvider } from '../OpenFormProvider.js';
import { isIframe } from '../OpenFormUtils.js';
import { setFooterVisibility } from '../../../selfservice/actions/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const OpenFormLayout = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFooterVisibility(false));
    return () => {
      dispatch(setFooterVisibility(true));
    };
  }, [dispatch]);

  return (
    <OpenFormProvider auth={auth}>
      {isIframe() ? <OpenFormLayoutCanvas auth={auth} /> : <OpenFormLayoutOnline auth={auth} />}
    </OpenFormProvider>
  );
};
