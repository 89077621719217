import { Dropdown } from '../Dropdown/Dropdown.js';
import {
  activationFeeMsg,
  alvZeroMsg,
  changeSubscriptionTypeFeeMsg,
  noFixedTermMsg,
  selectMsg,
  subscriptionTypeMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getLegacyProductContents, getOffer } from './OrderSubscriptionSelectionUtils';
import { useEffect, useState } from 'react';
import type { CLDropdownItem } from '../../common/types/componentLibrary';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type { CampaignContext } from '../../generated/api/campaignContext.js';
import type { LegacyProductContent } from './content/CommercialProductContent.js';
import type { ModelType } from '../../common/enums.js';
import type { Offer } from '../../generated/api/offer.js';
import type { SalesType } from '../../generated/api/salesType.js';

import './LegacyOffersSelection.scss';

const DropdownSelector = ({
  dropdownKey,
  dropdownItems,
  offers,
  setSelectedOffer,
}: {
  dropdownKey: number;
  dropdownItems: CLDropdownItem[];
  offers: Offer[];
  setSelectedOffer: (offer: Offer) => void;
}) => (
  <div>
    <div className={`${dsClass.FONT_WEIGHT_600} ${dsClass.PADDING_TOP_3} ${dsClass.PADDING_BOTTOM_1}`}>
      {t.QH8P(subscriptionTypeMsg)}
    </div>
    <Dropdown
      key={dropdownKey}
      i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
      items={dropdownItems}
      onValueChange={element => {
        const selection = getOffer(offers, element.dataset.value);
        setSelectedOffer(selection);
      }}
      className="of-mobile-broadband-four-g-selection__dropdown"
    />
  </div>
);

const LegacyProductDescription = ({
  legacyProduct: { title, dataLimitation, priceTexts, openingFee },
}: {
  legacyProduct: LegacyProductContent;
}) => (
  <>
    <h4>{title}</h4>
    <div>{dataLimitation}</div>
    <div className="of-mobile-broadband-four-g-selection__description__price">
      <span className="of-mobile-broadband-four-g-selection__description__price__final">{priceTexts?.priceText}</span>
      <span className="of-mobile-broadband-four-g-selection__description__price__before-discount">
        {priceTexts?.priceBeforeDiscount}
      </span>
    </div>
    <div className="of-mobile-broadband-four-g-selection__description__footer">
      <span>{t.XJMC(noFixedTermMsg)}</span>
      <span>{`${t.HWDR(activationFeeMsg)} ${formatSum(openingFee)} (${t.YG89(changeSubscriptionTypeFeeMsg)})`}</span>
      <span>{t.S8TX(alvZeroMsg)}</span>
    </div>
    <hr />
  </>
);

const populateDropdownItems = (productContents: LegacyProductContent[]): CLDropdownItem[] => {
  return productContents.map(content => ({
    label: `${content.title} ${content.priceTexts?.priceText}`,
    value: content.offerCode,
  }));
};

interface LegacyOffersSelectionProps {
  setSelectedOffer: (offer: Offer, campaign?: CampaignAssociation) => void;
  salesType: SalesType;
  campaignContexts: CampaignContext[];
  offers: Offer[];
  selectedOffer?: Offer;
  voucher?: string;
  modelType: ModelType;
}

export const LegacyOffersSelection = ({
  selectedOffer,
  setSelectedOffer,
  offers,
  salesType,
  campaignContexts,
  voucher,
  modelType,
}: LegacyOffersSelectionProps) => {
  const [allLegacyProducts, setAllLegacyProducts] = useState<LegacyProductContent[] | undefined>();
  const [selectedLegacyProduct, setSelectedLegacyProduct] = useState<LegacyProductContent | undefined>();
  const [dropdownKey, setDropdownKey] = useState(0);

  const resetDropdown = () => {
    // Change the key to force a re-render and reset the component state, so that we can show again placeholder text
    // if the user has chosen 5G subscription
    setDropdownKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    const offerCode = selectedOffer?.offerCode;
    const legacyOfferCodes = allLegacyProducts?.map(product => product.offerCode) || [];
    if (!offerCode || !legacyOfferCodes.includes(offerCode)) {
      setSelectedLegacyProduct(undefined);
      resetDropdown();
    } else {
      setSelectedLegacyProduct(allLegacyProducts?.find(content => content.offerCode === offerCode));
    }
  }, [selectedOffer, allLegacyProducts]);

  useEffect(() => {
    setAllLegacyProducts(getLegacyProductContents(salesType, campaignContexts, modelType, offers, voucher));
  }, [offers, salesType, campaignContexts, voucher, modelType]);

  if (offers.length === 0 || !allLegacyProducts) {
    return null;
  }

  return (
    <div className="of-mobile-broadband-four-g-selection">
      <h3>{t.DN45('Other subscription types')}</h3>
      <hr />
      <DropdownSelector
        dropdownKey={dropdownKey}
        dropdownItems={populateDropdownItems(allLegacyProducts)}
        offers={offers}
        setSelectedOffer={offer => setSelectedOffer(offer, selectedLegacyProduct?.campaignAssociation)}
      />
      {selectedLegacyProduct && <LegacyProductDescription legacyProduct={selectedLegacyProduct} />}
    </div>
  );
};
