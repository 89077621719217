import { DeliveryMethodAndLanguageSelection } from '../../components/DeliveryMethodAndLanguageSelection.js';
import type { CLDropdownItem } from '../../../../common/types/componentLibrary';

interface BillingAccountPaperDeliveryMethodProps {
  deliveryMethodOptions: CLDropdownItem[];
  disabled?: boolean;
}

export const BillingAccountPaperDeliveryMethodEdit = ({
  deliveryMethodOptions,
  disabled = false,
}: BillingAccountPaperDeliveryMethodProps) => (
  <DeliveryMethodAndLanguageSelection deliveryMethodOptions={deliveryMethodOptions} disabled={disabled} />
);
