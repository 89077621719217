import * as CL from '@design-system/component-library';
import {
  Locale,
  authenticationMsg,
  companyDetailsMsg,
  continueMsg,
  createIdMsg,
  employeeOmaElisaMsg,
  goToOmaElisaMsg,
  manageEmployeeSubscriptionMsg,
  readyMsg,
  t,
} from '../../common/i18n/index.js';
import { RegistrationStep1 } from './RegistrationStep1.js';
import { RegistrationStep2 } from './RegistrationStep2.js';
import { RegistrationStep3 } from './RegistrationStep3.js';
import { RegistrationStep4 } from './RegistrationStep4.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect, useState } from 'react';
import type { CLStepperItem } from '../../common/types/componentLibrary';
import type { CommonError } from '../../common/types/errors.js';
import type {
  Company,
  RegisterUserRequestUserDetails,
  ValidateUserAuthorityResponse,
} from '../../generated/api/models.js';
import type { RegistrationStep1Props } from './RegistrationStep1.js';
import type { RegistrationStep3Props } from './RegistrationStep3.js';

import './RegistrationScene.scss';

const corporateEmployeeSelfServiceUrl =
  'https://verkkoasiointi.elisa.fi/corporate-employee-selfservice/tunnistautuminen';

const companyDetailsKey = 'registration-company';
const userDetailsKey = 'registration-user-details';

type UserCredentials = { email: string };

export type RegistrationSceneProps = {
  errors: CommonError[];
  registrationCompleted?: boolean;
  persistedUserDetails?: ValidateUserAuthorityResponse;
  persistedUserCredentials?: UserCredentials;
  step1Props: Pick<RegistrationStep1Props, 'companySearchResult' | 'persistedCompany' | 'searchCompany'>;
  step3Props: Pick<RegistrationStep3Props, 'validationErrors' | 'registrationInProgress'>;
  redirectPath?: string;
  searchDebounce?: number;
  locale?: string;

  storeDetails: (key: string, object: object) => void;
  invalidateAuthSession: () => void;
  registerUser: (
    identityCertificate: string,
    company: Company,
    userDetails: RegisterUserRequestUserDetails,
    newUser: boolean
  ) => void;
  userExists: (identityCertificate: string, businessId: string, email: string) => void;
  isInExternalSystem?: boolean;
  navigate: (url: string) => void;
};

interface ValidUserInformation {
  firstNames: string;
  lastName: string;
  phoneNumber: string;
}

const isValidUserInformation = (userDetails?: Partial<ValidUserInformation>): userDetails is ValidUserInformation => {
  return (
    userDetails !== undefined &&
    typeof userDetails.firstNames === 'string' &&
    typeof userDetails.lastName === 'string' &&
    typeof userDetails.phoneNumber === 'string'
  );
};

export const RegistrationScene = (props: React.PropsWithChildren<RegistrationSceneProps>) => {
  const {
    locale = Locale.FI,
    errors,
    step1Props: { companySearchResult, persistedCompany, searchCompany },
    step3Props: { validationErrors, registrationInProgress },
    persistedUserDetails,
    searchDebounce,
    storeDetails,
    invalidateAuthSession,
    registerUser,
    userExists,
    persistedUserCredentials,
    registrationCompleted,
    redirectPath = paths.SELF_SERVICE_HOME,
    isInExternalSystem,
  } = props;

  const { ssoSessionValid, anonymousUser } = useAuth();
  const loginErrors = anonymousUser?.errors;

  const [userDetails, setUserDetails] = useState(persistedUserDetails);

  const getInitialStep = () => {
    if (userDetails?.existingUserId || userDetails?.phoneNumber) {
      return 2;
    } else if (persistedCompany) {
      return 1;
    }
    return 0;
  };

  const [currentStep, setCurrentStep] = useState(getInitialStep());
  const [company, setCompany] = useState(persistedCompany);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const isStepClickable = (step: number) => !registrationCompleted && currentStep > step;
  const isStepTwoClickable = () => Boolean(company?.businessId) && isStepClickable(1);
  const navigateToStep = (step: number) => isStepClickable(step) && setCurrentStep(step);
  const redirectButtonText = redirectPath === paths.SELF_SERVICE_HOME ? t.Z13H(goToOmaElisaMsg) : t.I62A(continueMsg);

  useEffect(() => {
    if (loginInProgress) {
      setLoginInProgress(!(ssoSessionValid || (Array.isArray(loginErrors) && loginErrors.length > 0)));
    }
  }, [ssoSessionValid, loginErrors, loginInProgress]);

  useEffect(() => {
    if (registrationCompleted) {
      setCurrentStep(3);
    }
  }, [setCurrentStep, registrationCompleted]);

  const steps: CLStepperItem[] = [
    {
      id: 'step1',
      name: t.EHOL(companyDetailsMsg),
      content: (
        <RegistrationStep1
          isEditable={currentStep === 0}
          companySearchResult={companySearchResult}
          searchCompany={searchCompany}
          storeCompany={selectedCompany => {
            if (company?.businessId !== selectedCompany.businessId) {
              invalidateAuthSession();
              setUserDetails(undefined);
            }
            setCompany(selectedCompany);
            setCurrentStep(1);
            storeDetails(companyDetailsKey, selectedCompany);
          }}
          persistedCompany={company}
          searchDebounce={searchDebounce}
        />
      ),
    },
    {
      id: 'step2',
      name: t.AKN3(authenticationMsg),
      content: (
        <RegistrationStep2
          locale={locale as Locale}
          errors={errors}
          isEditable={currentStep === 1}
          initialUserDetails={
            userDetails
              ? {
                  firstNames: userDetails.firstNames || '',
                  lastName: userDetails.lastName || '',
                  phoneNumber: userDetails.phoneNumber || '',
                }
              : undefined
          }
          businessId={company?.businessId || ''}
          storeUserDetails={details => {
            const allDetails = { ...userDetails!, ...details };
            setUserDetails(allDetails);
            setCurrentStep(2);
            storeDetails(userDetailsKey, allDetails);
          }}
          redirectPath={redirectPath}
        />
      ),
    },
    {
      id: 'step3',
      name: t.CTMX(createIdMsg),
      content: (
        <RegistrationStep3
          errors={errors}
          isEditable={currentStep === 2 && !registrationCompleted}
          existingUserId={userDetails?.existingUserId}
          validateEmail={email => userExists(userDetails!.identityCertificate, company?.businessId || '', email)}
          validationErrors={validationErrors}
          initialEmail={persistedUserCredentials && persistedUserCredentials.email}
          registrationInProgress={registrationInProgress || loginInProgress}
          isInExternalSystem={isInExternalSystem || false}
          registerUser={credentials => {
            // registerUser is not called for existing userid's or users in external system. Before we call
            // the registration endpoint user must have given valid information in the RegistrationStep2
            if (isValidUserInformation(userDetails)) {
              registerUser(
                userDetails.identityCertificate,
                persistedCompany!,
                {
                  firstName: userDetails.firstNames,
                  lastName: userDetails.lastName,
                  phoneNumber: userDetails.phoneNumber,
                  email: credentials.email,
                  password: credentials.password,
                },
                credentials.newUser
              );
              setLoginInProgress(true);
            } else {
              setCurrentStep(1);
            }
          }}
          redirectUri={redirectPath}
          redirectButtonText={redirectButtonText}
        />
      ),
    },
    {
      id: 'step4',
      name: t.L75K(readyMsg),
      content: <RegistrationStep4 redirectUri={redirectPath} redirectButtonText={redirectButtonText} />,
    },
  ];

  const stepsWithCompleteAndClickHandler = steps.map((step, idx) => {
    if (idx < currentStep) {
      step.completed = true;
    } else {
      step.completed = false;
      step.onClick = undefined;
    }
    if ((isStepTwoClickable() || step.completed) && !registrationCompleted) {
      step.onClick = () => navigateToStep(idx);
    }
    return step;
  });

  return (
    <div className="of-registration-scene">
      <CL.GridRow className="disclaimer-row">
        <CL.GridCol colWidth={12}>
          <CL.Disclaimer title={t.E0KX('Are you an employee user?')} visible>
            <p>
              {t.JW6Y(manageEmployeeSubscriptionMsg)}{' '}
              <a href={paths.EMPLOYEE_HOME}>{` ${t.ZPZR(employeeOmaElisaMsg)}`}</a>.
            </p>
            <br />
            <p>
              {t.ZYAW('To be able to login you need')}{' '}
              <a href={corporateEmployeeSelfServiceUrl}>{` ${t.FXG6('an Elisa-ID')}`}</a>.
            </p>
          </CL.Disclaimer>
        </CL.GridCol>
      </CL.GridRow>

      <CL.GridRow className="main-header-row">
        <CL.GridCol colWidth={12}>
          <h2>{t.QRON('Register as a corporate customer')}</h2>
        </CL.GridCol>
      </CL.GridRow>

      <CL.GridRow>
        <CL.GridCol colWidth={12}>
          <div className={dsClass.RESET}>
            <CL.Stepper
              steps={stepsWithCompleteAndClickHandler}
              activeStep={stepsWithCompleteAndClickHandler[currentStep].id}
            />
          </div>
        </CL.GridCol>
      </CL.GridRow>
    </div>
  );
};
