import { Dropdown } from '../../../components/Dropdown/Dropdown.js';
import { createFieldClassName, createFieldError } from '../utils.js';
import type { CLDropdownItem } from '../../types/componentLibrary';
import type { FieldInputProps, FormikComputedProps, FormikHelpers, FormikState, FormikValues } from 'formik';

export interface DropdownComponentProps {
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikComputedProps<FormikValues>;
  id?: string;
  className?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  items: string[] | CLDropdownItem[];
  placeholderText?: string;
  selectedValue?: string;
  infoText?: string;
  helpText?: string;
  optionalText?: string;
  required?: boolean;
  integrated?: boolean;
}

const toDropDownItem = (item: string | CLDropdownItem): CLDropdownItem => {
  return typeof item === 'string' ? { label: item, value: item } : item;
};

export const DropdownComponent = ({
  field,
  form,
  items,
  placeholderText,
  selectedValue,
  infoText,
  helpText,
  optionalText,
  required,
  ...props
}: DropdownComponentProps) => {
  const { name } = field;
  const dropdownItems = items.map(toDropDownItem);

  return (
    <div id={name}>
      <div className="of-formik-dropdown">
        <Dropdown
          {...props}
          items={dropdownItems}
          optional={!required}
          className={createFieldClassName(field)}
          onValueChange={listElement => {
            const currentValue = listElement.dataset.value;
            form.setFieldValue(name, currentValue);
          }}
          selectedValue={selectedValue || field.value}
          i18n_dropdown_placeholderText={placeholderText}
          i18n_dropdown_infoText={infoText}
          i18n_dropdown_helpText={helpText}
          i18n_dropdown_optionalText={optionalText}
          i18n_dropdown_errorMessage={required ? createFieldError(field, form)?.toString() : undefined}
        />
      </div>
    </div>
  );
};
