import * as CL from '@design-system/component-library';
import { TableSortOrder, TableUrlParams } from './tableConstants.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import type { CLTableColumn, CLTableRow } from '../../common/types/componentLibrary';

import './Table.scss';

export interface TableProps {
  columns: CLTableColumn[];
  isLoading?: boolean;
  noItemsText?: string;
  rows: CLTableRow[];
  valignText?: 'top' | 'middle' | 'bottom';
  onSortChange?: () => void;
  tableType?: 'nude' | 'bordered' | 'striped';
  rowClicks?: (() => void)[];
}

export const Table = ({
  columns,
  isLoading = false,
  noItemsText,
  rows,
  valignText = 'middle',
  onSortChange,
  tableType = 'striped',
  rowClicks,
}: TableProps) => {
  const { hash, search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const sort = queryParams.get(TableUrlParams.SORT) || undefined;
  const order = queryParams.get(TableUrlParams.ORDER) || undefined;
  const searchInput = queryParams.get(TableUrlParams.SEARCH) || undefined;
  const columnsWithSort = columns.map(column => ({
    ...column,
    sorted: column.key === sort && order ? (order as CLTableColumn['sorted']) : undefined,
  })) satisfies CLTableColumn[];

  const setSort = (label: string) => {
    const column = columns.find(c => c.label === label);
    if (column) {
      if (sort && order && sort === column.key) {
        queryParams.set(TableUrlParams.ORDER, order === TableSortOrder.ASC ? TableSortOrder.DESC : TableSortOrder.ASC);
      } else {
        queryParams.set(TableUrlParams.SORT, column.key);
        queryParams.set(TableUrlParams.ORDER, column.sorted === 'asc' ? TableSortOrder.DESC : TableSortOrder.ASC);
      }
      if (onSortChange) {
        onSortChange();
      }
      navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
    }
  };

  return (
    <>
      <div className={`of-table-wrapper ${rows.length === 0 ? 'empty-table' : ''} ${dsClass.MARGIN_TOP_4}`}>
        <div className="of-table-content">
          <div className="of-table">
            {isLoading ? (
              <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.PADDING_8} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
                <CL.LoadingSpinner size="xl" />
              </div>
            ) : (
              <CL.Table
                columns={columnsWithSort}
                hideHeadersOnMobile={false}
                onSortClick={setSort}
                rows={rows}
                tableType={tableType}
                valignText={valignText}
                rowClicks={rowClicks}
              />
            )}
          </div>
        </div>
        {rows.length === 0 && !isLoading && (
          <div className="no-results">
            {searchInput ? (
              <>
                <span>{t.OQBT('No results for')} </span>
                <strong>&quot;{searchInput}&quot;</strong>.
              </>
            ) : (
              (noItemsText ?? `${noItemsText}`)
            )}
          </div>
        )}
      </div>
    </>
  );
};
