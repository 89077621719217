import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { loginMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';

import './LoginBanner.scss';

export const LoginBanner = () => (
  <CL.Disclaimer
    visible
    className={`${dsClass.MARGIN_VERTICAL_3} ${dsClass.BACKGROUND_COLOR_BLUE_100} ${dsClass.BORDER_WIDTH_1} ${dsClass.BORDER_STYLE_SOLID}`}
    icon={<CL.Icon icon="diamond" aria-hidden="true" color="secondary-blue-600" />}
  >
    <h5 className={dsClass.MARGIN_BOTTOM_1}>{t.HYV2(loginMsg)}</h5>
    <p className={dsClass.MARGIN_BOTTOM_2}>
      {t.WDIX(
        `Log in to receive personalized service and offers. If you don't have an account in OmaElisa for Companies, you can create one below.`
      )}
    </p>
    <p>
      <Link
        to={`${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(paths.SHOPPING_BASKET)}`}
        className={`${dsClass.MARGIN_TOP_1} ${dsClass.MARGIN_BOTTOM_3}`}
      >
        <span>{t.XE0P('Create an account')}</span>
      </Link>
    </p>
    <p>
      <Link to={paths.SHOPPING_BASKET} state={{ loginInProgress: true }}>
        <CL.Button color="light">{t.HYV2(loginMsg)}</CL.Button>
      </Link>
    </p>
  </CL.Disclaimer>
);
