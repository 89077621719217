import * as CL from '@design-system/component-library';

export interface NotificationTextProps {
  message: string;
  icon?: string;
  className?: string;
}

export const NotificationText = ({ message, icon = 'notification', className }: NotificationTextProps) => (
  <div className="of-alert-disclaimer of-warning">
    <CL.Disclaimer
      className={className}
      visible
      disclaimerType="warning"
      icon={<CL.Icon icon={icon} aria-hidden="true" />}
    >
      <p>{message}</p>
    </CL.Disclaimer>
  </div>
);
