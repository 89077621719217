import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { closeMsg, invalidPhoneNumberMsg, phoneNumberMsg, saveMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isValidPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogState } from '../../common/types/states.js';
import type { DialogWrapperButton } from '../DialogWrapper/index.js';

export interface MissingMobileDialogProps {
  errors?: CommonError[];
  isLoading?: boolean;
  dialog?: DialogState | null;
  onContinue: (mobileNumber: string) => void;
  onCloseDialog: () => void;
}

export const MissingMobileDialog = (props: MissingMobileDialogProps) => {
  const { errors, onCloseDialog, onContinue, isLoading } = props;
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [mobileNumberError, setMobileNumberError] = useState(false);

  const buttons: DialogWrapperButton[] = [
    errors
      ? {
          onClick: () => onCloseDialog(),
          text: t.WOYD(closeMsg),
        }
      : {
          disabled: mobileNumberError || !mobileNumber,
          id: 'add-missing-mobile-number-dialog-continue',
          isLoading,
          onClick: () => {
            if (!mobileNumberError && Boolean(mobileNumber)) {
              onContinue(mobileNumber!);
            }
          },
          text: t.NIWE(saveMsg),
        },
  ];

  const checkPhoneNumber = (number: string) => {
    if (Boolean(number) && isValidPhoneNumber(number)) {
      setMobileNumber(number);
      setMobileNumberError(false);
    } else {
      setMobileNumber('');
      setMobileNumberError(true);
    }
  };

  // FIXME this currently validates on each input; the current design consensus says
  // we should only validate on blur.
  return (
    <DialogWrapper
      closeable={Boolean(errors)}
      buttons={buttons}
      header={t.VP3V('Add a mobile phone number to your contact information')}
      onCloseDialog={onCloseDialog}
    >
      <form>
        <p>
          {t.KC2R('The number is used e.g. in matters related to your subscriptions and for resetting your password.')}
        </p>

        <p id="LoginErrorMessage" className={dsClass.COLOR_ERROR} role="alert">
          {errors && errors[0].displayText}
        </p>
        <CL.Input
          disabled={isLoading}
          i18n_input_errorMessage={mobileNumberError ? t.YLCX(invalidPhoneNumberMsg) : undefined}
          id="missing-mobile-number"
          label={t.AGIJ(phoneNumberMsg)}
          name="mobilenumber"
          onValueChange={e => e && typeof e != 'string' && checkPhoneNumber(e.currentTarget.value)}
          type="text"
        />
      </form>
      {errors &&
        errors.map((error, i) => (
          <CL.Disclaimer
            className={dsClass.TEXT_M}
            icon={<CL.Icon icon="warning" />}
            key={i}
            text={error.message}
            visible
          />
        ))}
    </DialogWrapper>
  );
};
