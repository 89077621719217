import * as CL from '@design-system/component-library';
import {
  addressMsg,
  billingAccountMsg,
  billingFrequencyMsg,
  durationOfContractMsg,
  emailMsg,
  idNumberMsg,
  listPriceMsg,
  monthsMsg,
  nameMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getCommitmentText } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { getRecurringPriceAsText, getSubscriptionStatusFieldCL } from './subscriptionDetailsCommon.js';
import type { CLDescriptionItem } from '../../common/types/componentLibrary';
import type {
  Subscription,
  SubscriptionDetails,
  SubscriptionDetailsSoftwareProduct,
} from '../../generated/api/models.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

const getLicensePricePerUnit = (softwareDetails?: SubscriptionDetailsSoftwareProduct) => {
  if (!softwareDetails || !softwareDetails.licencePricePerUnit || !softwareDetails.licencePriceUnit) {
    return undefined;
  }

  return `${formatSum(softwareDetails.licencePricePerUnit, true)} ${softwareDetails.licencePriceUnit.replace(
    /^e\//,
    '€/'
  )}`;
};

/* TODO: There are five different details with similar solutions,
    consider refactoring to a separate component */
export const getSubscriptionDetailsSoftwareProduct = (
  subscription: Subscription,
  subscriptionStatus: SubscriptionStatus,
  details: SubscriptionDetails,
  billingAccountSelection?: JSX.Element,
  softwareProductDetails?: SubscriptionDetailsSoftwareProduct
) => {
  const items: CLDescriptionItem[] = [
    getSubscriptionStatusFieldCL(subscriptionStatus, t.ASQT(statusMsg)),
    {
      title: t.YTH3(idNumberMsg),
      description: subscription.subscriptionDisplayId,
    },
    {
      title: t.IFT9(billingAccountMsg),
      description: billingAccountSelection,
    },
    {
      title: t.CAA9(durationOfContractMsg),
      description: getCommitmentText(details),
    },
  ];
  const unitPrice = getLicensePricePerUnit(softwareProductDetails);
  if (unitPrice) {
    items.push({
      title: t.R39E('Unit price'),
      description: unitPrice,
    });
  }

  const billingPeriod = subscription.details?.billingPeriod;

  items.push({
    title: t.N68W(listPriceMsg),
    description: getRecurringPriceAsText(details.monthlyRecurringCharge, billingPeriod),
  });

  if (billingPeriod) {
    items.push({
      title: t.PE9O(billingFrequencyMsg),
      description: `${billingPeriod.toString()} ${t.XXVX(monthsMsg)}`,
    });
  }
  return <CL.Description items={items} />;
};

export const getAdminUserAccordionContent = (
  softwareProductDetails: SubscriptionDetailsSoftwareProduct,
  adminUserName: string | undefined
) => (
  <CL.Description
    items={[
      {
        title: t.VGFI(nameMsg),
        description: adminUserName,
      },
      {
        title: t.XVHQ(emailMsg),
        description: softwareProductDetails.adminUserEmail,
      },
    ]}
  />
);

export const getDomainAccordionContent = (softwareProductDetails: SubscriptionDetailsSoftwareProduct) => (
  <CL.Description
    items={[
      {
        title: t.GWZ6(addressMsg),
        description: softwareProductDetails.domain,
      },
    ]}
  />
);
