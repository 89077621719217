import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { EppSolutionResponse } from '../../generated/api/eppSolutionResponse';
import {
  activationFeeWithVarMsg,
  activationFeesWithVarMsg,
  deductibleMsg,
  monthlyChargesMsg,
  monthsMsg,
  noFixedTermMsg,
  oneTimePaymentMsg,
  recurringChargesMsg,
  singleFaresMsg,
  squareTradeDeductibleMsg,
  sumPerMonthMsg,
  t,
  totalWithCurlyBracketsMsg,
  vatPercentageMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { formatSum, formatSumToString } from '../../common/utils/priceUtils.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import type { AddOn } from '../../generated/api/addOn.js';
import type { BasketItem, OfferItem, ShoppingBasketType } from '../../common/types/shoppingBasket.js';
import type { BasketItemState, ChangePaymentOptions, PaymentOptionData, PriceData, PriceDisplayData } from './types';
import type {
  CLShoppingCartAddon,
  CLShoppingCartPaymentOptions,
  CLShoppingCartPrice,
} from '../../common/types/componentLibrary.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { Price } from '../../generated/api/price.js';
import type { ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { ShoppingCartProps } from '@design-system/component-library';
import EppSolutionStatusEnum = EppSolutionResponse.EppSolutionStatusEnum;
import { AddOnPurpose } from '../../generated/api/addOnPurpose';
import { EcommerceEventTypeV4 } from '../../common/analytics';
import { LEGACY_SALES_PRODUCT_NAME, getManufacturer } from '../../common/utils/cartProductUtils';
import { OnlineModelCategory } from '../../generated/api/onlineModelCategory.js';
import { areItemsEqualInBasket } from './shoppingBasketFunctions.js';
import { createEcommerceItemFromCommercialProducts } from '../../selfservice/common/googleEcommerceEvent';
import { getAdditionalFields, getProductType } from '../../selfservice/common/shopping-cart/shoppingCartFunctions.js';
import { getItemVariant } from '../../common/utils/analyticsUtils';
import { getShoppingBasketFromLocalStorage, parseBasketJson } from '../../common/hooks/useShoppingBasket';
import { googleEcommerceAddToCart, googleEcommerceRemoveFromCart } from '../../selfservice/actions';

export const getCommercialProductForGuid = (guid: string, model?: OnlineModel): CommercialProduct | undefined => {
  return model?.offers
    .flatMap(offer => offer.commercialProducts)
    .find(commercialProduct => commercialProduct?.commercialProductCode === guid);
};

export const getCommercialProductsForSalesProduct = (model?: OnlineModel, offer?: Offer): string[] | undefined => {
  return model?.offers
    .find(foundOffer => foundOffer.offerCode === offer?.offerCode)
    ?.commercialProducts.map(cp => cp.commercialProductCode);
};

export const getPriceForSalesProduct = (offer?: Offer): PriceData => {
  return {
    price: {
      monthlyRecurringCharge: offer?.commercialProducts.find(cp => cp.monthlyRecurringCharge)?.monthlyRecurringCharge,
      oneTimeCharge: offer?.commercialProducts.find(cp => cp.oneTimeCharge)?.oneTimeCharge,
      payments: offer?.commercialProducts.find(cp => cp.payments)?.payments,
    },
    isDiscountedPrice: false,
    billingPeriod: offer?.commercialProducts.find(cp => cp.billingPeriod)?.billingPeriod,
  };
};

const emptyPrice = {
  amount: '',
  unit: '',
};

export const getPriceToDisplay = (priceData: PriceData | undefined, quantity: number): CLShoppingCartPrice => {
  if (priceData?.price?.monthlyRecurringCharge && priceData?.billingPeriod && priceData?.billingPeriod !== 1) {
    return {
      amount: formatSumToString(priceData.price.monthlyRecurringCharge * quantity, true),
      unit: `€/${priceData.billingPeriod}
             ${t.XXVX(monthsMsg)}`,
    };
  }
  if (priceData?.price?.monthlyRecurringCharge) {
    return {
      amount: formatSumToString(priceData.price.monthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  } else if (priceData?.price?.oneTimeCharge) {
    return { amount: formatSumToString(priceData.price.oneTimeCharge * quantity, true), unit: '€' };
  } else {
    return { amount: formatSumToString(0, true), unit: '€' };
  }
};

export const getPriceFromBasketOfferItem = (offer: OfferItem): Price => {
  return {
    oneTimeCharge: offer.oneTimePriceInCents,
    monthlyRecurringCharge: offer.periodicPrice?.periodicPriceInCents,
    payments: offer.periodicPrice?.payments,
  };
};

export const findDiscountedPrice = (discountedPrices: DiscountedPrices[], commercialProductCode?: string) => {
  return discountedPrices
    .flatMap(discountedPricesEntry => discountedPricesEntry.prices)
    .find(discountedPrice => discountedPrice.commercialProductCode === commercialProductCode);
};

export const getPriceForGuid = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[]
): PriceData | undefined => {
  const commercialProduct = getCommercialProductForGuid(guid, model);
  if (commercialProduct) {
    const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct.commercialProductCode);
    return {
      isDiscountedPrice: !!discountedPrice,
      price: discountedPrice ?? commercialProduct.price?.effectivePrice,
    };
  }
  return undefined;
};

export const getPriceForGuidToDisplay = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPricesForModel: DiscountedPrices | undefined,
  quantity: number
): PriceDisplayData => {
  const priceData = getPriceForGuid(guid, model, discountedPricesForModel ? [discountedPricesForModel] : []);
  return {
    isDiscountedPrice: priceData?.isDiscountedPrice || false,
    shoppingCartPrice: priceData ? getPriceToDisplay(priceData, quantity) : emptyPrice,
  };
};

export const getOnlineModelForBasketItem = (onlineModels: OnlineModel[], basketItem: BasketItem) => {
  return onlineModels?.find(model => model.onlineModelCode === basketItem.guid);
};

export const getOffer = (offerCode: string, onlineModel: OnlineModel | undefined): Offer | undefined => {
  return onlineModel?.offers.find(offer => offer.offerCode === offerCode);
};

export const getPrice = (
  basketItem: BasketItem,
  onlineModel: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[]
) => {
  const isSalesProduct = onlineModel?.category === CommercialProductType.SALES_PRODUCT;
  return isSalesProduct
    ? getPriceForSalesProduct(getOffer(basketItem.offer.guid, onlineModel))
    : getPriceForGuid(basketItem.offer.commercialProductGuid, onlineModel, discountedPrices);
};

export const calculateTotalPrices = (
  models: OnlineModel[],
  discountedPrices: DiscountedPrices[],
  items: BasketItem[] | undefined,
  basketItemStates: Map<string, BasketItemState>
): ShoppingCartProps['totals'] => {
  const calculateAddonPricesOneTime = (addOns: string[], onlineModel: OnlineModel): number => {
    return addOns.reduce(
      (total, addOn) =>
        total + (onlineModel.addOns?.find(modelAddOn => modelAddOn.addOnCode === addOn)?.addOnOneTimeCharge ?? 0),
      0
    );
  };

  const calculateAddonPricesMonthly = (addOns: string[], onlineModel: OnlineModel): number =>
    addOns.reduce(
      (total, addOn) =>
        total +
        (onlineModel.addOns?.find(modelAddOn => modelAddOn.addOnCode === addOn)?.addOnMonthlyRecurringCharge ?? 0),
      0
    );

  let billingPeriodMultipleMonths = false;

  const { monthlyRecurringCharges, onetimeCharges } = items?.reduce(
    (acc, basketItem) => {
      const onlineModelForItem = getOnlineModelForBasketItem(models, basketItem);
      const basketItemState = basketItemStates.get(basketItem.id) || {};
      const price = basketItemState.showContractPriceWillBeConfirmedMsg
        ? { price: getPriceFromBasketOfferItem(basketItem.offer) }
        : getPrice(basketItem, onlineModelForItem, discountedPrices);
      if (onlineModelForItem?.category === OnlineModelCategory.SALES_PRODUCT) {
        if (price?.billingPeriod && price?.billingPeriod !== 1) {
          billingPeriodMultipleMonths = true;
        }
      }
      if (!basketItemState.contractPeriodUnavailable || basketItemState.showContractPriceWillBeConfirmedMsg) {
        acc.monthlyRecurringCharges += (price?.price?.monthlyRecurringCharge || 0) * basketItem.quantity;
        acc.onetimeCharges += (price?.price?.oneTimeCharge || 0) * basketItem.quantity;
      }
      acc.onetimeCharges +=
        basketItem.offer.addOns && onlineModelForItem
          ? calculateAddonPricesOneTime(basketItem.offer.addOns, onlineModelForItem) * basketItem.quantity
          : 0;
      acc.monthlyRecurringCharges +=
        basketItem.offer.addOns && onlineModelForItem
          ? calculateAddonPricesMonthly(basketItem.offer.addOns, onlineModelForItem) * basketItem.quantity
          : 0;

      return acc;
    },
    { monthlyRecurringCharges: 0, onetimeCharges: 0 }
  ) || { monthlyRecurringCharges: 0, onetimeCharges: 0 };

  return [
    ...(monthlyRecurringCharges
      ? [
          {
            name: billingPeriodMultipleMonths ? t.J4WW(recurringChargesMsg) : t.P6BC(monthlyChargesMsg),
            amount: formatSumToString(monthlyRecurringCharges, true),
            unit: '€',
          },
        ]
      : []),
    ...(onetimeCharges
      ? [
          {
            amount: formatSumToString(onetimeCharges, true),
            name: t.GOBY(singleFaresMsg),
            unit: '€',
          },
        ]
      : []),
  ];
};

const getPriceOptionsForOffer = (
  offerCode: string,
  selectedCommercialProduct: string,
  onlineModel: OnlineModel | undefined,
  includeEpp: boolean,
  allowNonEppDevicesWithRecurringCharges: boolean,
  allowNonEppDevicesWithOneTimeCharges: boolean,
  discountedPrices: DiscountedPrices[]
): PaymentOptionData[] => {
  const offer = getOffer(offerCode, onlineModel);
  return (
    offer?.commercialProducts
      .filter(cp => cp.active)
      .map(cp => ({
        id: cp.commercialProductCode,
        price: findDiscountedPrice(discountedPrices, cp.commercialProductCode) || cp.price?.effectivePrice,
        isEpp: cp?.productSubType === CommercialProductSubType.EPP_DEVICE,
        selected: cp.commercialProductCode === selectedCommercialProduct,
        hasRecurringCharges: !!cp.price?.effectivePrice.monthlyRecurringCharge,
        hasOneTimeCharges: !!cp.price?.effectivePrice.oneTimeCharge,
      }))
      .filter(paymentOptionData => includeEpp || !paymentOptionData.isEpp)
      .filter(
        paymentOptionData =>
          allowNonEppDevicesWithRecurringCharges || paymentOptionData.isEpp || !paymentOptionData.hasRecurringCharges
      )
      .filter(
        paymentOptionData =>
          allowNonEppDevicesWithOneTimeCharges || paymentOptionData.isEpp || !paymentOptionData.hasOneTimeCharges
      ) || []
  );
};

export const getPaymentLabel = (price: Price | undefined, isEpp: boolean) => {
  if (price?.monthlyRecurringCharge) {
    return `${price.payments}${t.XXVX(monthsMsg)}, ${formatSumToString(price.monthlyRecurringCharge, true)} ${t.YO7F(
      sumPerMonthMsg,
      '€'
    )}${isEpp ? ' (EPP)' : ''}`;
  } else {
    return `${t.ASEI(oneTimePaymentMsg)} ${formatSumToString(price?.oneTimeCharge)}`;
  }
};

export const getPaymentOptions = (
  offerItem: OfferItem,
  onlineModel: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[],
  eppSolution?: EppSolutionResponse
): CLShoppingCartPaymentOptions => {
  if (onlineModel?.category === CommercialProductType.SALES_PRODUCT) {
    // no payment options for sales products
    return [];
  }
  const eppActive = eppSolution?.eppSolutionStatus === EppSolutionStatusEnum.ACTIVE;
  const allowNonEppRecurring = !eppActive || eppSolution?.allowDevicesWithRecurringCharges;
  const allowNonEppOneTime = !eppActive || eppSolution?.allowDevicesWithOneTimeCharges;
  const priceOptions = getPriceOptionsForOffer(
    offerItem.guid,
    offerItem.commercialProductGuid,
    onlineModel,
    eppActive,
    allowNonEppRecurring === undefined ? true : allowNonEppRecurring,
    allowNonEppOneTime === undefined ? true : allowNonEppOneTime,
    discountedPrices
  );
  return (
    priceOptions
      // Sort lowest monthly charge first
      .sort((a, b) => (a.price?.monthlyRecurringCharge || 0) - (b.price?.monthlyRecurringCharge || 0))
      // Sort EPPs first
      .sort((a, b) => Number(b.isEpp) - Number(a.isEpp))
      // Sort one time charges last
      .sort((a, b) => (a.price?.oneTimeCharge || 0) - (b.price?.oneTimeCharge || 0))
      .map(sortedPrice => ({
        id: sortedPrice.id,
        label: getPaymentLabel(sortedPrice.price, sortedPrice.isEpp) || '',
        selected: sortedPrice.selected,
      }))
  );
};

const getAddonDisclaimers = (addOn: AddOn): string[] => {
  const vatMessage = t.A0OJ(vatPercentageMsg, '0');
  if (isSquareTradeAddOn(addOn)) {
    return [`${t.VWSV(deductibleMsg)} ${squareTradeDeductibleMsg}`, t.XJMB(noFixedTermMsg), vatMessage];
  }
  return [vatMessage];
};

const getAddonPriceDisplayData = (addOn: AddOn, quantity: number) => {
  if (addOn.addOnOneTimeCharge) {
    return {
      amount: formatSumToString(addOn.addOnOneTimeCharge * quantity, true),
      unit: '€',
    };
  } else if (addOn.addOnMonthlyRecurringCharge) {
    return {
      amount: formatSumToString(addOn.addOnMonthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  }
  return {
    amount: '',
    unit: '',
  };
};

export const getAddonsDisplayData = (addOns: string[], model: OnlineModel, quantity: number): CLShoppingCartAddon[] => {
  return (
    model.addOns?.flatMap(modelAddOn => {
      if (addOns.includes(modelAddOn.addOnCode)) {
        return [
          {
            disclaimer: modelAddOn && getAddonDisclaimers(modelAddOn),
            id: modelAddOn?.addOnCode,
            name: modelAddOn?.addOnProductName || '',
            price: getAddonPriceDisplayData(modelAddOn, quantity),
            quantity,
          } satisfies CLShoppingCartAddon,
        ];
      }
      return [];
    }) || []
  );
};

export const getTotalAmount = (basketItems?: BasketItem[]) => {
  return basketItems?.reduce((acc, curr) => acc + curr.quantity, 0) || 0;
};

const findMatchingBasketItem = (
  commercialProductGuid: string,
  existingItem?: BasketItem,
  existingBasketItems?: BasketItem[]
) => {
  return existingBasketItems?.find(
    basketItem =>
      basketItem.offer.commercialProductGuid === commercialProductGuid &&
      deepEqual(existingItem?.offer.addOns, basketItem.offer.addOns)
  );
};

export const findBasketItemForId = (basketItems: BasketItem[] | undefined, basketItemId: string) => {
  return basketItems?.find(item => item.id === basketItemId);
};

export const changeQuantityInBasket = (shoppingBasket: ShoppingBasketType, basketItemId: string, quantity: number) => {
  const basketItem = findBasketItemForId(shoppingBasket.items, basketItemId);
  if (basketItem) {
    if (quantity === 0) {
      const index = shoppingBasket.items?.findIndex(item => item.id === basketItemId);
      if (typeof index === 'number' && index >= 0) {
        shoppingBasket.items?.splice(index, 1);
      }
    } else {
      basketItem.quantity = quantity;
    }
  }
  return shoppingBasket;
};

export const changeQuantityInRawBasket = (shoppingBasket: string, basketItemId: string, quantity: number) => {
  if (shoppingBasket) {
    const basket: ShoppingBasketType = parseBasketJson(shoppingBasket);
    return changeQuantityInBasket(basket, basketItemId, quantity);
  }
  return undefined;
};

const combineQuantitiesForSameItem = (basket: ShoppingBasketType): ShoppingBasketType => {
  const combinedItems: BasketItem[] = [];
  basket.items?.forEach(current => {
    const duplicateIndex = combinedItems?.findIndex(item => areItemsEqualInBasket(item, current));
    if (duplicateIndex !== -1) {
      combinedItems[duplicateIndex].quantity += current.quantity;
    } else {
      combinedItems.push(current);
    }
  });
  return { ...basket, items: combinedItems };
};

export const removeAddOnFromBasket = (shoppingBasket: string, basketItemId: string, addOnId: string) => {
  const basket: ShoppingBasketType = parseBasketJson(shoppingBasket);
  const item = findBasketItemForId(basket.items, basketItemId);
  const index = item?.offer.addOns?.findIndex(addOn => addOn === addOnId);
  if (typeof index === 'number' && index >= 0) {
    item?.offer.addOns?.splice(index, 1);
  }

  return JSON.stringify(combineQuantitiesForSameItem(basket));
};

export const getProductImageUrl = (imageUrl: string, offer?: Offer, model?: OnlineModel): string => {
  if (offer && model) {
    return offer.images && offer.images.length > 0 ? offer.images[0] : model?.listingImage || imageUrl;
  }
  return imageUrl;
};

/**
 * This bad-boy maps the new basket stuff to old checkout format
 * To be removed after checkout renewal
 */
export const mapBasketItemsForCheckout = (
  shoppingBasket: ShoppingBasketType,
  onlineModels?: OnlineModel[],
  discounts?: DiscountedPrices[]
): ShoppingCartItemForCheckout[] | undefined => {
  const result = shoppingBasket.items?.reduce((output, item) => {
    const model = onlineModels?.find(m => m.onlineModelCode === item.guid);
    const commercialProduct = getCommercialProductForGuid(item.offer.commercialProductGuid, model)?.active
      ? getCommercialProductForGuid(item.offer.commercialProductGuid, model)
      : undefined;
    const offer = getOffer(item.offer.guid, model)?.active ? getOffer(item.offer.guid, model) : undefined;
    // for sales products we need this:
    const commercialProductGuids =
      model?.category === OnlineModelCategory.SALES_PRODUCT && getCommercialProductsForSalesProduct(model, offer)
        ? getCommercialProductsForSalesProduct(model, offer)
        : commercialProduct && [commercialProduct.commercialProductCode];
    const price =
      model?.category === OnlineModelCategory.SALES_PRODUCT
        ? getPriceForSalesProduct(offer)
        : getPriceForGuid(item.offer.commercialProductGuid, model, discounts || []);
    const formattedPrice = {
      guid: commercialProduct?.commercialProductCode || '',
      ...(price?.price?.oneTimeCharge && {
        onetime: {
          price: price.price.oneTimeCharge || 0,
        },
      }),
      ...(price?.price?.monthlyRecurringCharge && {
        periodic: {
          payments: price?.price?.payments || 0,
          price: price.price.monthlyRecurringCharge,
          billingPeriod: commercialProduct?.billingPeriod,
          priceSubType: commercialProduct?.productSubType,
        },
      }),
    };
    const addOns = model?.addOns
      ?.filter(addOn => item.offer.addOns?.includes(addOn.addOnCode))
      .reduce((validAddOns, found) => {
        const matchingAddOnAssociation = commercialProduct?.addOnAssociations?.find(
          association => association.addOnCode === found.addOnCode
        );
        if (matchingAddOnAssociation) {
          const mappedAddOn: ShoppingCartAddOn = {
            addOnAssociationCode: matchingAddOnAssociation.addOnAssociationCode,
            addOnCode: found.addOnCode,
            addOnAssociationId: matchingAddOnAssociation.addOnCode,
            addOnGroup: found.addOnGroup,
            addOnProductName: found.addOnProductName,
            addOnPurpose: matchingAddOnAssociation?.addOnPurpose,
            addOnType: found.addOnType,
            display: matchingAddOnAssociation?.display || false,
            addOnMonthlyRecurringCharge: found.addOnMonthlyRecurringCharge,
            addOnOneTimeCharge: found.addOnOneTimeCharge,
          };
          return [...validAddOns, mappedAddOn];
        }
        return validAddOns;
      }, []);
    const mapped: ShoppingCartItemForCheckout | undefined =
      model?.category && offer && commercialProductGuids
        ? {
            id: item.id,
            addOns: addOns || [],
            selectedAddOns: addOns || [],
            category:
              // for old checkout to work we need this legacy name..
              model.category === OnlineModelCategory.SALES_PRODUCT ? LEGACY_SALES_PRODUCT_NAME : model.category,
            commercialProductCodes: commercialProductGuids,
            purposeOfUseOrContacts: [],
            numberPublicities: [],
            simCardConfigurations: [],
            selectedPhoneNumbers: [],
            phoneNumberOwners: [],
            imageListingUrl: getProductImageUrl(item.imageUrl, offer, model),
            price: formattedPrice,
            productPrice: formattedPrice,
            offerCode: item.offer.guid,
            productName: offer.offerName || model.onlineModelName,
            quantity: item.quantity,
            onlineModelCode: model.onlineModelCode,
            onlineModelName: model.onlineModelName,
            additionalFields: getAdditionalFields(model, offer),
            additionalFieldValues: [],
            productType: getProductType(model),
          }
        : undefined;
    return mapped ? [...output, mapped] : output;
  }, []);

  return result || undefined;
};

export const areBasketItemsEqualForCheckout = (
  first?: ShoppingCartItemForCheckout[],
  second?: ShoppingCartItemForCheckout[]
): boolean => {
  if (!!first && !!second && first.length !== second.length) {
    return false;
  }
  return !!first?.every(firstItem => {
    return !!second?.find(
      secondItem =>
        secondItem.onlineModelCode === firstItem.onlineModelCode &&
        secondItem.offerCode === firstItem.offerCode &&
        deepEqual(secondItem.commercialProductCodes, firstItem.commercialProductCodes) &&
        secondItem.quantity === firstItem.quantity &&
        deepEqual(secondItem.addOns, firstItem.addOns)
    );
  });
};

export const getAnalyticsEventForAddOrRemoveItem = (
  basketItem: BasketItem | undefined,
  newQuantity: number,
  onlineModels: OnlineModel[]
) => {
  if (!basketItem) {
    return;
  }
  const onlineModelForItem = getOnlineModelForBasketItem(onlineModels, basketItem);
  if (!onlineModelForItem) {
    return;
  }
  const commercialProduct = getCommercialProductForGuid(basketItem.offer.commercialProductGuid, onlineModelForItem);
  if (!commercialProduct) {
    return;
  }
  const commercialProducts =
    commercialProduct.productType === CommercialProductType.SALES_PRODUCT
      ? getOffer(basketItem.offer.guid, onlineModelForItem)?.commercialProducts || []
      : [commercialProduct];
  const delta = newQuantity - basketItem.quantity;

  const ecommerceItem = createEcommerceItemFromCommercialProducts(
    onlineModelForItem.onlineModelCode,
    onlineModelForItem.category ?? commercialProduct.productSubType ?? commercialProduct.productType,
    onlineModelForItem.onlineModelName,
    getItemVariant(EcommerceEventTypeV4.ADD_TO_CART, commercialProduct.commercialProductName) ??
      commercialProduct.commercialProductName,
    commercialProducts,
    Math.abs(delta),
    getManufacturer(onlineModelForItem, commercialProduct.commercialProductName)
  );
  return delta > 0
    ? googleEcommerceAddToCart([ecommerceItem]).event
    : googleEcommerceRemoveFromCart([ecommerceItem]).event;
};

export const changePaymentOption = ({
  basketItem,
  changeToCommercialProductCode,
  onlineModel,
  discountedPrices,
}: ChangePaymentOptions) => {
  const shoppingBasket = parseBasketJson(getShoppingBasketFromLocalStorage()) as ShoppingBasketType;
  const cp = getCommercialProductForGuid(changeToCommercialProductCode, onlineModel);

  // If item has one optional addon, check that it's compatible with the CommercialProduct. If it's not, remove it
  if (basketItem.offer.addOns?.length === 1 && cp) {
    const addOnCode = basketItem.offer.addOns[0];
    const addOnAssociation = cp.addOnAssociations?.find(association => association.addOnCode === addOnCode);
    const firstAssociation = cp.addOnAssociations ? cp.addOnAssociations[0] : undefined;
    if (!addOnAssociation && firstAssociation?.addOnPurpose === AddOnPurpose.OPTIONAL) {
      const itemWithAddon = findBasketItemForId(shoppingBasket.items, basketItem.id);
      if (itemWithAddon) {
        itemWithAddon.offer.addOns = [];
      }
    }
  }
  const itemToChange = findBasketItemForId(shoppingBasket.items, basketItem.id);
  const duplicateItem = findMatchingBasketItem(changeToCommercialProductCode, itemToChange, shoppingBasket?.items);

  if (itemToChange && duplicateItem) {
    changeQuantityInBasket(shoppingBasket, itemToChange.id, duplicateItem.quantity + itemToChange.quantity);
    const indexOfDuplicateItem = shoppingBasket.items?.indexOf(duplicateItem);
    if (indexOfDuplicateItem !== undefined) {
      shoppingBasket.items?.splice(indexOfDuplicateItem, 1);
    }
  }
  if (itemToChange && cp) {
    const price = getPriceForGuid(cp.commercialProductCode, onlineModel, discountedPrices);
    itemToChange.offer.commercialProductGuid = cp.commercialProductCode;
    if (price?.price?.monthlyRecurringCharge) {
      itemToChange.offer.oneTimePriceInCents = undefined;
      itemToChange.offer.periodicPrice = {
        periodicPriceInCents: price?.price?.monthlyRecurringCharge,
        payments: price?.price?.payments,
        period: cp.billingPeriod || 1, // Billing period in months, usually not available so default to 1
      };
    } else if (price?.price?.oneTimeCharge) {
      itemToChange.offer.periodicPrice = undefined;
      itemToChange.offer.oneTimePriceInCents = price?.price?.oneTimeCharge;
    }
  }
  return shoppingBasket;
};

export const getOnetimePaymentText = (
  oneTimeCharge: number | undefined,
  quantity: number,
  isActivationFee: boolean
) => {
  const formatOneTimeCharge = formatSum(oneTimeCharge) || '';
  if (isActivationFee) {
    return quantity > 1
      ? t.HWDV(activationFeesWithVarMsg, formatOneTimeCharge)
      : t.HWDU(activationFeeWithVarMsg, formatOneTimeCharge);
  } else {
    return t.ASEI(oneTimePaymentMsg);
  }
};

export const getDisclaimerFields = (oneTimePaymentText: string, priceData?: PriceData) => {
  const hasOneTimeCharge = !!priceData?.price?.oneTimeCharge;
  const hasMonthlyCharges = !!priceData?.price?.monthlyRecurringCharge;
  const oneTimeChargeFields = ['', oneTimePaymentText, t.A0OJ(vatPercentageMsg, '0')];
  const payments = priceData?.price?.payments || 0;
  // Corporate VAT is 0
  const monthlyRecurringFields = [
    payments === 0 ? '' : t.CF93('{} month agreement', String(payments)),
    payments === 0
      ? ''
      : t.W1RX(totalWithCurlyBracketsMsg, formatSum(Number(priceData?.price?.monthlyRecurringCharge) * payments) || ''),
    t.A0OJ(vatPercentageMsg, '0'),
  ];
  if (hasOneTimeCharge || hasMonthlyCharges) {
    return [...(hasOneTimeCharge ? oneTimeChargeFields : []), ...(!hasOneTimeCharge ? monthlyRecurringFields : [])];
  } else {
    return [...oneTimeChargeFields];
  }
};

export const isActiveEppPrice = (cpGuid: string, onlineModel?: OnlineModel) => {
  const commercialProduct = getCommercialProductForGuid(cpGuid, onlineModel);
  return commercialProduct?.active && commercialProduct?.productSubType === CommercialProductSubType.EPP_DEVICE;
};

export const isItemDiscontinued = (basketItem: BasketItem, models: OnlineModel[]) => {
  const availableOnlineModelCodes = models && models.length > 0 ? models.map(model => model.onlineModelCode) : [];
  return (
    !availableOnlineModelCodes.includes(basketItem.guid) ||
    !getOffer(basketItem.offer.guid, getOnlineModelForBasketItem(models, basketItem))?.active ||
    getOnlineModelForBasketItem(models, basketItem)
      ?.offers?.flatMap(offer => offer.commercialProducts)
      .every(cp => !cp.active)
  );
};

export const getBasketItemStates = (
  basketItems: BasketItem[] | undefined,
  models: OnlineModel[],
  eppSolution: EppSolutionResponse | undefined,
  loggedIn: boolean
): Map<string, BasketItemState> => {
  return new Map(
    basketItems?.map(basketItem => {
      const onlineModelForItem = getOnlineModelForBasketItem(models, basketItem);
      const paymentOptions = getPaymentOptions(basketItem.offer, onlineModelForItem, [], eppSolution);
      const cp = getCommercialProductForGuid(basketItem.offer.commercialProductGuid, onlineModelForItem);
      const isContractPeriodUnavailable =
        !cp ||
        !cp.active ||
        (paymentOptions?.length > 0 && !paymentOptions.find(po => po.id === cp.commercialProductCode));
      const basketItemIsActiveEpp = isActiveEppPrice(basketItem.offer.commercialProductGuid, onlineModelForItem);
      const showContractPriceWillBeConfirmedMsg = !loggedIn && basketItemIsActiveEpp;
      const basketItemState: BasketItemState = {
        contractPeriodUnavailable: isContractPeriodUnavailable,
        showContractPriceWillBeConfirmedMsg: showContractPriceWillBeConfirmedMsg,
      };
      return [basketItem.id, basketItemState];
    })
  );
};
