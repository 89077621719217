import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { COST_CENTER_REGEX } from '../../common/utils/validationUtils.js';
import { CompanyInfoResponse, CustomerOrderStatus } from '../../generated/api/models.js';
import { CustomerOrderFooter } from './CustomerOrderDetailsFooter.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EOECustomerOrderContent } from './EOECustomerOrderDetailsContent';
import { Form, Formik } from 'formik';
import { Loading } from '../Loading/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getDefaultDeliveryOrder } from '../../common/utils/orderUtils';
import { invalidCostCenterNumberMsg, orderMsg, t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useState } from 'react';
import { validateForm } from '../../common/formik/index.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { ContactPerson, CustomerOrder, Subscription } from '../../generated/api/models.js';

import './CustomerOrderDetails.scss';

export interface EOECustomerOrderDetailsProps {
  buildDeliveryOrderHref: (cid: string, did: string) => string;
  ordererContact?: ContactPerson;
  deviceSubscriptions?: Subscription[];
  customerOrder?: CustomerOrder;
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  firstBreadCrumbName?: string;
  isEmployee?: boolean;
  billingAccountId?: string;
  costCenter?: string;
  employeeNumber?: string;
  breadCrumbPaths?: BreadCrumbList;
}

const validateCostCenterForInternal = (value: string) => {
  return !value || COST_CENTER_REGEX.test(value);
};

export const billingInfoFieldValidations = {
  billingAccountId: { required: true },
};

export const billingInfoFieldValidationsForInternal = {
  costCenter: {
    required: true,
    validationFn: validateCostCenterForInternal,
    messages: {
      validationFn: () => t.JE8S(invalidCostCenterNumberMsg),
    },
  },
  billingAccount: { required: true },
};

export const EOECustomerOrderDetails = ({
  buildDeliveryOrderHref,
  ordererContact,
  deviceSubscriptions,
  customerOrder,
  breadCrumbPaths,
  customerType,
  isEmployee = false,
}: EOECustomerOrderDetailsProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const showFooter = customerOrder?.status === CustomerOrderStatus.PENDING_APPROVAL && !isEmployee;
  const { authenticatedUser } = useAuth();

  const companyName = authenticatedUser?.companyName;

  const isInternalCustomer = customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;

  if (!customerOrder) {
    return <Loading />;
  }

  const content: JSX.Element = (
    <>
      <EOECustomerOrderContent
        buildDeliveryOrderHref={buildDeliveryOrderHref}
        customerOrder={customerOrder}
        ordererContact={ordererContact}
        deviceSubscriptions={deviceSubscriptions}
        isInternalCustomer={isInternalCustomer}
        isEmployee={isEmployee}
      />
      {showFooter && (
        <>
          <CL.Checkbox
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
            }}
          >
            {t.WO28('I have checked the validity of the billing and order details.')}
          </CL.Checkbox>
          <CustomerOrderFooter customerOrder={customerOrder} ordererContact={ordererContact} isChecked={isChecked} />
        </>
      )}
    </>
  );

  const customerOrderDisplayId = customerOrder ? customerOrder.customerOrderDisplayId : '…';
  const fieldValidations = isInternalCustomer ? billingInfoFieldValidationsForInternal : billingInfoFieldValidations;
  const defaultDeliveryOrder = getDefaultDeliveryOrder(customerOrder.deliveryOrders);
  const initialFormValues = {
    billingAccountId: customerOrder.approverMustSelectBillingAccount ? undefined : customerOrder.billingAccountId,
    employeeNumber: defaultDeliveryOrder?.subscriptionReference || '',
    costCenter: defaultDeliveryOrder?.costCenter || '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      validate={formikValues => {
        return validateForm(formikValues, fieldValidations);
      }}
      validateOnMount={true}
      onSubmit={() => {}}
      initialTouched={{
        billingAccountId: true,
        costCenter: true,
      }}
    >
      <Form>
        <DetailsWrapper
          classes={['of-customer-order-details']}
          detailsTop={breadCrumbPaths && <BreadCrumbsWithTitle breadCrumbPaths={breadCrumbPaths} />}
          id={`customer-order-details-${customerOrderDisplayId}`}
          heading={customerOrderDisplayId}
          headingBottom={`${formatTimestampToDDMMYYYY(customerOrder.created)}${companyName ? ` | ${companyName}` : ''}`}
          headingTop={t.C001(orderMsg)}
          heroPicto="order"
        >
          {content}
        </DetailsWrapper>
      </Form>
    </Formik>
  );
};
