import * as CL from '@design-system/component-library';
import { OnlineModelCategory } from '../../generated/api/onlineModelCategory.js';
import { createSizes, createSrcSet, getFileExtension } from '../../components/Picture/Picture.js';
import { formatSum, resolvePrices } from '../../common/utils/priceUtils.js';
import { getEffectiveVAT } from '../../components/ProductDetails/utils/productDetailsUtils.js';
import { getImageUrl } from '../../components/Picture/contentfulImageProxy.js';
import { getMimeType } from '../../components/Picture/mimeType.js';
import { isEmployeeUser } from '../../components/ProductDetails/utils/productDetailsEmployeeUtils.js';
import { monthlyChargeSummaryMsg, orStartingAtMonthlyMsg, t } from '../../common/i18n/index.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { CLProductGridProductWithRequiredId } from '../../common/types/componentLibrary';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader.js';
import type { Price } from '../../generated/api/price.js';

const resolveCurrentPriceAndPriceInfo = (prices: Price, onlineModelHeader: OnlineModelHeader, isEmployee: boolean) => {
  if (!prices.oneTimeCharge && onlineModelHeader.category !== OnlineModelCategory.ACCESSORIES) {
    return [
      prices?.monthlyRecurringCharge
        ? t.OD1M(orStartingAtMonthlyMsg, formatSum(prices?.monthlyRecurringCharge, true)!)
        : '',
      `${t.O108(
        monthlyChargeSummaryMsg,
        (prices.payments || 0).toString(),
        formatSum((prices.payments || 0) * (prices.monthlyRecurringCharge || 0)) || '',
        getEffectiveVAT(isEmployee)
      )}`,
    ];
  } else {
    return [
      prices?.oneTimeCharge ? String(formatSum(prices?.oneTimeCharge, true)) : '0',
      prices?.monthlyRecurringCharge
        ? t.OD1M(orStartingAtMonthlyMsg, formatSum(prices?.monthlyRecurringCharge, true)!)
        : '',
    ];
  }
};

const toPicture = (imageUrl: string, productName: string): JSX.Element => {
  const widthAlternatives = [90, 147, 175, 176, 180, 285, 294, 350, 352, 415, 570, 895];
  const sizes = createSizes({
    onPhone: 'min(136px, calc((100vw - 64px) * 0.35))',
    onPhoneLarge: 'calc(50vw - 65px)',
    onTablet: 'calc((100vw / 3) - 68px)',
    onLaptop: 'calc(25vw - 65px)',
  });

  const fileExtension = getFileExtension(imageUrl) || '';
  const mimeType = getMimeType(fileExtension) || '';
  const imageSources = [
    {
      srcSet: createSrcSet(imageUrl, widthAlternatives, false, undefined, 'avif'),
      media: '',
      type: 'image/avif',
    },
    {
      srcSet: createSrcSet(imageUrl, widthAlternatives, false, undefined, 'webp'),
      media: '',
      type: 'image/webp',
    },
    ...(mimeType
      ? [
          {
            srcSet: createSrcSet(imageUrl, widthAlternatives, false, undefined, undefined),
            media: '',
            type: mimeType,
          },
        ]
      : []),
  ];

  return (
    <CL.Picture
      src={imageUrl}
      i18n_picture_altText={productName}
      sources={imageSources}
      sizes={sizes}
      loadingMethod="lazy"
    />
  );
};

export const toProductGridProduct = (
  user: AuthenticatedUserState | undefined | null,
  onlineModelHeader: OnlineModelHeader,
  promotionText?: string
): CLProductGridProductWithRequiredId => {
  const imageUrl = encodeURI(getImageUrl(onlineModelHeader.listingImage));
  const productName = `${onlineModelHeader.manufacturer} ${onlineModelHeader.onlineModelName}`;
  const prices = resolvePrices(onlineModelHeader.lowestPrices.flatMap(price => price.prices));
  const [currentPrice, additionalInfo] = resolveCurrentPriceAndPriceInfo(
    prices,
    onlineModelHeader,
    user ? isEmployeeUser(user) : false
  );
  // CL does not support the new promotion text design yet so we use the product badge until it does
  const productBadge = promotionText ? (
    <CL.Badge color="primary" className="legacy-promotion-text" text={promotionText} />
  ) : onlineModelHeader.offerBadge ? (
    <CL.Badge
      className={`legacy-badge-${onlineModelHeader.offerBadge.type.toLowerCase()}`}
      color="primary"
      text={onlineModelHeader.offerBadge.text}
      i18n_badge_ariaLabel={onlineModelHeader.offerBadge.text}
    />
  ) : undefined;

  return {
    productId: onlineModelHeader.onlineModelCode,
    productImage: toPicture(imageUrl, productName),
    productURL: onlineModelHeader.pagePath || '',
    productName: productName,
    productBadge: productBadge,
    productPrice: <CL.Price currentPrice={currentPrice} currentPriceUnit="€" additionalInfo={additionalInfo} />,
  };
};

export const employeePromotionToProductGridProduct = (
  user: AuthenticatedUserState | undefined | null,
  onlineModelHeader: OnlineModelHeader
): CLProductGridProductWithRequiredId => {
  const productGridProduct = toProductGridProduct(user, onlineModelHeader);
  productGridProduct.productPrice = undefined;
  productGridProduct.productBadge = (
    <CL.Badge color="warning" className="legacy-employee-promotion-text" text={t.Y9JE('Paid by employer')} />
  );
  return productGridProduct;
};
