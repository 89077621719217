import { DeliveryOrder } from '../../generated/api/deliveryOrder';

export const excludeDeliveryFees = (deliveryOrder: DeliveryOrder) =>
  deliveryOrder.productType !== DeliveryOrder.ProductTypeEnum.DELIVERY_FEE;

// The delivery fees are special, they don't have costCenter nor reference, unlike other delivery order items.
// Note, EOE orders have a single costCenter & reference per order, which is used for all items in the order,
// however NOE orders can have different costCenter/reference per item.
// Once we've excluded the delivery fees we can safely pick the first delivery order item and use its details.
// NOTE! Do not use this in NOE, as an NOE order may have multiple different cost centers, etc.
export const getDefaultDeliveryOrder = (deliveryOrders: DeliveryOrder[]): DeliveryOrder | undefined =>
  deliveryOrders.filter(excludeDeliveryFees)[0];
