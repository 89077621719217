import * as CL from '@design-system/component-library';
import { t } from '../../../../common/i18n/index.js';

export const DnsRecordDisclaimerReadOnly = ({ email }: { email: string }) => (
  <CL.Disclaimer
    disclaimerType="info"
    icon={<CL.Icon icon="information" type="regular" aria-hidden="true" />}
    title={t.O3L6('You have read-only access')}
    visible
  >
    <p>
      {`${t.ICAD(
        'You have read-only access to the records. If you would like to extend your access rights, please contact'
      )} `}
      <a href={`mailto:${email}`}>{email}</a>
    </p>
  </CL.Disclaimer>
);
