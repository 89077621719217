import * as CL from '@design-system/component-library';
import type * as CLT from '@design-system/component-library';

import './Dropdown.scss';

// This Dropdown component gives a style to the CL.Dropdown that will
// hide the placeholder text from a dropdown list that is by default added into the
// dropdown list as a selectable item.
// This is a workaround and should be removed once the CL.Dropdown supports similar approach.
export const Dropdown = (props: CLT.DropdownProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, ref, ...rest } = props;
  return <CL.Dropdown className={`of-dropdown ${className || ''}`} {...rest} />;
};
