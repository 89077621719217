import { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails';
import { WorkingHours } from '../../generated/api/workingHours';
import { forceInternationalPhoneNumberFormat, formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import { notInUseMsg, t } from '../../common/i18n';
import type { NumberRangeData } from '../../common/loaders';
import CurrentActiveChainEnum = SubscriptionPbxDetails.CurrentActiveChainEnum;
import type { CLDropdownItem } from '../../common/types/componentLibrary';
import type { NumberRange } from '../../generated/api/numberRange';

export interface PbxSettingFormData {
  pbxType?: SubscriptionPbxDetails.PbxTypeEnum;

  currentActiveChain?: SubscriptionPbxDetails.CurrentActiveChainEnum;
  callerLineIdMasking?: SubscriptionPbxDetails.CallerLineIdMaskingEnum;
  callerLineIdTargetNumber?: SubscriptionPbxDetails.CallerLineIdTargetNumberEnum;
  callerLineIdNumber?: string;
  workingHoursReachabilityChain?: string[];
  offWorkReachabilityChain?: string[];

  corporateNumber: string;
  delayForChainCalls?: string;
  corporateNumberRangeId?: string;
  busyAction?: SubscriptionPbxConfiguration.BusyActionEnum;
  connectToNumber?: string;
  extensionNumber?: string;

  pbxSolutionId?: string;
  extensionRangeId?: string;
  corporateRangeId?: string;

  workingHoursWeekdayStart?: string;
  workingHoursWeekdayEnd?: string;
  workingHoursWeekendStart?: string;
  workingHoursWeekendEnd?: string;
  workingHoursSaturdayStart?: string;
  workingHoursSaturdayEnd?: string;
  workingHoursSundayStart?: string;
  workingHoursSundayEnd?: string;
}

const convertFromDisplayFormatToMillis = (hoursMinutes?: string) => {
  if (!hoursMinutes) {
    return undefined;
  }
  const [hours, minutes] = hoursMinutes
    .replace('.', ':')
    .split(':')
    .map(strNumber => parseInt(strNumber, 10));
  if (hours === undefined || minutes === undefined || Number.isNaN(hours) || Number.isNaN(minutes)) {
    return undefined;
  }
  return hours * 1000 * 60 * 60 + minutes * 1000 * 60;
};

export const workingHourErrors = (startTime?: string, endTime?: string) => {
  const startTimeMillis = convertFromDisplayFormatToMillis(startTime);
  const endTimeMillis = convertFromDisplayFormatToMillis(endTime);
  return !!(startTimeMillis && endTimeMillis && endTimeMillis < startTimeMillis);
};

export const getErrorForWorkingHourRespondents = (values: PbxSettingFormData) => {
  if (
    (values.currentActiveChain === CurrentActiveChainEnum.CALENDAR ||
      values.currentActiveChain === CurrentActiveChainEnum.WORKING_HOURS) &&
    values.workingHoursReachabilityChain &&
    (values.workingHoursReachabilityChain[0] === undefined || values.workingHoursReachabilityChain[0] === '')
  ) {
    return t.XI84('The first extension number is required for the office-hours chain when time control is selected');
  }
  return undefined;
};

export const getErrorForOffWorkingTimeRespondents = (values: PbxSettingFormData) => {
  if (
    values.currentActiveChain === CurrentActiveChainEnum.OFF_WORK &&
    values.offWorkReachabilityChain &&
    (values.offWorkReachabilityChain[0] === undefined || values.offWorkReachabilityChain[0] === '')
  ) {
    return t.ZML8('The first number for the out-of-office chain is required when out-of-office chain is selected');
  }
  return undefined;
};

export const formatPayload = (
  formData: PbxSettingFormData,
  pbxConfigurationToSave: SubscriptionPbxConfiguration
): SubscriptionPbxConfiguration => {
  const modifiedPbxConfiguration = { ...pbxConfigurationToSave };
  modifiedPbxConfiguration.pbxConfigurationDetails.currentActiveChain = formData.currentActiveChain;
  modifiedPbxConfiguration.connectToNumber = formData.connectToNumber;
  modifiedPbxConfiguration.pbxConfigurationDetails.callerLineIdTargetNumber = formData.callerLineIdTargetNumber;
  modifiedPbxConfiguration.pbxConfigurationDetails.callerLineIdMasking =
    formData.callerLineIdMasking ?? SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE;

  if (pbxConfigurationToSave.pbxConfigurationDetails.pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO) {
    modifiedPbxConfiguration.pbxConfigurationDetails.callerLineIdNumber = formData.callerLineIdNumber;
  }
  modifiedPbxConfiguration.corporateNumber = formData.corporateNumber;
  modifiedPbxConfiguration.pbxConfigurationDetails.offWorkReachabilityChain =
    formData.offWorkReachabilityChain?.filter(n => !!n) ?? [];
  modifiedPbxConfiguration.workingHoursReachabilityChain =
    formData.workingHoursReachabilityChain?.filter(n => !!n) ?? [];

  modifiedPbxConfiguration.delayForChainCalls = Number(formData.delayForChainCalls);
  modifiedPbxConfiguration.busyAction = formData.busyAction;
  modifiedPbxConfiguration.connectToNumber = formData.connectToNumber;
  modifiedPbxConfiguration.extensionNumber = formData.extensionNumber;

  const workingHours: WorkingHours[] = [];
  if (formData.workingHoursWeekdayStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekdayEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekdayStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.WEEKDAY,
    });
  }
  if (formData.workingHoursWeekendStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekendEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekendStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.WEEKEND,
    });
  }
  if (formData.workingHoursSaturdayStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursSaturdayEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursSaturdayStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.SATURDAY,
    });
  }
  if (formData.workingHoursSundayStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursSundayEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursSundayStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.SUNDAY,
    });
  }
  modifiedPbxConfiguration.workingHours = workingHours;

  return modifiedPbxConfiguration;
};

export const getTimeDisplayFormat = (hours: number, minutes: number) => {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  // IE11 produces some magic UTF characters with this function, those need to be replaced, thus the .replace
  return date
    .toLocaleTimeString(undefined, { hour: '2-digit', hour12: false, minute: '2-digit' })
    .replace(/[^0-9.:]/g, '');
};

const getWorkingHoursByType = (hourType: WorkingHours.WorkingHoursTypeEnum, workingHours?: WorkingHours[]) =>
  workingHours?.find(({ workingHoursType }) => workingHoursType === hourType);

const convertMillisFromMidnightToDisplayFormat = (millisFromMidnight?: number) => {
  if (millisFromMidnight === undefined) {
    return undefined;
  }
  const hours = Math.trunc(millisFromMidnight / 1000 / 60 / 60);
  const minutes = (millisFromMidnight / 1000 / 60) % 60;
  return getTimeDisplayFormat(hours, minutes);
};

const filterWorkingHoursPbxConfiguration = (
  workingHours: WorkingHours[],
  hourType: WorkingHours.WorkingHoursTypeEnum,
  startTime: boolean,
  formatted: boolean
) => {
  const hours = getWorkingHoursByType(hourType, workingHours);
  if (hours) {
    const millisFromMidnight = startTime ? hours.workingHoursStartTime : hours.workingHoursEndTime;
    return formatted ? convertMillisFromMidnightToDisplayFormat(millisFromMidnight) : millisFromMidnight;
  }
  return undefined;
};

export const filterWorkingHours = (
  config: SubscriptionPbxConfiguration,
  hourType: WorkingHours.WorkingHoursTypeEnum,
  startTime: boolean,
  formatted: boolean
) => {
  if (config.workingHours) {
    return filterWorkingHoursPbxConfiguration(config.workingHours, hourType, startTime, formatted);
  }
  return undefined;
};

export const createPbxWorkingHoursConfig = (config: SubscriptionPbxConfiguration) => ({
  currentActiveChain: config.pbxConfigurationDetails.currentActiveChain,
  callerLineIdMasking:
    config.pbxConfigurationDetails.callerLineIdMasking ?? SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE,
  callerLineIdNumber: forceInternationalPhoneNumberFormat(config.pbxConfigurationDetails.callerLineIdNumber),
  callerLineIdTargetNumber: config.pbxConfigurationDetails.callerLineIdTargetNumber,
  workingHoursReachabilityChain: config.workingHoursReachabilityChain,
  offWorkReachabilityChain: config.pbxConfigurationDetails.offWorkReachabilityChain,
  connectToNumber: config.connectToNumber,
  workingHoursSaturdayEnd: filterWorkingHours(
    config,
    WorkingHours.WorkingHoursTypeEnum.SATURDAY,
    false,
    true
  )?.toString(),
  workingHoursSaturdayStart: filterWorkingHours(
    config,
    WorkingHours.WorkingHoursTypeEnum.SATURDAY,
    true,
    true
  )?.toString(),
  workingHoursSundayEnd: filterWorkingHours(config, WorkingHours.WorkingHoursTypeEnum.SUNDAY, false, true)?.toString(),
  workingHoursSundayStart: filterWorkingHours(config, WorkingHours.WorkingHoursTypeEnum.SUNDAY, true, true)?.toString(),
  workingHoursWeekdayEnd: filterWorkingHours(
    config,
    WorkingHours.WorkingHoursTypeEnum.WEEKDAY,
    false,
    true
  )?.toString(),
  workingHoursWeekdayStart: filterWorkingHours(
    config,
    WorkingHours.WorkingHoursTypeEnum.WEEKDAY,
    true,
    true
  )?.toString(),
  workingHoursWeekendEnd: filterWorkingHours(
    config,
    WorkingHours.WorkingHoursTypeEnum.WEEKEND,
    false,
    true
  )?.toString(),
  workingHoursWeekendStart: filterWorkingHours(
    config,
    WorkingHours.WorkingHoursTypeEnum.WEEKEND,
    true,
    true
  )?.toString(),
});

export const updateConfigurationToSaveWithWorkingHours = (
  formData: PbxSettingFormData,
  pbxConfigurationToSave: SubscriptionPbxConfiguration
) => {
  const workingHours: WorkingHours[] = [];
  if (formData.workingHoursWeekdayStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekdayEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekdayStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.WEEKDAY,
    });
  }
  if (formData.workingHoursWeekendStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekendEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursWeekendStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.WEEKEND,
    });
  }
  if (formData.workingHoursSaturdayStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursSaturdayEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursSaturdayStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.SATURDAY,
    });
  }
  if (formData.workingHoursSundayStart) {
    workingHours.push({
      workingHoursEndTime: convertFromDisplayFormatToMillis(formData.workingHoursSundayEnd),
      workingHoursStartTime: convertFromDisplayFormatToMillis(formData.workingHoursSundayStart),
      workingHoursType: WorkingHours.WorkingHoursTypeEnum.SUNDAY,
    });
  }
  pbxConfigurationToSave.workingHours = workingHours;
  return pbxConfigurationToSave;
};

export const getAllNumbersFromAllRanges = (numberRanges: NumberRange[]) =>
  numberRanges
    .reduce((allNumbers: string[], numberRange) => {
      const startNumber = Number.parseInt(numberRange.startNumber, 10);
      const endNumber = Number.parseInt(numberRange.endNumber, 10);
      const size = endNumber + 1 - startNumber;
      return allNumbers.concat(
        Array.from(
          { length: size },
          (_element, i) =>
            // Reinsert leading plus or zero if it was present before converting to number
            ((numberRange.startNumber.startsWith('+') && '+') || '') +
            ((numberRange.startNumber.startsWith('0') && '0') || '') +
            (startNumber + i).toString()
        )
      );
    }, [])
    .sort();

export const numberConversionOptions = (pbxType: SubscriptionPbxDetails.PbxTypeEnum | undefined) => {
  const options = [
    {
      label: t.JR2G('Always in use'),
      value: SubscriptionPbxDetails.CallerLineIdMaskingEnum.ALWAYS_ON.toString(),
    },
    {
      label: t.MUF5(notInUseMsg),
      value: SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE.toString(),
    },
  ];
  if (pbxType !== SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU) {
    options.push({
      label: t.D4L5('In use during company number’s scheduled office hours'),
      value: SubscriptionPbxDetails.CallerLineIdMaskingEnum.BY_CALENDAR.toString(),
    });
  }
  return options;
};

export const corporateNumberDropdownItems = (
  pbxConfiguration: PbxSettingFormData,
  numberRange?: NumberRangeData
): CLDropdownItem[] => {
  const options: CLDropdownItem[] =
    numberRange?.allNumbers?.map(phoneNumber => ({
      label: formatPhoneNumber(phoneNumber, true),
      value: forceInternationalPhoneNumberFormat(phoneNumber)!,
    })) ?? [];

  const numberAdded = options.find(
    option => option.label === formatPhoneNumber(pbxConfiguration.corporateNumber, true)
  );
  if (pbxConfiguration.corporateNumber && !numberAdded) {
    options.push({
      label: formatPhoneNumber(pbxConfiguration.corporateNumber, true),
      value: forceInternationalPhoneNumberFormat(pbxConfiguration.corporateNumber)!,
    });
  }
  return options;
};

export const extensionNumberOptions = (
  pbxConfiguration: PbxSettingFormData,
  extensionNumberRange?: NumberRangeData
): CLDropdownItem[] => {
  const options: CLDropdownItem[] =
    extensionNumberRange?.allNumbers?.map(extensionNumber => ({
      label: formatPhoneNumber(extensionNumber),
      value: extensionNumber,
    })) ?? [];

  const numberAdded =
    pbxConfiguration.extensionNumber !== undefined &&
    options.find(option => option.label === formatPhoneNumber(pbxConfiguration.extensionNumber!));

  if (pbxConfiguration.extensionNumber && !numberAdded) {
    options.push({
      label: formatPhoneNumber(pbxConfiguration.extensionNumber),
      value: pbxConfiguration.extensionNumber,
    });
  }

  return options;
};

export const resolveBusyActionLocalization = (
  busyAction?: SubscriptionPbxConfiguration.BusyActionEnum,
  connectToNumber?: string
) => {
  switch (busyAction) {
    case SubscriptionPbxConfiguration.BusyActionEnum.NEXT_NUMBER_IN_CHAIN:
      return t.EGRR('Forward to next number in chain');
    case SubscriptionPbxConfiguration.BusyActionEnum.BUSY_TONE:
      return t.J5SL('Busy signal');
    case SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER:
      return `${t.HS6F('Forward to')} ${formatPhoneNumber(connectToNumber ?? '')}`;
    default:
      return undefined;
  }
};

export const activeChainItems = () => [
  {
    label: t.NQ09('According to company number’s office hours (scheduled)'),
    value: SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR.toString(),
  },
  {
    label: t.O9WF('Office hours (manual)'),
    value: SubscriptionPbxDetails.CurrentActiveChainEnum.WORKING_HOURS.toString(),
  },
  {
    label: t.VJCA('Out-of-office (manual)'),
    value: SubscriptionPbxDetails.CurrentActiveChainEnum.OFF_WORK.toString(),
  },
];
