import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses';
import { t } from '../../common/i18n';

export const OmalaskuStatusFailedDisclaimer = () => (
  <div className={dsClass.MARGIN_TOP_6}>
    <CL.Disclaimer
      text={t.P6A0('Failed to find information about OmaLasku. Try again later.')}
      disclaimerType="alert"
      icon={<CL.Icon icon="warning" aria-hidden="true" />}
      visible
    />
  </div>
);
