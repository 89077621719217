import {
  archiveMsg,
  bulletinsMsg,
  contactUsMsg,
  devicesMsg,
  instructionsMsg,
  loginMsg,
  myAccountMsg,
  myOrdersMsg,
  myRedeemRequestsMsg,
  myServicesMsg,
  omaLaskuServicesMsg,
  orderRequestsMsg,
  ordersMsg,
  redeemRequestsMsg,
  shopMsg,
  subscriptionsMsg,
  supportMsg,
  t,
} from '../../../common/i18n/index.js';
import { navigationItemListWithCurrentProperty } from '../../Header/dynamic/headerFunctions.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { CLHeaderNestedSubMenu } from '../../../common/types/componentLibrary';

const employeeNavigationItemList = (
  hasApproverRole: boolean,
  hasPublishedCatalogs: boolean,
  siteBaseUrl: string
): CLHeaderNestedSubMenu[] => {
  return [
    {
      title: t.OHZ1(myServicesMsg),
      subMenu: [
        {
          title: t.JPRH(subscriptionsMsg),
          url: paths.EMPLOYEE_SUBSCRIPTIONS,
        },
        {
          title: t.JJTM(devicesMsg),
          url: paths.EMPLOYEE_DEVICES,
        },
      ],
    },
    {
      title: t.EG6F(myOrdersMsg),
      subMenu: [
        {
          title: t.AUA3(myOrdersMsg),
          url: paths.EMPLOYEE_OWN_ORDERS,
        },
        {
          title: t.ZB3L(myRedeemRequestsMsg),
          url: paths.EMPLOYEE_OWN_CHANGE_REQUESTS,
        },
      ],
    },
    ...(hasApproverRole
      ? [
          {
            title: t.Y29L(orderRequestsMsg),
            subMenu: [
              {
                title: t.I9C0(ordersMsg),
                url: paths.EMPLOYEE_PENDING_REVIEW_ORDERS,
              },
              {
                title: t.Z1M1(redeemRequestsMsg),
                url: paths.EMPLOYEE_CHANGE_REQUEST_ORDERS,
              },
              {
                title: t.B2B9(archiveMsg),
                url: paths.EMPLOYEE_FINISHED_REVIEW_ORDERS,
              },
            ],
          },
        ]
      : []),
    {
      title: t.FJOL(omaLaskuServicesMsg),
      url: paths.EMPLOYEE_OMALASKU,
    },
    {
      title: t.SEBD(myAccountMsg),
      url: paths.EMPLOYEE_OWN_INFO,
    },
    ...(hasPublishedCatalogs
      ? [
          {
            title: t.BZGP(shopMsg),
            url: paths.EMPLOYEE_DEVICE_STORE,
          },
        ]
      : []),
    {
      title: t.VUU0(supportMsg),
      subMenu: [
        {
          title: t.RD4R(instructionsMsg),
          url: `${siteBaseUrl}${paths.INSTRUCTIONS}`,
          target: '_blank',
        },
        {
          title: t.NYU3(contactUsMsg),
          url: `${siteBaseUrl}${paths.CONTACT_INFO}`,
          target: '_blank',
        },
        {
          title: t.JJWP(bulletinsMsg),
          url: paths.BULLETINS,
          target: '_blank',
        },
        {
          title: t.G9QF(loginMsg),
          url: `${siteBaseUrl}${paths.CORPORATE_SERVICES}`,
          target: '_blank',
        },
      ],
    },
  ];
};

export const employeeNavigationLinksWithCurrentProperty = (
  hasApproverRole: boolean,
  hasPublishedCatalogs: boolean,
  currentPath: string,
  siteBaseUrl: string
): CLHeaderNestedSubMenu[] => {
  return navigationItemListWithCurrentProperty(
    employeeNavigationItemList(hasApproverRole, hasPublishedCatalogs, siteBaseUrl),
    currentPath
  );
};
