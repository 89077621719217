import * as CL from '@design-system/component-library';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n';

export const BlackListedAccountDisclaimer = () => {
  return (
    <CL.Disclaimer
      disclaimerType="info"
      icon={<CL.Icon icon="lock" type="regular" aria-hidden="true" />}
      title={t.UB77('Access to the licence management portal is restricted')}
      text={t.OBVW(
        'Access to the licence management portal is restricted. To resolve the situation, contact our customer service.'
      )}
      visible
    >
      <CL.Link linkHref={paths.SUPPORT_CASE_NEW}>{t.AKW8('Leave a message to customer service')}</CL.Link>
    </CL.Disclaimer>
  );
};
