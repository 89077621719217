import * as CL from '@design-system/component-library';
import { InProgressModal } from './OmalaskuOrderInProgressModal';
import { OmalaskuBarringFields } from '../../common/enums';
import { OmalaskuCancellationModal } from './OmalaskuCancellationModal';
import { OmalaskuStatusResponse } from '../../generated/api/omalaskuStatusResponse';
import { dsClass } from '../../common/constants/dsClasses';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import { getOmalaskuBarringsTitleText, getOmalaskuStatus } from './omalaskuUtils';
import { palvelunumerotMsg, subscriptionMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { OmalaskuStatusWithMsisdnResponse } from './omalaskuUtils';
import SpeechBarringsEnum = OmalaskuStatusResponse.SpeechBarringsEnum;
import { Status } from '../Status/Status';
import type { CLTableColumn } from '../../common/types/componentLibrary';

export interface OmalaskuBarringStatusProps {
  status: OmalaskuStatusWithMsisdnResponse;
}

const columns: CLTableColumn[] = [
  {
    key: OmalaskuBarringFields.SUBSCRIPTION_USER_FRIENDLY_ID,
    label: t.P674(subscriptionMsg),
    width: '33%',
  },
  {
    key: OmalaskuBarringFields.BARRING_STATUS,
    label: t.CH87(palvelunumerotMsg),
    width: '33%',
  },
  {
    key: OmalaskuBarringFields.BARRINGS,
    label: t.GQAX('Call barrings'),
    width: '34%',
  },
];

export const OmalaskuBarringStatus = ({ status }: OmalaskuBarringStatusProps) => {
  const [showInProgressModal, setShowInProgressModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const navigate = useNavigate();
  const rows = [
    {
      [OmalaskuBarringFields.SUBSCRIPTION_USER_FRIENDLY_ID]: <>{formatPhoneNumber(status.msisdn)}</>,
      [OmalaskuBarringFields.BARRING_STATUS]: (
        <Status
          textAfterBadge={getOmalaskuStatus(status.omaLaskuStatus).status}
          color={getOmalaskuStatus(status.omaLaskuStatus).color}
        />
      ),
      [OmalaskuBarringFields.BARRINGS]: <>{getOmalaskuBarringsTitleText(status.speechBarrings)}</>,
    },
  ];

  const noBarrings = status.speechBarrings.length === 1 && status.speechBarrings[0] === SpeechBarringsEnum.NONE;

  const onClick = () => {
    switch (status.omaLaskuStatus) {
      case OmalaskuStatusResponse.OmaLaskuStatusEnum.Active:
        setShowCancellationModal(true);
        break;
      case OmalaskuStatusResponse.OmaLaskuStatusEnum.Inactive:
        navigate(`${paths.EMPLOYEE_OMALASKU}/${status.msisdn}`);
        break;
      case OmalaskuStatusResponse.OmaLaskuStatusEnum.Activation_Inprogress:
      case OmalaskuStatusResponse.OmaLaskuStatusEnum.Cancellation_Inprogress:
        setShowInProgressModal(true);
        break;
    }
  };

  return (
    <>
      <div className={dsClass.MARGIN_TOP_6}>
        <CL.Table columns={columns} rows={rows} tableType="nude"></CL.Table>
        <hr className="of-employee-omalasku__table-end" />
        <div className={dsClass.MARGIN_TOP_4}>
          {!noBarrings && (
            <CL.Button color="light" onClick={onClick}>
              {status.omaLaskuStatus === OmalaskuStatusResponse.OmaLaskuStatusEnum.Active
                ? t.LGPO('Remove service')
                : t.E51E('Read more and activate')}
            </CL.Button>
          )}
        </div>
      </div>
      {showInProgressModal && <InProgressModal onclose={() => setShowInProgressModal(false)} />}
      {showCancellationModal && (
        <OmalaskuCancellationModal msisdn={status.msisdn} onClose={() => setShowCancellationModal(false)} />
      )}
    </>
  );
};
