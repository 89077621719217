import * as CL from '@design-system/component-library';
import { DeliveryMethodAndLanguageSelection } from '../../components/DeliveryMethodAndLanguageSelection.js';
import { Dropdown } from '../../../../common/react-hook-form/components/Dropdown.js';
import { ELECTRONIC_ADDRESS_REGEX } from '../../../../common/utils/validationUtils.js';
import { GridColumn } from '../../components/GridColumn.js';
import { TextInput } from '../../../../common/react-hook-form/fields/index.js';
import {
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  invalidEInvoiceAddressMsg,
  t,
} from '../../../../common/i18n/index.js';
import { getElectronicInvoiceOperatorDropDownOptions } from '../../../../common/utils/billingAccountUtils.js';
import type { BillChannel } from '../../../../generated/api/billChannel.js';
import type { CLDropdownItem } from '../../../../common/types/componentLibrary';

interface BillingAccountElectronicDeliveryMethodProps {
  deliveryMethodOptions: CLDropdownItem[];
  billChannels: BillChannel[];
  disabled?: boolean;
}

export const BillingAccountElectronicDeliveryMethodEdit = ({
  billChannels,
  deliveryMethodOptions,
  disabled = false,
}: BillingAccountElectronicDeliveryMethodProps) => {
  const validateElectronicAddress = (value: string) => {
    if (!ELECTRONIC_ADDRESS_REGEX.test(value)) {
      return t.NMXU(invalidEInvoiceAddressMsg);
    }
    return undefined;
  };

  return (
    <>
      <DeliveryMethodAndLanguageSelection deliveryMethodOptions={deliveryMethodOptions} disabled={disabled} />
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <Dropdown
            name="billingAccount.billElectronicOperator"
            id="billElectronicOperator"
            items={getElectronicInvoiceOperatorDropDownOptions(billChannels)}
            label={t.WVLB(eInvoicingOperatorMsg)}
            disabled={disabled}
          />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <TextInput
            name="billingAccount.billElectronicAddress"
            label={t.OL7B(eInvoicingAddressMsg)}
            validate={validateElectronicAddress}
            disabled={disabled}
          />
        </GridColumn>
      </CL.GridRow>
    </>
  );
};
