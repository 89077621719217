import * as CL from '@design-system/component-library';
import { ActingOnBehalfOfCompany } from './ActingOnBehalfOfCompany.js';
import { BillingDetails } from './BillingDetails.js';
import { ChooseWhetherAllBillingAccountsApply } from './ChooseWhetherAllBillingAccountsApply.js';
import { IsBillingContactPerson } from './IsBillingContactPerson.js';
import { PayerNameOrAddressChange } from './PayerNameOrAddressChange.js';
import { WillChangeTakeEffectImmediately } from './WillChangeTakeEffectImmediately.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { editMsg, nextMsg, submitMsg, t } from '../../../common/i18n/index.js';
import { loadBillChannels } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { ContactSupportInfo } from '../types.js';

import './EOperatorChangeRequestForm.scss';

export interface EOperatorChangeRequestFormProps {
  isAnonymousUser: boolean;
  isEditing?: boolean;
  onSetEditMode?: (isEditing: boolean) => void;
  isSubmitting?: boolean;
  onSubmit: (formValues: EOperatorChangeRequestFormValues, contactValues: ContactSupportInfo) => void;
  contactValues: ContactSupportInfo;
}

export interface FormEditProps {
  isEditing?: boolean;
}

interface ChangeWillTakeEffectDisclaimerProps {
  effectiveDate?: string;
  isAnonymousUser: boolean;
}

type BillingAccount = {
  billingAccountId: string;
};

export type EOperatorChangeRequestFormValues = {
  willChangeTakeEffectImmediately: boolean;
  actingOnBehalfOfCompany: boolean;
  actingOnBehalfOfCompanyBusinessId?: string;
  actingOnBehalfOfCompanyBusinessName?: string;
  actingOnBehalfOfCompanySearch?: string;
  actingOnBehalfOfCompanyCustomerNumber?: string;
  allBillingAccountsApply: boolean;
  customerNumber?: string;
  deliveryMethod: string;
  billLanguage: string;
  eOperator: string;
  eInvoiceAddress: string;
  isBillingContactPerson: boolean;
  payerNameOrAddressChange: boolean;
  effectiveDate?: string;
  billingAccounts?: BillingAccount[];
  billingContactPersonFirstName?: string;
  billingContactPersonLastName?: string;
  billingContactPersonEmail?: string;
  billingContactPersonPhone?: string;
  payerName?: string;
  payerAdditionalInfo?: string;
  payerPostalAddress?: string;
  payerPostalCode?: string;
  payerPostOffice?: string;
};

const PricingDisclaimer = () => (
  <CL.Disclaimer
    className={dsClass.MARGIN_BOTTOM_4}
    icon={<CL.Icon icon="information" type="filled" aria-hidden="true" />}
    visible
  >
    <p>
      <span>
        {t.XKFL(
          'Requests for changes to invoicing details made using this form will be processed free of charge. For change requests made via Customer Service, we charge a fee in accordance with '
        )}
      </span>
      <a
        className={dsClass.MARGIN_RIGHT_0}
        href="https://yrityksille.elisa.fi/palveluhinnasto"
        target="_blank"
        rel="noreferrer"
      >
        {t.ZUWC('the service price list for corporate customers')}
      </a>
      <span>{t.HNN2('.')}</span>
    </p>
  </CL.Disclaimer>
);

const ChangeWillTakeEffectDisclaimer = ({ effectiveDate, isAnonymousUser }: ChangeWillTakeEffectDisclaimerProps) => {
  let message;
  if (isAnonymousUser) {
    message = effectiveDate
      ? t.IH0Z(
          'The change will take effect on the selected date. You will receive an email concerning the amended agreements.'
        )
      : t.QDU2(
          'The change will take effect in approximately 24 hours. You will receive an email concerning the amended agreements.'
        );
  } else {
    message = effectiveDate
      ? t.EFC1(
          `The change will take effect on the selected date. The status of the ticket can be viewed in the messaging center. A notification of the agreements having been amended will be sent to the messaging center and the contact person’s email.`
        )
      : t.TF4R(
          `The change will take effect in approximately 24 hours. The status of the ticket can be viewed in the messaging center. A notification of the agreements having been amended will be sent to the messaging center and the contact person’s email.`
        );
  }
  return (
    <div className={dsClass.PADDING_BOTTOM_4}>
      <h4 className={dsClass.MARGIN_BOTTOM_2}>{t.M9HN('Date on which the change will take effect')}</h4>
      <p className={dsClass.MARGIN_BOTTOM_0}>{message}</p>
      <p className={dsClass.TEXT_SMALL}>{new Date().toLocaleDateString('fi')}</p>
    </div>
  );
};

export const EOperatorChangeRequestForm = ({
  onSubmit,
  isEditing,
  isSubmitting,
  onSetEditMode = () => {},
  isAnonymousUser,
  contactValues,
}: EOperatorChangeRequestFormProps) => {
  const methods = useFormContext<EOperatorChangeRequestFormValues>();
  const dispatch = useDispatch();
  const { handleSubmit, getValues, setValue, watch } = methods;
  const values = getValues();

  useEffect(() => {
    dispatch(loadBillChannels());
  }, [dispatch]);

  const onPreview = () => {
    if (values.actingOnBehalfOfCompanyBusinessName && values.actingOnBehalfOfCompanyBusinessId) {
      setValue(
        'actingOnBehalfOfCompanySearch',
        `${values.actingOnBehalfOfCompanyBusinessName}, ${values.actingOnBehalfOfCompanyBusinessId}`
      );
    }
    onSetEditMode(false);
  };

  return (
    <div className="of-eoperator-change-request-form" data-testid="of-eoperator-change-form">
      <PricingDisclaimer />
      <WillChangeTakeEffectImmediately isEditing={isEditing} />
      <ActingOnBehalfOfCompany isEditing={isEditing} />
      <ChooseWhetherAllBillingAccountsApply isEditing={isEditing} />
      <BillingDetails
        requireCustomerNumber={isAnonymousUser && !watch().actingOnBehalfOfCompany}
        isEditing={isEditing}
      />
      <IsBillingContactPerson isEditing={isEditing} />
      <PayerNameOrAddressChange isEditing={isEditing} />
      {isEditing ? (
        <>
          <p className={dsClass.MARGIN_BOTTOM_4}>
            {t.RSDJ(
              'In the next step, you can preview and check that the form has been filled in correctly. After that, you can send the form for processing.'
            )}
          </p>
          <CL.Button onClick={handleSubmit(onPreview)}>{t.F0MY(nextMsg)}</CL.Button>
        </>
      ) : (
        <>
          <ChangeWillTakeEffectDisclaimer effectiveDate={values.effectiveDate} isAnonymousUser={isAnonymousUser} />
          <CL.Button
            className={dsClass.MARGIN_RIGHT_4}
            disabled={isSubmitting}
            color="light"
            onClick={() => onSetEditMode(true)}
          >
            {t.NVPK(editMsg)}
          </CL.Button>
          <CL.Button onClick={handleSubmit(() => onSubmit(getValues(), contactValues))} loading={isSubmitting}>
            {t.FSWU(submitMsg)}
          </CL.Button>
        </>
      )}
    </div>
  );
};
