import * as CL from '@design-system/component-library';
import { activateMsg, discountVoucherMsg, t } from '../../common/i18n/index.js';
import { clearCampaigns, getCampaigns } from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { validCampaignsPresent } from '../../common/utils/campaignUtils.js';
import type { ChangeEvent, FormEvent } from 'react';
import type { SalesType } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './Voucher.scss';

interface VoucherProps {
  onlineModelCode: string;
  salesType: SalesType;
  onClear?: () => void;
  onSubmit?: () => void;
}

export const Voucher = ({ onlineModelCode, salesType, onClear, onSubmit }: VoucherProps) => {
  const dispatch = useDispatch();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  // We can't have a ref on the Input, so we have to use the parent
  const rootRef = useRef<HTMLFormElement>(null);

  const campaigns = useSelector((state: State) => state.campaigns, deepEqual);
  const loading = campaigns?.loading || false;
  const activeVoucher = campaigns?.voucher || '';
  const activeVoucherOnlineModelCode = campaigns?.onlineModelCode;
  const campaignErrors = campaigns?.errors || [];
  const campaignContextsFromVoucher = campaigns?.campaignContexts || [];

  // Use active voucher only if it was activated for this online model.
  const [voucher, setVoucher] = useState(onlineModelCode === activeVoucherOnlineModelCode ? activeVoucher : '');
  const [error, setError] = useState<string | undefined>();

  // checkedVoucher is used to see if the errors are actually for _this_ voucher.
  const hasErrors = Boolean(activeVoucher === voucher && campaignErrors.length);
  // This is used to disable 'activate' button and to add the clear-icon to the input
  const validVoucherFound = Boolean(activeVoucher && !hasErrors && validCampaignsPresent(campaignContextsFromVoucher));

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    setVoucher(e.target.value || '');
  };

  const focusInput = () => {
    // Timeout is needed to delay the focus until after the rerender caused by clearing the field
    window.setTimeout(() => {
      const input = rootRef.current?.querySelector('input');
      if (input) {
        input.focus();
        input.select();
      }
    }, 100);
  };

  useEffect(() => {
    if (!activeVoucher) {
      setVoucher('');
    }
  }, [activeVoucher]);

  const clearValue = () => {
    setError(undefined);
    setVoucher('');
    focusInput();
  };

  const clearCampaign = () => {
    clearValue();
    if (onClear) {
      onClear();
    }
    dispatch(clearCampaigns());
  };

  // clear campaings & voucher when mdmId changes
  useEffect(() => {
    setError(undefined);
    setVoucher('');
    dispatch(clearCampaigns());
  }, [dispatch, mdmId]);

  const checkVoucher = (e: FormEvent) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
    if (validVoucherFound) {
      setError(t.UJ7S('You can use only one voucher code at a time.'));
    } else {
      dispatch(
        getCampaigns({
          onlineModel: onlineModelCode,
          voucher,
          salesType,
        })
      );
    }
  };

  useEffect(() => {
    if (hasErrors) {
      setError(t.IQAF('No voucher code found.'));
    }
  }, [hasErrors]);

  useLayoutEffect(() => {
    if (hasErrors) {
      focusInput();
    }
  }, [hasErrors]);

  // We're using disabled instead of readonly just because readonly styling is totally unfitting for the purpose
  return (
    <div className={`of-campaign-voucher ${dsClass.MARGIN_BOTTOM_3}`}>
      <div className="of-campaign-voucher__container">
        <form className="of-campaign-voucher__form" onSubmit={checkVoucher} ref={rootRef}>
          <CL.Input
            clear={!loading}
            disabled={loading}
            i18n_input_clearFieldTitle={t.HH00('Clear')}
            i18n_input_errorMessage={error}
            i18n_input_optionalText=""
            id="voucherInput"
            label={t.GOH2(discountVoucherMsg)}
            name="voucherInput"
            onClearInput={clearValue}
            onValueChange={onValueChange}
            optional={true}
            placeholder={t.NI34('Enter discount voucher')}
            type="text"
            value={validVoucherFound ? '' : voucher}
            controlled={true}
          />
          <CL.Button
            disabled={!voucher.length || hasErrors}
            id="voucherButton"
            i18n_button_loading_ariaText={t.WFEF('Checking voucher {}', voucher)}
            loading={loading}
            size="l"
            type="submit"
          >
            {t.EITS(activateMsg)}
          </CL.Button>
        </form>
        {validVoucherFound && (
          <CL.Disclaimer
            text={t.QK7P('Voucher code "{}" already in use.', activeVoucher)}
            className="of-campaign-voucher__disclaimer"
            showCloseButton
            onDisclaimerHide={clearCampaign}
            disclaimerType="success"
            visible
          />
        )}
      </div>
    </div>
  );
};
