import * as CL from '@design-system/component-library';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { RedeemOptionDetails } from '../EppRedeem/EppRedeemOptionDetails.js';
import { RedeemOptions } from '../EppRedeem/EppRedeemOptions.js';
import {
  billingAccountMsg,
  changeOfDeviceMsg,
  checkoutRedeemOptionTitleMsg,
  contractTypeHeadingMsg,
  employeeInvoiceNotesMsg,
  employeeOmaLaiteLaskuMissingMsg,
  endTimeMsg,
  expiringEppSubsInfoMsg,
  forTheCompanyMsg,
  forTheEmployeeMsg,
  newContractMsg,
  oldEppDeviceHeadingMsg,
  redeemDeviceMsg,
  replaceableDevicesMsg,
  returnDeviceMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { useEffect, useState } from 'react';
import type { CLDescriptionItem } from '../../common/types/componentLibrary';
import type { DeviceChangeRequest } from '../../common/types/device.js';
import type { Subscription } from '../../generated/api/models.js';

import './RedeemOrReturn.scss';

const getAdditionalFields = (
  selectedSubscription: Subscription,
  redeemType?: EppRedeemTerminateRequestType
): CLDescriptionItem[] => {
  return redeemType === EppRedeemTerminateRequestType.COMPANY_REDEEM
    ? [{ title: t.IFT9(billingAccountMsg), description: selectedSubscription?.billingAccountDisplayId || '' }]
    : [];
};

type RedeemOrReturnProps = {
  expiringSubs: Subscription[];
  contactId: string;
  selectedPouIndex?: string;
  onSelectSubscriptionForDeviceChange: (
    contactId: string,
    subscriptionId?: string,
    subscriptionBillingAccountId?: string,
    redeemType?: EppRedeemTerminateRequestType
  ) => void;
  deviceChangeRequest?: DeviceChangeRequest;
  companyName?: string;
  subscriptionIdForDeviceChange?: string;
  isDeviceChangeSelectedWithSubscriptionId?: boolean;
  isFirstCartItem: boolean;
};

export const RedeemOrReturn = (props: RedeemOrReturnProps) => {
  const {
    expiringSubs,
    contactId,
    selectedPouIndex,
    onSelectSubscriptionForDeviceChange,
    deviceChangeRequest,
    companyName,
    subscriptionIdForDeviceChange,
    isDeviceChangeSelectedWithSubscriptionId,
    isFirstCartItem,
  } = props;
  const [isChangeDeviceSelected, setIsChangeDeviceSelected] = useState(isFirstCartItem && expiringSubs.length > 0);
  const [isRedeemSelected, setIsRedeemSelected] = useState(isFirstCartItem ? false : undefined);
  const [redeemType, setRedeemType] = useState<EppRedeemTerminateRequestType | undefined>(undefined);
  const [selectedSubscription, setSelectedSubscription] = useState(isFirstCartItem ? expiringSubs[0] : undefined);

  useEffect(() => {
    if (isChangeDeviceSelected) {
      onSelectSubscriptionForDeviceChange(
        contactId,
        selectedSubscription?.subscriptionId,
        selectedSubscription?.billingAccountId,
        redeemType
      );
    }
  }, [isChangeDeviceSelected, contactId, selectedSubscription, redeemType]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CL.Grid>
      <CL.GridRow className={dsClass.NO_GUTTER}>
        <CL.GridCol
          colWidth={12}
          className={`of-device-checkout-cart-product__bordered of-device-checkout-cart-product__redeem-or-return-wrapper ${dsClass.MARGIN_TOP_5}`}
        >
          <h4 className={dsClass.MARGIN_TOP_4}>{t.OLZS(contractTypeHeadingMsg)}</h4>
          <CL.Icon icon="information" />
          <span className={`information-message ${dsClass.PADDING_LEFT_2}`}>{t.F25D(expiringEppSubsInfoMsg)}</span>
          <CL.SelectGroup
            products={[
              {
                id: 'change',
                name: t.ZHBT(changeOfDeviceMsg),
              },
              {
                id: 'new-contract',
                name: t.K3GT(newContractMsg),
              },
            ]}
            value={isChangeDeviceSelected ? 'change' : 'new-contract'}
            onChange={id => {
              if (id === 'change') {
                setIsChangeDeviceSelected(true);
                setIsRedeemSelected(isRedeemSelected === undefined ? false : isRedeemSelected);
                if (isFirstCartItem && isDeviceChangeSelectedWithSubscriptionId && subscriptionIdForDeviceChange) {
                  const subscriptionForDeviceChange = expiringSubs.find(
                    subscription => subscription.subscriptionId === subscriptionIdForDeviceChange
                  );
                  setSelectedSubscription(subscriptionForDeviceChange);
                } else {
                  setSelectedSubscription(expiringSubs[0]);
                }
              } else if (id === 'new-contract' && isChangeDeviceSelected) {
                setIsChangeDeviceSelected(false);
                setSelectedSubscription(undefined);
                setIsRedeemSelected(undefined);
                setRedeemType(undefined);
                onSelectSubscriptionForDeviceChange(contactId, undefined, undefined);
              }
            }}
          />

          {isChangeDeviceSelected && (
            <div className={`${dsClass.PADDING_TOP_2} ${dsClass.PADDING_BOTTOM_3} of-device-checkout-delivery-mode`}>
              <h4>{t.NXGI(replaceableDevicesMsg)}</h4>
              {expiringSubs && (
                <CL.RadioGroup
                  value={deviceChangeRequest?.replacedSubscriptionId}
                  key={`${deviceChangeRequest?.replacedSubscriptionId}`}
                >
                  {expiringSubs.map((expSub, index) => (
                    <CL.RadioV2
                      className="expiringSubscriptions-option"
                      id={`expiringSubscriptions-options-${selectedPouIndex}-${index}`}
                      onChange={() => {
                        onSelectSubscriptionForDeviceChange(
                          contactId,
                          expSub.subscriptionId,
                          expSub.billingAccountId,
                          redeemType
                        );
                        setSelectedSubscription(expSub);
                      }}
                      key={`expiringSubscriptions-options-key-${selectedPouIndex}-${index}`}
                      value={expSub.subscriptionId}
                    >
                      <div>
                        <div>
                          {expSub.subscriptionName} {expSub.subscriptionDisplayId}
                        </div>
                        <div className={`${dsClass.TEXT_XS} ${dsClass.COLOR_ORANGE_600}`}>
                          {`${t.LQ6I(endTimeMsg).toLowerCase()}`}{' '}
                          {formatTimestampToUTCDDMMYYYY(expSub.details?.commitmentEndDate)}
                        </div>
                      </div>
                    </CL.RadioV2>
                  ))}
                </CL.RadioGroup>
              )}
              <div
                className={`${dsClass.PADDING_TOP_2} ${dsClass.PADDING_BOTTOM_3} of-device-checkout-cart-product__bordered of-device-checkout-cart-product__redeem-or-return`}
              >
                <h4>{t.PJIU(oldEppDeviceHeadingMsg)}</h4>
                <CL.SelectGroup
                  className={dsClass.PADDING_BOTTOM_2}
                  products={[
                    {
                      id: 'return',
                      name: t.A86X(returnDeviceMsg),
                      selected: !isRedeemSelected,
                    },
                    {
                      id: 'redeem',
                      name: t.LKBV(redeemDeviceMsg),
                      selected: isRedeemSelected,
                    },
                  ]}
                  value={isRedeemSelected ? 'redeem' : 'return'}
                  onChange={id => {
                    if (id === 'return') {
                      setIsRedeemSelected(false);
                      setRedeemType(undefined);
                    } else {
                      setIsRedeemSelected(true);
                      setRedeemType(redeemType ?? EppRedeemTerminateRequestType.COMPANY_REDEEM);
                    }
                  }}
                />
                {isRedeemSelected && selectedSubscription ? (
                  <>
                    <RedeemOptions
                      titleText={t.TS0L(checkoutRedeemOptionTitleMsg)}
                      companyLabel={t.VS0Z(forTheCompanyMsg)}
                      employeeLabel={t.ACGC(forTheEmployeeMsg)}
                      onChange={(selectedType: EppRedeemTerminateRequestType) => setRedeemType(selectedType)}
                      selectedOption={redeemType || EppRedeemTerminateRequestType.COMPANY_REDEEM}
                      isOmaLaiteLaskuActive={selectedSubscription.details?.device?.omaLaiteLasku}
                    />
                    {!selectedSubscription.details?.device?.omaLaiteLasku && (
                      <div
                        className={`${dsClass.TEXT_XS} of-device-checkout-cart-product__redeem-or-return-information`}
                      >
                        {t.GJ0P(employeeOmaLaiteLaskuMissingMsg)}
                      </div>
                    )}
                    <RedeemOptionDetails
                      subscription={selectedSubscription}
                      selectedOption={redeemType || EppRedeemTerminateRequestType.COMPANY_REDEEM}
                      companyName={companyName}
                      additionalFields={getAdditionalFields(selectedSubscription, redeemType)}
                      twoColumnView={true}
                    />
                    {redeemType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM_BY_ADMIN && (
                      <div>{t.U0N3(employeeInvoiceNotesMsg)}</div>
                    )}
                  </>
                ) : (
                  <div className="of-device-checkout-cart-product__redeem-or-return-disclaimer">
                    {t.ZSRZ(
                      'When choosing device replacement, we send a freight bag with the new device delivery.' +
                        ' The old device should be returned with the freight bag without delay.'
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
