import * as CL from '@design-system/component-library';
import { ApproveOrDeny } from '../ApproveOrDeny/ApproveOrDeny';
import { BillingAccount } from '../../common/react-hook-form/fields/BillingAccount';
import { Checkbox } from '../../common/react-hook-form/components/Checkbox';
import { CostCenter } from '../../common/react-hook-form/fields/CostCenter';
import { CustomerOrderCustomerInfo } from './CustomerOrderCustomerInfo.js';
import { CustomerOrderDeviceSubscriptions } from './CustomerOrderDeviceSubscriptions.js';
import { CustomerOrderInfo } from './CustomerOrderInfo.js';
import { CustomerOrderOrderedProductsList } from './CustomerOrderOrderedProductsList.js';
import { CustomerOrderStatus, SubscriptionStatusType } from '../../generated/api/models.js';
import { DialogType } from '../../common/enums';
import { EppDeviceChangeSummaries } from './EppDeviceChange/EppDeviceChangeSummaries.js';
import { EppPendingRedeemDisclaimers } from './EppDeviceChange/EppPendingRedeemDisclaimers.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Reference } from '../../common/react-hook-form/fields/Reference';
import { dsClass } from '../../common/constants/dsClasses';
import { getDefaultDeliveryOrder } from '../../common/utils/orderUtils';
import { notMandatoryMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { showDialog } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type {
  BillingAccountSearchResponse,
  ContactHeader,
  CustomerOrder,
  Subscription,
} from '../../generated/api/models.js';
import type { ReviewOnlineOrderDialogParams } from '../../common/types/dialog';

import './CustomerOrderDetails.scss';

interface CustomerOrderContentParams {
  buildDeliveryOrderHref: (cid: string, did: string) => string;
  customerOrder: CustomerOrder;
  ordererContact: ContactHeader | undefined;
  deviceSubscriptions: Subscription[] | undefined;
  isInternalCustomer: boolean;
  isEmployee: boolean;
  billingAccounts?: BillingAccountSearchResponse[];
  mdmId: string;
}

// userContactId will be same for all deliverOrder when an employee places an order.
const getEmployeeUserContactId = (customerOrder: CustomerOrder): string | undefined =>
  customerOrder?.deliveryOrders.find(deliveryOrder => deliveryOrder.userContactId)?.userContactId;

const activeEppDeviceSubscriptionsOfEmployee = (
  customerOrdersDetail: CustomerOrder,
  deviceSubscriptions?: Subscription[]
): Subscription[] | undefined => {
  const employeeUserContactId = getEmployeeUserContactId(customerOrdersDetail);
  return deviceSubscriptions?.filter(
    item =>
      item.details?.device?.eppSubscription &&
      item.subscriptionContactId === employeeUserContactId &&
      item.subscriptionStatus === SubscriptionStatusType.ACTIVE
  );
};

const findFirstReplacedSubscriptionDisplayId = (customerOrder: CustomerOrder) =>
  customerOrder?.deliveryOrders?.find(deliveryOrder => !!deliveryOrder.replacedSubscriptionDisplayId)
    ?.replacedSubscriptionDisplayId;

export interface CustomerOrderDetailsFormValues {
  costCenter: string;
  employeeNumber: string;
  billingAccountId: string;
  orderAndBillingDetailsChecked: boolean;
  approved?: boolean;
}

export interface CustomerOrderBillingDetailsFormProps {
  isInternalCustomer: boolean;
  billingAccounts: BillingAccountSearchResponse[];
  customerOrder: CustomerOrder;
  mdmId: string;
  ordererContact?: ContactHeader;
}

const getReviewOnlineOrderDialogParams = (
  formValues: CustomerOrderDetailsFormValues,
  customerOrder: CustomerOrder,
  ordererContact?: ContactHeader
): ReviewOnlineOrderDialogParams => {
  const onlineOrderReview = {
    billingAccountId: formValues.billingAccountId,
    costCenter: formValues.costCenter,
    subscriptionReference: formValues.employeeNumber,
    onlineOrderId: customerOrder?.onlineOrderId || '',
  };
  return {
    onlineOrderReview,
    approved: !!formValues.approved,
    customerOrder: customerOrder,
    type: DialogType.REVIEW_ONLINE_ORDER,
    ordererEmail: ordererContact?.email,
  };
};

const isDeviceChangeOrder = (customerOrdersDetail: CustomerOrder) =>
  customerOrdersDetail?.deliveryOrders.some(deliveryOrder => deliveryOrder.replacedSubscriptionId);

export const CustomerOrderDetailsForm = ({
  billingAccounts,
  customerOrder,
  isInternalCustomer,
  ordererContact,
  mdmId,
}: CustomerOrderBillingDetailsFormProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const defaultDeliveryOrder = getDefaultDeliveryOrder(customerOrder.deliveryOrders);
  const methods = useForm<CustomerOrderDetailsFormValues>({
    defaultValues: {
      billingAccountId: customerOrder.approverMustSelectBillingAccount ? undefined : customerOrder.billingAccountId,
      costCenter: defaultDeliveryOrder?.costCenter || '',
      employeeNumber: defaultDeliveryOrder?.subscriptionReference || '',
    },
  });
  const { handleSubmit, setValue, watch, setError } = methods;
  const dispatch = useDispatch();

  const selectedBillingAccountId = watch('billingAccountId');

  const onSubmit = async (formValues: CustomerOrderDetailsFormValues) => {
    dispatch(showDialog(getReviewOnlineOrderDialogParams(formValues, customerOrder, ordererContact)));
  };

  const handleApprove = (approved: boolean = false) => {
    setValue('approved', approved);
    if (approved && !selectedBillingAccountId) {
      setError('billingAccountId', { type: 'custom', message: t.GMD4('The billing account must be chosen') });
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onCreateNewBillingAccountSelected = () => {
    const options = {
      state: {
        ...location.state,
        redirectPath: `${location.pathname}?mdmId=${mdmId}`,
        mdmId,
      },
    };
    navigate(`${paths.BILLING_ACCOUNTS_CREATE_NEW_BA}?mdmId=${mdmId}`, options);
  };

  return (
    <FormProvider {...methods}>
      <form className="of-customer-order-details-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <h3 className={dsClass.MARGIN_VERTICAL_4}>{t.W1ZW('Billing information for the products')}</h3>
        <CostCenter
          label={!isInternalCustomer ? `${t.WH9D('Cost Center')} ${t.NFRH(notMandatoryMsg)}` : undefined}
          maxLength={40}
          required={isInternalCustomer}
        />
        <Reference
          label={!isInternalCustomer ? `${t.WGGQ('e.g. employee number')} ${t.NFRH(notMandatoryMsg)}` : undefined}
          required={false}
          maxLength={80}
          hint={undefined}
        />
        <BillingAccount
          billingAccounts={billingAccounts}
          name="billingAccountId"
          enableAddNewBa={true}
          required={false}
          onCreateNewBillingAccountSelected={onCreateNewBillingAccountSelected}
        />
        <Checkbox name="orderAndBillingDetailsChecked">
          {t.WO28('I have checked the validity of the billing and order details.')}
        </Checkbox>
        <ApproveOrDeny
          onApprove={() => handleApprove(true)}
          onDeny={() => handleApprove()}
          disabled={!watch('orderAndBillingDetailsChecked')}
        />
        {isDeviceChangeOrder(customerOrder) && (
          <div className={`${dsClass.FONT_SIZE_SMALL} ${dsClass.PADDING_LEFT_2}`}>
            <span>
              <CL.Icon icon="information" type="filled" size="m" color="orange" />
            </span>
            <span className={`${dsClass.COLOR_ORANGE} ${dsClass.PADDING_LEFT_1}`}>
              {t.GVYP('Please note that the user has')} <u>{t.WZ0H('valid device agreements.')}</u>
            </span>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export const CustomerOrderContent = ({
  buildDeliveryOrderHref,
  customerOrder,
  ordererContact,
  deviceSubscriptions,
  isInternalCustomer,
  billingAccounts,
  mdmId,
}: CustomerOrderContentParams) => {
  const activeDeviceSubscriptions = activeEppDeviceSubscriptionsOfEmployee(customerOrder, deviceSubscriptions);
  const orderPendingApproval = customerOrder.status === CustomerOrderStatus.PENDING_APPROVAL;
  const replacedSubscriptionDisplayId = findFirstReplacedSubscriptionDisplayId(customerOrder);

  return (
    <div className="of-customer-order-details__content">
      <CustomerOrderInfo customerOrder={customerOrder} isEmployee={false} />
      <EppDeviceChangeSummaries customerOrder={customerOrder} deviceSubscriptions={deviceSubscriptions} />
      <EppPendingRedeemDisclaimers deviceSubscriptions={deviceSubscriptions} />
      {orderPendingApproval && activeDeviceSubscriptions && (
        <CustomerOrderDeviceSubscriptions
          subscriptions={activeDeviceSubscriptions}
          isEmployee={false}
          replacedSubscriptionDisplayId={replacedSubscriptionDisplayId}
          mdmId={mdmId}
        />
      )}
      <CustomerOrderOrderedProductsList
        buildDeliveryOrderHref={buildDeliveryOrderHref}
        customerOrder={customerOrder}
        isEmployee={false}
        mdmId={mdmId}
      />
      {orderPendingApproval && (
        <>
          <CustomerOrderCustomerInfo ordererContact={ordererContact} />
          {billingAccounts && (
            <CustomerOrderDetailsForm
              billingAccounts={billingAccounts}
              customerOrder={customerOrder}
              ordererContact={ordererContact}
              isInternalCustomer={isInternalCustomer}
              mdmId={mdmId}
            />
          )}
        </>
      )}
    </div>
  );
};
