import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { ExternalAuthenticationProviders } from '../ExternalAuthenticationProviders/ExternalAuthenticationProviders.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import {
  customerServiceNumberLabelMsg,
  externalAuthenticationErrorMsg,
  externalAuthenticationRetryMsg,
  firstNameMsg,
  lastNameMsg,
  nextMsg,
  phoneNumberMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { Locale } from '../../common/i18n/index.js';
import type { PropsWithChildren } from 'react';

interface UserDetails {
  firstNames: string;
  lastName: string;
  phoneNumber: string;
}

export interface RegistrationStep2Props {
  locale: Locale;
  errors: CommonError[];
  businessId: string;
  initialUserDetails?: UserDetails;
  isEditable?: boolean;
  redirectPath: string;

  storeUserDetails: (userDetails: UserDetails) => void;
}

interface RegistrationFormProps {
  defaultValues?: UserDetails;
  onSubmit: (data: UserDetails) => void;
}
const InitialUserDetailsForm = ({ defaultValues, onSubmit }: RegistrationFormProps) => {
  const methods = useForm({ mode: 'all', defaultValues });
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate style={{ maxWidth: '432px' }}>
        <Name name="firstNames" label={t.AIK7(firstNameMsg)} placeholder={t.AIK7(firstNameMsg)} />
        <Name name="lastName" label={t.Y41S(lastNameMsg)} placeholder={t.Y41S(lastNameMsg)} />
        <PhoneNumber />
        <div className={dsClass.PADDING_TOP_2}>
          <CL.Button size="l" type="submit">
            {t.F0MY(nextMsg)}
          </CL.Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const RegistrationStep2 = ({
  storeUserDetails,
  initialUserDetails,
  businessId,
  isEditable,
  errors,
  locale,
  redirectPath,
}: PropsWithChildren<RegistrationStep2Props>) => {
  const [userDetails, setUserDetails] = useState(initialUserDetails);

  const onSubmit = (data: UserDetails) => {
    setUserDetails(data);
    storeUserDetails(data);
  };

  const authError = errors.find(
    ({ type }) => type === CommonErrorType.BAD_REQUEST || type === CommonErrorType.FORBIDDEN
  );

  const uriState = `businessId:${businessId}:${REDIRECT_PATH_KEY}:${encodeURIComponent(redirectPath)}`;

  return (
    <div className="of-registration-step of-registration-scene-step2">
      {isEditable && (
        <>
          {authError && (
            <div id="of-registration-step2-authorization-error" className="ea-m-b-3">
              <CL.Disclaimer
                disclaimerType="alert"
                icon={<CL.Icon icon="warning" size="m" />}
                title={
                  authError.type === CommonErrorType.FORBIDDEN
                    ? t.DDU5('Registration failed')
                    : t.UEHC(externalAuthenticationErrorMsg)
                }
                visible
              >
                {authError.type === CommonErrorType.FORBIDDEN
                  ? t.WTML(
                      'We were not able to verify your authority to sign on behalf of the company. Please check that the Business ID is registered in the Finnish Trade Register. You can also contact our customer service at {0}.',
                      customerServiceNumberLabelMsg
                    )
                  : t.Q9YM(externalAuthenticationRetryMsg)}
              </CL.Disclaimer>
            </div>
          )}
          {initialUserDetails ? (
            <InitialUserDetailsForm defaultValues={initialUserDetails} onSubmit={onSubmit} />
          ) : (
            <ExternalAuthenticationProviders
              locale={locale}
              uriState={uriState}
              headerText={t.YB45('Identify yourself by your personal bank credentials')}
            />
          )}
        </>
      )}
      {!isEditable && userDetails && (
        <>
          <h4>{`${userDetails.firstNames} ${userDetails.lastName}`}</h4>
          <div id="of-registration-step2-user-phone-number">{`${t.AGIJ(phoneNumberMsg)}: ${
            userDetails.phoneNumber
          }`}</div>
        </>
      )}
    </div>
  );
};
