import * as CL from '@design-system/component-library';
import { DeliveryMethodDropdown } from '../../../common/formik/Fields/Dropdown/DeliveryMethodDropdown.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { LanguageDropdown } from '../../../common/formik/Fields/Dropdown/LanguageDropdown.js';
import { invoiceLanguageMsg, t } from '../../../common/i18n/index.js';
import type { BillingAccountDeliveryMethod } from '../../../generated/api/models.js';
import type { CLDropdownItem } from '../../../common/types/componentLibrary';

export const DeliveryMethodAndLanguageSelection = ({
  deliveryMethodOptions,
  setDeliveryType,
}: {
  deliveryMethodOptions: CLDropdownItem[];
  setDeliveryType: (deliveryType: BillingAccountDeliveryMethod) => void;
}) => {
  return (
    <CL.GridRow>
      <GridColumn className="of-billing-account-details form-column edit-field">
        <DeliveryMethodDropdown deliveryMethodOptions={deliveryMethodOptions} setDeliveryType={setDeliveryType} />
      </GridColumn>
      <GridColumn className="of-billing-account-details form-column edit-field">
        <LanguageDropdown name="billLanguage" label={t.A7DR(invoiceLanguageMsg)} />
      </GridColumn>
    </CL.GridRow>
  );
};
