import * as CL from '@design-system/component-library';
import { Link, generatePath } from 'react-router-dom';
import { Loading } from '../Loading/index.js';
import { Status } from '../Status/Status.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { endTimeMsg, monthlyChargesMsg, numberMsg, productsMsg, statusMsg, t } from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getEppActionLocalization } from './EppDeviceChange/eppDeviceChangeUtil.js';
import { getPeriodicPriceAsTextFromDetails } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { CLTableColumn } from '../../common/types/componentLibrary';
import type { Subscription } from '../../generated/api/models.js';

interface CustomerOrderDeviceSubscriptionsParams {
  subscriptions: Subscription[] | undefined;
  isEmployee: boolean;
  replacedSubscriptionDisplayId?: string;
  mdmId: string;
}

enum DeviceSubscriptionFields {
  DEVICE_SUBSCRIPTION_NAME = 'subscriptionName',
  DEVICE_SUBSCRIPTION_DISPLAY_ID = 'subscriptionDisplayId',
  DEVICE_SUBSCRIPTION_RECURRING_CHARGE = 'monthlyRecurringCharge',
  DEVICE_SUBSCRIPTION_END_DATE = 'commitmentEndDate',
  DEVICE_SUBSCRIPTION_STATE = 'subscriptionStatus',
}

const columns: CLTableColumn[] = [
  {
    key: DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_NAME,
    label: t.IY4Z(productsMsg),
  },
  {
    key: DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_DISPLAY_ID,
    label: t.CCB1(numberMsg),
  },
  {
    key: DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_RECURRING_CHARGE,
    label: t.P6BC(monthlyChargesMsg),
  },
  {
    key: DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_END_DATE,
    label: t.LQ6I(endTimeMsg),
  },
  {
    key: DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_STATE,
    label: t.ASQT(statusMsg),
  },
];

const DeviceSubscriptionsTable = (props: CustomerOrderDeviceSubscriptionsParams) => {
  const { mdmId } = props;
  const tableRows = props.subscriptions?.map(subscription => ({
    [DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_NAME]: (
      <Link
        to={`${generatePath(paths.PS_DEVICE, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${mdmId}`}
      >
        <div>{subscription.subscriptionName || ''}</div>
        <div className={`${dsClass.TEXT_S} ${dsClass.COLOR_BLACK}`}>
          {getEppActionLocalization(subscription.details?.device?.replacementAction)}
        </div>
      </Link>
    ),
    [DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_DISPLAY_ID]: <>{subscription.subscriptionDisplayId}</>,
    [DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_RECURRING_CHARGE]: (
      <>{getPeriodicPriceAsTextFromDetails(subscription?.details) || '—'}</>
    ),
    [DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_END_DATE]: (
      <>
        {subscription?.details?.commitmentEndDate
          ? `${formatTimestampToUTCDDMMYYYY(subscription?.details?.commitmentEndDate)}`
          : '—'}
      </>
    ),
    [DeviceSubscriptionFields.DEVICE_SUBSCRIPTION_STATE]: (
      <Status
        textAfterBadge={getSubscriptionStatus(subscription).text}
        color={getSubscriptionStatus(subscription).color}
        inTable={true}
      />
    ),
    active: subscription.subscriptionDisplayId === props.replacedSubscriptionDisplayId,
  }));

  return (
    <div className={`of-table-wrapper ${dsClass.PADDING_BOTTOM_3}`}>
      <CL.Table
        columns={columns}
        hideHeadersOnMobile={false}
        rows={tableRows || []}
        tableType="bordered"
        hover={true}
      />
    </div>
  );
};

export const CustomerOrderDeviceSubscriptions = ({
  subscriptions,
  isEmployee,
  replacedSubscriptionDisplayId,
  mdmId,
}: CustomerOrderDeviceSubscriptionsParams) => {
  if (!subscriptions) {
    return <Loading />;
  }
  if (subscriptions.length) {
    return (
      <>
        <h3 className={dsClass.MARGIN_BOTTOM_2}>{t.NK9P(`User's valid device agreements`)}</h3>
        <DeviceSubscriptionsTable
          subscriptions={subscriptions}
          replacedSubscriptionDisplayId={replacedSubscriptionDisplayId}
          isEmployee={isEmployee}
          mdmId={mdmId}
        />
      </>
    );
  }
  return <></>;
};
