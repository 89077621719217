import { Anchor } from '../Anchor/Anchor.js';
import {
  Locale,
  activeMsg,
  closedMsg,
  contractsMsg,
  devicesMsg,
  formatNumber,
  getLocale,
  inDeliveryMsg,
  internetSubscriptionsMsg,
  monthMsg,
  monthsMsg,
  servicesMsg,
  t,
  temporarilyClosedMsg,
  unknownMsg,
  vatMsg,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { Status } from '../Status/Status.js';
import { StatusColor } from '../../common/enums.js';
import { VAT_AS_NUMBER, getPriceWithTax } from '../../common/utils/taxUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { isEppDeviceSubscriptionExpiring } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import type { CLDescriptionItem } from '../../common/types/componentLibrary';
import type {
  Subscription,
  SubscriptionAction,
  SubscriptionDetails,
  SubscriptionStatusType,
} from '../../generated/api/models.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

const STATUS_TEXTS = () => ({
  ACTIVATION_CANCELLED: t.UFGV(closedMsg),
  ACTIVE: t.SFRK(activeMsg),
  CLOSED_REPLACED: t.UFGV(closedMsg),
  CREATED: t.W59S(inDeliveryMsg),
  IN_ACTIVATION: t.W59S(inDeliveryMsg),
  SUSPENDED: t.PC3E(temporarilyClosedMsg),
  TERMINATED: t.UFGV(closedMsg),
});

const STATUS_COLORS = {
  ACTIVATION_CANCELLED: StatusColor.DARK_BLUE,
  ACTIVE: StatusColor.GREEN,
  CLOSED_REPLACED: StatusColor.DARK_BLUE,
  CREATED: StatusColor.YELLOW,
  IN_ACTIVATION: StatusColor.YELLOW,
  SUSPENDED: StatusColor.DARK_BLUE,
  TERMINATED: StatusColor.DARK_BLUE,
};

type DeviceType = 'accessories' | 'computers' | 'network equipment' | 'phone' | 'tablet';
const DEVICE_TYPE_TEXTS = () => ({
  accessories: t.O0EZ('Accessory'),
  computers: t.IHS4('Computer'),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'network equipment': t.EG02('Network device'),
  phone: t.OWF1('Mobile phone'),
  tablet: t.ZFAS('Tablet'),
});

export const CATEGORY_TEXTS = () => ({
  broadband: t.SEYV(internetSubscriptionsMsg),
  device: t.JJTM(devicesMsg),
  service: t.MBG9(servicesMsg),
  voice: t.AR0B(voiceSubscriptionsMsg),
  contract: t.SW0J(contractsMsg),
  subscriptions: '',
  domain: '',
  elisadns: '',
  licenses: '',
});

export const getSubscriptionStatusColor = (status?: SubscriptionStatusType) => {
  if (!status) {
    return StatusColor.GREY;
  }
  return STATUS_COLORS[status.toString() as keyof typeof STATUS_COLORS] as StatusColor;
};

export const getSubscriptionStatusText = (status?: SubscriptionStatusType) => {
  if (!status) {
    return t.QWC8(unknownMsg);
  }
  return STATUS_TEXTS()[status.toString() as SubscriptionStatusType];
};

export const getSubscriptionStatusFieldValue = (subscriptionStatus: SubscriptionStatus, action?: StatusFieldAction) => {
  return (
    <>
      {action ? (
        <>
          {subscriptionStatus.text} | <Anchor onClick={action.link}>{action.text}</Anchor>
        </>
      ) : (
        subscriptionStatus.text
      )}
    </>
  );
};

export const getSubscriptionStatus = (subscriptionStatus: SubscriptionStatus, action?: StatusFieldAction) => {
  return (
    <Status
      color={subscriptionStatus.color}
      textAfterBadge={getSubscriptionStatusFieldValue(subscriptionStatus, action)}
    />
  );
};

export const getSubscriptionStatusFieldCL = (
  subscriptionStatus: SubscriptionStatus,
  label: string,
  action?: StatusFieldAction
): CLDescriptionItem => {
  return {
    title: label,
    description: getSubscriptionStatus(subscriptionStatus, action),
  };
};

export interface StatusFieldAction {
  text: string;
  link: () => void;
}

// TODO: When CompositeList is not used - this can replace the below function
export const getRecurringPriceAsText = (monthlyRecurringCharge?: number, billingPeriod = 0) => {
  const monthText = billingPeriod ? `/${billingPeriod} ${t.XXVX(monthsMsg)}` : `/${t.XXVX(monthMsg)}`;
  return monthlyRecurringCharge ? `${formatSum(monthlyRecurringCharge)}${monthText}` : '—';
};

export const getPeriodicPriceAsText = (monthlyRecurringCharge?: number) => {
  return getRecurringPriceAsText(monthlyRecurringCharge);
};

export const getPriceAsText = (
  charge?: number,
  listCharge?: number,
  periodic?: boolean,
  undefinedValue?: string,
  billingPeriod = 0
): string | undefined => {
  if (charge === undefined) {
    return undefinedValue || formatSum(charge);
  }
  const billingSuffix = billingPeriod ? `/${billingPeriod} ${t.XXVX(monthsMsg)}` : `/${t.XXVX(monthMsg)}`;
  const periodSuffix = periodic ? billingSuffix : '';
  let priceText = formatSum(charge) + periodSuffix;
  if (listCharge !== undefined && charge !== listCharge) {
    priceText += ` (${t.LRAQ('reg.')} ${formatSum(listCharge)}${periodSuffix})`;
  }

  return priceText;
};

export const getPeriodicPriceAsTextFromDetails = (subscriptionDetails?: SubscriptionDetails): string | undefined => {
  if (!subscriptionDetails) {
    return '—';
  }
  const { monthlyRecurringCharge, monthlyRecurringListCharge } = subscriptionDetails;
  if (monthlyRecurringCharge === 0) {
    return '—';
  }
  return getPriceAsText(monthlyRecurringCharge, monthlyRecurringListCharge, true, '—');
};

export const getEmployeePeriodicPriceAsText = (price: number | undefined) => {
  const percentageFormat = getLocale() === Locale.EN ? '%' : ' %';
  return price
    ? `${getPeriodicPriceAsText(getPriceWithTax(price))} (${t.U8AN('incl.')} ${t.UZ6X(vatMsg)} ${formatNumber(
        VAT_AS_NUMBER
      )}${percentageFormat})`
    : '-';
};

export const getDeviceTypeText = (subscriptionSubType: string) => {
  return DEVICE_TYPE_TEXTS()[subscriptionSubType.toLowerCase() as DeviceType] || '';
};

export const getExpiringEppDeviceSubscriptions = (
  subscriptions: Subscription[],
  subscriptionActions?: SubscriptionAction[]
): Subscription[] => {
  return subscriptions.filter(
    sub =>
      isEppDeviceSubscriptionExpiring(sub) &&
      subscriptionActions?.findIndex(subscriptionAction => subscriptionAction.subscriptionId === sub.subscriptionId) ===
        -1
  );
};
