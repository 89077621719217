import { AuthenticationLevel } from '../../../../generated/api/models.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  Table,
} from '../../../Table/index.js';
import { DnsDomainDisclaimerEnableMfa } from './DnsDomainDisclaimerEnableMfa.js';
import { Grid } from '../../../Grid/Grid.js';
import { Link, generatePath } from 'react-router-dom';
import { billingAccountMsg, companyMsg, t } from '../../../../common/i18n/index.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { getArrayPropertySorter } from '../../../../common/utils/arrayUtils.js';
import { getCompanyName } from '../../../../common/utils/accountUtils.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../../../Table/tableUtils.js';
import { tableSearchRow } from '../../../Table/TableDataUtil.js';
import { useSearchParams } from '../../../../common/hooks/useSearchParams.js';
import type { AuthenticatedUserState } from '../../../../common/types/states.js';
import type { CLTableColumn, CLTableRow } from '../../../../common/types/componentLibrary';
import type { DnsRecordsSearchParams } from '../../dnsManagementUtils.js';
import type { SubscriptionSearchResponse } from '../../../../generated/api/models.js';

import './DnsDomainList.scss';

export const DnsDomainList = ({
  searchResults = [],
  total = 0,
  authenticatedUser,
}: {
  searchResults?: SubscriptionSearchResponse[];
  total?: number;
  authenticatedUser?: AuthenticatedUserState;
}) => {
  const { sort, search, order } = useSearchParams<DnsRecordsSearchParams>();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const mfaEnabled = authenticatedUser?.authenticationLevel === AuthenticationLevel.TWO_FACTOR;

  const columns = [
    { key: 'subscriptionName', label: t.ES5D('Domain'), sortable: true },
    { key: 'subscriptionId', label: t.YTH3('ID Number'), sortable: true },
    { key: 'oldContractId', label: t.Z223('Billing code'), sortable: true },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'company', label: t.KJTS(companyMsg) },
  ] satisfies CLTableColumn[];

  const rows = searchResults
    .map(({ result }) => ({
      subscriptionName: result.subscriptionName,
      subscriptionId: result.subscriptionId,
      oldContractId: result.oldContractId,
      billingAccountDisplayId: result.billingAccountDisplayId,
      accountMasterId: result.accountMasterId,
    }))
    .filter(row => tableSearchRow(search, row))
    .sort(getArrayPropertySorter(sort, order))
    .map(row => ({
      subscriptionName: !mfaEnabled ? (
        row.subscriptionName
      ) : (
        <Link to={generatePath(paths.PS_ELISA_DNS_DOMAIN, { subscriptionId: row.subscriptionId })}>
          {row.subscriptionName}
        </Link>
      ),
      subscriptionId: row.subscriptionId,
      oldContractId: row.oldContractId,
      billingAccountDisplayId: row.billingAccountDisplayId,
      company: getCompanyName(authenticatedUser, row.accountMasterId),
    })) satisfies CLTableRow[];

  return (
    <Grid>
      <div className="of-dns-domain-list">
        {!mfaEnabled && <DnsDomainDisclaimerEnableMfa />}
        <ListSearch
          className={dsClass.MARGIN_TOP_4}
          onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
        />
        <Table columns={columns} noItemsText={t.VA1O('No records')} rows={rows} />
        <ListPagination totalItems={total} />
      </div>
    </Grid>
  );
};
