import * as CL from '@design-system/component-library';
import { Loading } from '../Loading/index.js';
import { MONTHS } from '../../common/i18n/calendar.js';
import { SubscriptionUsageTable } from './SubscriptionUsageTable.js';
import { addMonths } from 'date-fns';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fetchSubscriptionMonthlyUsageDetails } from '../../common/fetch.js';
import { getLocale, t } from '../../common/i18n/index.js';
import { useCallback, useEffect, useState } from 'react';
import type { MonthlyUsageDetailsResponse } from '../../generated/api/monthlyUsageDetailsResponse.js';
import type { UsageItemDetail } from '../../generated/api/usageItemDetail.js';

import './SubscriptionUsageTabs.scss';

const defaultNumberOfMonths = 6;

interface SubscriptionUsageSpecificationProps {
  subscriptionId: string;
  isRoaming?: boolean;
  numberOfMonths?: number;
}

const createMonthlyUsageKey = (month: number, year: number) => `${MONTHS['en-GB'][month]} ${year}`;
const compareDateTimeDesc = (l: UsageItemDetail, r: UsageItemDetail) => r.dateAndTime - l.dateAndTime;

export const SubscriptionUsageTabs = ({
  subscriptionId,
  isRoaming = false,
  numberOfMonths = defaultNumberOfMonths,
}: SubscriptionUsageSpecificationProps) => {
  const now = new Date();
  const firstMonth = MONTHS['en-GB'][now.getMonth()];
  const firstMonthKey = createMonthlyUsageKey(now.getMonth(), now.getFullYear());
  const [monthlyDetails, setMonthlyDetails] = useState<{ [key: string]: MonthlyUsageDetailsResponse }>({});

  const fetchAndCacheMonth = useCallback(
    (monthName: string, cacheKey: string) => {
      fetchSubscriptionMonthlyUsageDetails(monthName, subscriptionId).then(details => {
        details.voiceCalls.sort(compareDateTimeDesc);
        details.textMessages.sort(compareDateTimeDesc);
        details.data.sort(compareDateTimeDesc);

        setMonthlyDetails({
          ...monthlyDetails,
          [cacheKey]: details,
        });
      });
    },
    [monthlyDetails, subscriptionId]
  );

  const onTabSelected = (selectedMonthOrdinal: number, year: number) => {
    const selectedMonthKey = createMonthlyUsageKey(selectedMonthOrdinal, year);
    const selectedMonthName = MONTHS['en-GB'][selectedMonthOrdinal];
    if (!monthlyDetails[selectedMonthKey]) {
      fetchAndCacheMonth(selectedMonthName, selectedMonthKey);
    }
  };

  const getTabContent = (tabKey: string) => {
    if (!monthlyDetails[tabKey]) {
      return <Loading />;
    }

    return <SubscriptionUsageTable details={monthlyDetails[tabKey]} isRoaming={isRoaming} />;
  };

  useEffect(() => {
    if (!monthlyDetails[firstMonthKey]) {
      fetchAndCacheMonth(firstMonth, firstMonthKey);
    }
  }, [monthlyDetails, firstMonthKey, firstMonth, fetchAndCacheMonth]);

  const tabs = Array.from({ length: numberOfMonths }).map((_, i) => {
    const tabMonth = addMonths(now, -i);
    const monthOrdinal = tabMonth.getMonth();
    const year = tabMonth.getFullYear();
    const tabKey = createMonthlyUsageKey(monthOrdinal, year);
    const tabTitle = `${MONTHS[getLocale()][monthOrdinal]} ${year}`;
    return (
      <CL.TabNavigationTab
        key={tabKey}
        title={tabTitle}
        selected={now.getMonth() === monthOrdinal}
        onClick={() => onTabSelected(monthOrdinal, year)}
      >
        {getTabContent(tabKey)}
      </CL.TabNavigationTab>
    );
  });

  return (
    <div>
      <h4>{t.N31T('Monthly call details')}</h4>
      <CL.TabNavigation className={`of-subscription-usage-details-specification ${dsClass.MARGIN_BOTTOM_0}`}>
        {tabs}
      </CL.TabNavigation>
    </div>
  );
};
