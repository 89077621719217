import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import { CustomerOrderListFields } from '../../common/enums.js';
import { CustomerOrderStatus } from '../../generated/api/models.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  SearchFilters,
  Table,
  TableDescription,
  TableUrlParams,
} from '../Table/index.js';
import { Highlight } from '../Highlight/Highlight.js';
import { Link, generatePath } from 'react-router-dom';
import { Status } from '../Status/Status.js';
import {
  approverContactMsg,
  companyMsg,
  contentMsg,
  customerMsg,
  imeiSlashSerialNumberMsg,
  noOrdersMsg,
  orderDateMsg,
  orderMsg,
  statusMsg,
  t,
  userTextMsg,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimeStampToHHmm, formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getCustomerOrderStatusInfo } from '../CustomerOrderDetails/CustomerOrderDetailsUtil.js';
import { isValidSearchQuery, truncateText } from '../../common/utils/stringUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { CLTableColumn } from '../../common/types/componentLibrary';
import type { CustomerOrderAggregationsResponse, CustomerOrderSearchResponse } from '../../generated/api/models.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';

import './CustomerOrderListWithTable.scss';

const truncateTextMaxChars = 100;

const customerOrderAdditionalContentFields = [
  { name: 'selectedApproverName', getTitle: () => t.GOZ7(approverContactMsg) },
  { name: 'customerOrderContactName', getTitle: () => t.ZC7D(customerMsg) },
  { name: 'deliveryOrderSerialNumbers', getTitle: () => t.OE45(imeiSlashSerialNumberMsg) },
];

const customerOrderAdditionalContentFieldNames = customerOrderAdditionalContentFields.map(field => field.name);

const orderedCustomerOrderStatuses = [
  CustomerOrderStatus.PENDING_APPROVAL,
  CustomerOrderStatus.IN_PROGRESS,
  CustomerOrderStatus.PARTIALLY_COMPLETED,
  CustomerOrderStatus.COMPLETED,
  CustomerOrderStatus.PENDING,
  CustomerOrderStatus.FAILED,
  CustomerOrderStatus.PARTIALLY_FAILED,
  CustomerOrderStatus.CANCELLED,
];

const isInProgressStatus = (orderedStatus: CustomerOrderStatus) => orderedStatus === CustomerOrderStatus.IN_PROGRESS;

const columns: CLTableColumn[] = [
  {
    key: CustomerOrderListFields.CUSTOMER_ORDER_DISPLAY_ID,
    label: t.C001(orderMsg),
    sortable: true,
    width: '15%',
  },
  {
    key: CustomerOrderListFields.CREATED,
    label: t.ZTPC(orderDateMsg),
    sortable: true,
  },
  {
    key: CustomerOrderListFields.DELIVERY_ORDER_CONTACT_NAMES,
    label: t.U4MA(userTextMsg),
    sortable: false,
    width: '20%',
  },
  {
    key: CustomerOrderListFields.DELIVERY_ORDERS,
    label: t.NBNH(contentMsg),
    sortable: false,
    width: '35%',
  },
  {
    key: CustomerOrderListFields.STATUS,
    label: t.ASQT(statusMsg),
    sortable: true,
    width: '10%',
  },
  {
    key: CustomerOrderListFields.COMPANY,
    label: t.KJTS(companyMsg),
    sortable: false,
    width: '20%',
  },
];

const getHighlightedAdditionalContentFields = (value: CustomerOrderSearchResponse, searchTerms: string[]) =>
  value.matchedFields
    ?.filter(matchedField => customerOrderAdditionalContentFieldNames.includes(matchedField))
    .map(matchedField => (
      <span key={matchedField}>
        {customerOrderAdditionalContentFields.find(field => field.name === matchedField)?.getTitle()}:&nbsp;
        <Highlight
          text={
            // @ts-ignore
            truncateText(value.result[matchedField], truncateTextMaxChars)
          }
          highlights={searchTerms}
        />
        &nbsp;
      </span>
    ));

const getAggregationStatusItemsCount = (
  orderedStatus: CustomerOrderStatus,
  aggregations: CustomerOrderAggregationsResponse | undefined
) => aggregations?.status.find(aggregationStatus => aggregationStatus.name === orderedStatus)?.count ?? 0;

export interface CustomerOrderListWithTableProps {
  customerOrders?: CustomerOrderSearchResponse[];
  aggregations?: CustomerOrderAggregationsResponse;
  totalItems: number;
  loading?: boolean;
  searchQuery?: string;
  status?: string;
  authenticatedUser?: AuthenticatedUserState;
}

export const CustomerOrderListWithTable = ({
  customerOrders,
  aggregations,
  loading,
  totalItems,
  searchQuery,
  status,
  authenticatedUser,
}: CustomerOrderListWithTableProps): JSX.Element => {
  const searchTerms = searchQuery?.split(' ') || [];
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const [filtersOpen, setFiltersOpen] = useState(false);

  const rows = customerOrders?.map(value => ({
    [CustomerOrderListFields.CUSTOMER_ORDER_DISPLAY_ID]: (
      <Link
        to={`${generatePath(paths.CUSTOMER_ORDER, { orderId: value?.result?.customerOrderDisplayId })}?mdmId=${
          value.result.accountMasterId
        }`}
      >
        <Highlight text={value.result.customerOrderDisplayId ?? ''} highlights={searchTerms} />
        {isValidSearchQuery(searchQuery) &&
          value.matchedFields?.some(matchedField =>
            customerOrderAdditionalContentFieldNames.includes(matchedField)
          ) && (
            <div className="additional-field-content">
              <strong>{t.NBNH(contentMsg)}: </strong>
              {getHighlightedAdditionalContentFields(value, searchTerms)}
            </div>
          )}
      </Link>
    ),
    [CustomerOrderListFields.CREATED]: (
      <TableDescription
        description={value.result.created ? formatTimeStampToHHmm(value.result.created) : ''}
        title={formatTimestampToDDMMYYYY(value.result.created)}
      />
    ),
    [CustomerOrderListFields.DELIVERY_ORDER_CONTACT_NAMES]: (
      <Highlight
        text={truncateText(
          (value.result.deliveryOrderContactNames?.length ?? 0) > 0
            ? value.result.deliveryOrderContactNames
            : value.result.deliveryOrderPurposeOfUses,
          truncateTextMaxChars
        )}
        highlights={searchTerms}
      />
    ),
    [CustomerOrderListFields.DELIVERY_ORDERS]: (
      <Highlight text={truncateText(value.result.deliveryOrders, truncateTextMaxChars)} highlights={searchTerms} />
    ),
    [CustomerOrderListFields.STATUS]: (
      <Status
        textAfterBadge={getCustomerOrderStatusInfo(value.result.status).text}
        color={getCustomerOrderStatusInfo(value.result.status).color}
        inTable={true}
      />
    ),
    [CustomerOrderListFields.COMPANY]: getCompanyName(authenticatedUser, value.result.accountMasterId),
  }));

  const getSearchFilterGroups = (): SearchFilterGroup[] => {
    const items = orderedCustomerOrderStatuses
      .filter(orderedStatus => aggregations?.status.some(aggregationStatus => aggregationStatus.name === orderedStatus))
      .map(orderStatus => {
        const itemsCount =
          // IN_PROGRESS status is combined with FAILED and PARTIALLY_FAILED statuses under the same filter.
          isInProgressStatus(orderStatus)
            ? getAggregationStatusItemsCount(orderStatus, aggregations) +
              getAggregationStatusItemsCount(CustomerOrderStatus.FAILED, aggregations) +
              getAggregationStatusItemsCount(CustomerOrderStatus.PARTIALLY_FAILED, aggregations)
            : getAggregationStatusItemsCount(orderStatus, aggregations);
        return {
          checked: status?.split('|').includes(orderStatus),
          itemsCount: itemsCount,
          label: () => getCustomerOrderStatusInfo(orderStatus).text,
          value: isInProgressStatus(orderStatus)
            ? `${CustomerOrderStatus.IN_PROGRESS}|${CustomerOrderStatus.FAILED}|${CustomerOrderStatus.PARTIALLY_FAILED}`
            : orderStatus,
        };
      })
      .filter(item => item.value !== CustomerOrderStatus.FAILED && item.value !== CustomerOrderStatus.PARTIALLY_FAILED);

    return [
      {
        label: () => t.UNK4(statusMsg),
        value: TableUrlParams.STATUS,
        items: items ?? [],
      },
    ];
  };

  return (
    <div className={`of-customer-order-list ${dsClass.MARGIN_TOP_4}`}>
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={
          <Table columns={columns} isLoading={loading} noItemsText={t.QFXU(noOrdersMsg)} rows={rows || []} />
        }
        listPagination={<ListPagination totalItems={totalItems} />}
        listSearch={
          <ListSearch
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        searchFilters={
          <SearchFilters
            displayActiveFiltersAsTiles={!filtersOpen}
            filterGroups={getSearchFilterGroups()}
            onModalClose={() => setFiltersOpen(false)}
          />
        }
      />
    </div>
  );
};
