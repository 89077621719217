import * as CL from '@design-system/component-library';
import { Table } from '../../Table';
import { createInterworksRedirectUrl } from '../../../common/fetch';
import { pushLicenseManagementGAEventToDataLayer } from '../../../common/analytics';
import { t } from '../../../common/i18n';
import React, { useState } from 'react';
import type { CLTableColumn, CLTableRow } from '../../../common/types/componentLibrary';
import type { LicenseManagementAccount } from '../../../generated/api/licenseManagementAccount';
import type { LicenseManagementUrlResponse } from '../../../generated/api/licenseManagementUrlResponse';

import './LicenseManagementAccountList.scss';

interface LicenseManagementAccountListProps {
  accountMasterId: string;
  licenseManagementAccounts: Array<LicenseManagementAccount>;
  onErrorNavigatingToLicenseManagementAccount: () => void;
}
export const LicenseManagementAccountList = ({
  accountMasterId,
  licenseManagementAccounts,
  onErrorNavigatingToLicenseManagementAccount,
}: LicenseManagementAccountListProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLmAccountId, setSelectedLmAccountId] = useState<number | undefined>();

  const onNavigateToLicenseManagementPortal = async (accountId: number) => {
    setIsLoading(true);
    setSelectedLmAccountId(accountId);
    try {
      const { url }: LicenseManagementUrlResponse = await createInterworksRedirectUrl(accountId, accountMasterId);
      // Safari is blocking any call to window.open() which is made inside an async call.
      // As we want to open url in a new browser tab, window.location() cannot be used.
      // This is workaround that fixes the Safari issue and also works on other browsers.
      setTimeout(() => {
        window.open(url, '_blank', 'noopener,noreferrer');
      });
    } catch (err) {
      onErrorNavigatingToLicenseManagementAccount();
    } finally {
      setIsLoading(false);
      setSelectedLmAccountId(undefined);
    }
  };

  const columns = [
    { key: 'accountName', label: t.KP7E('Customer account'), width: '25%' },
    { key: 'microsoftTenant', label: 'Microsoft tenant', width: '25%' },
    { key: 'billingAccount', label: t.PS5T('Billing account'), width: '25%' },
    { key: 'actionLinks', label: '', width: '25%' },
  ] satisfies CLTableColumn[];

  const getActionLinks = (lmAccount: LicenseManagementAccount, selectedLmAccount?: number) => {
    if (isLoading && lmAccount.accountId === selectedLmAccount) {
      return <CL.LoadingSpinner size="xs" />;
    }
    return (
      <CL.Link
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          onNavigateToLicenseManagementPortal(lmAccount.accountId);
          pushLicenseManagementGAEventToDataLayer('navigated_to_iw_portal');
          return false;
        }}
      >
        {t.ERXV('Go to Licence management')} <CL.Icon icon="external-link" size="m" type="regular" color="blue-600" />
      </CL.Link>
    );
  };

  const rows = licenseManagementAccounts.map(licenseManagementAccount => ({
    ...licenseManagementAccount,
    actionLinks: getActionLinks(licenseManagementAccount, selectedLmAccountId),
  })) satisfies CLTableRow[];

  return (
    <div className="of-license-management-accounts ds-margin-vertical--3">
      <Table columns={columns} rows={rows} />
    </div>
  );
};
