import * as CL from '@design-system/component-library';
import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table, TableDescription } from '../Table/index.js';
import { Fragment } from 'react';
import { Grid } from '../Grid/Grid.js';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import {
  billingAccountNameMsg,
  companyMsg,
  numberMsg,
  payerMsg,
  referenceMsg,
  subscriptionCountMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils';
import { getCompanyName, getPrimaryMdmId } from '../../common/utils/accountUtils.js';
import {
  getDefaultBillingContactId,
  getDefaultDeliveryMethod,
  getDeliveryMethod,
} from '../../common/utils/billingAccountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { BillingAccountHeader, BillingAccountsResponse } from '../../generated/api/models.js';
import type { CLTableColumn } from '../../common/types/componentLibrary';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { ReactNode } from 'react';

import './BillingAccountList.scss';

export interface BillingAccountListProps {
  billingAccounts?: BillingAccountsResponse;
}

interface BillingAccountLinkProps {
  billingAccount: BillingAccountHeader;
  children: ReactNode;
}

const HighlightSearchResult = <T extends object>(
  esResults: string[],
  object: T,
  columnsToAdd: { columnId: string; translation: string }[],
  searchWord: string
) => {
  return columnsToAdd.map(column => {
    // @ts-ignore
    const col = object[column.columnId];
    if (esResults.includes(column.columnId) && !!col) {
      return (
        <span key={column.columnId}>
          <span>{column.translation}: </span>
          {highlightKeyword(col, searchWord)}
          <br />
        </span>
      );
    }
    return <Fragment key={column.columnId}></Fragment>;
  });
};

const BillingAccountLink = ({ billingAccount, children }: BillingAccountLinkProps) => {
  const path = `${generatePath(paths.BILLING_ACCOUNT, {
    billingAccountId: billingAccount.billingAccountDisplayId ?? '',
  })}?mdmId=${billingAccount.accountMasterId}`;

  return <Link to={path}>{children}</Link>;
};

const ScheduledChangeTimestamp = ({ scheduledChangeTimestamp }: { scheduledChangeTimestamp?: number }) => {
  if (!scheduledChangeTimestamp) {
    return null;
  }

  return (
    <div className="of-billing-account-list__scheduled-timestamp">
      <CL.Tooltip
        triggerElement={<CL.Icon icon="calendar-time" />}
        placement="top"
        i18n_tooltip_contentText={`${t.N4LE('Scheduled change')} ${formatTimestampToDDMMYYYY(scheduledChangeTimestamp)}`}
      />
      <div>{formatTimestampToDDMMYYYY(scheduledChangeTimestamp)}</div>
    </div>
  );
};

export const BillingAccountList = ({ billingAccounts }: BillingAccountListProps) => {
  const { authenticatedUser } = useAuth();
  const navigate = useNavigate();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;

  const billingAccountHeaders = billingAccounts?.searchResults?.map(sr => sr.result);
  const defaultDeliveryMethod = getDefaultDeliveryMethod(billingAccountHeaders);
  const defaultBillingContactId = getDefaultBillingContactId(billingAccountHeaders);

  const columns: CLTableColumn[] = [
    { key: 'billingAccountDisplayId', label: t.CCB1(numberMsg), sortable: true, width: '15%' },
    { key: 'payerName', label: t.PB6S(payerMsg), sortable: true, width: '20%' },
    { key: 'billingAccountName', label: t.RH6T(billingAccountNameMsg), sortable: true, width: '20%' },
    { key: 'billingContactName', label: t.VYZS('Contact person'), sortable: true, width: '20%' },
    { key: 'subscriptionCount', label: t.J9DM(subscriptionCountMsg), sortable: true, width: '5%' },
    { key: 'companyName', label: t.KJTS(companyMsg), width: '10%' },
    { key: 'scheduledChangeTimestamp', label: '', width: '10%' },
  ];

  const rows = billingAccounts?.searchResults?.map(row => {
    const ba = row.result;
    const billingAccountDisplayId = (
      <BillingAccountLink billingAccount={ba}>
        <TableDescription
          description={
            ba.deliveryMethod ? (
              <>
                {getDeliveryMethod(ba.deliveryMethod)}
                <br />
                {row.matchedFields &&
                  search &&
                  HighlightSearchResult(
                    row.matchedFields,
                    ba,
                    [
                      { columnId: 'customerReference1', translation: t.DQHY(referenceMsg) + ' 1' },
                      { columnId: 'customerReference2', translation: t.DQHY(referenceMsg) + ' 2' },
                      { columnId: 'customerReference3', translation: t.DQHY(referenceMsg) + ' 3' },
                      { columnId: 'customerReference4', translation: t.DQHY(referenceMsg) + ' 4' },
                    ],
                    search
                  )}
              </>
            ) : undefined
          }
          title={<div>{highlightKeyword(ba.billingAccountDisplayId ?? '-', search)}</div>}
        />
      </BillingAccountLink>
    );

    return {
      billingAccountDisplayId,
      billingAccountName: (
        <TableDescription
          description={ba.billingAccountExtensionName && highlightKeyword(ba.billingAccountExtensionName, search)}
          title={ba.billingAccountName && highlightKeyword(ba.billingAccountName, search)}
        />
      ),
      payerName: (
        <TableDescription
          description={ba.payerNameExtension && highlightKeyword(ba.payerNameExtension, search)}
          title={ba.payerName ? highlightKeyword(ba.payerName, search) : ba.payerName}
        />
      ),
      billingContactName: (
        <TableDescription
          title={ba.billingContactName && highlightKeyword(ba.billingContactName, search)}
          description={
            ba.billingContactPhone
              ? `${ba.billingContactPhone}, ${ba.billingContactEmail}`
              : `${ba.billingContactEmail ?? '-'}`
          }
        />
      ),
      subscriptionCount: <>{ba.subscriptionCount || 0}</>,
      companyName: <>{getCompanyName(authenticatedUser, row.result.accountMasterId)}</>,
      scheduledChangeTimestamp: <ScheduledChangeTimestamp scheduledChangeTimestamp={ba.scheduledChangeTimestamp} />,
    };
  });

  return (
    <Grid colWidthL={12}>
      <div
        className={`of-create-new-billing-account ${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_FLEX_END} ${dsClass.MARGIN_TOP_4}`}
      >
        <CL.Button
          onClick={() =>
            navigate(`${paths.BILLING_ACCOUNTS_CREATE_NEW_BA}?mdmId=${getPrimaryMdmId(authenticatedUser)}`, {
              state: {
                defaultDeliveryMethod,
                defaultBillingContactId,
              },
            })
          }
        >
          {t.TFDQ('Create new')}
        </CL.Button>
      </div>
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={<Table columns={columns} noItemsText={t.NVGB('No billing accounts')} rows={rows || []} />}
        listPagination={<ListPagination totalItems={billingAccounts?.total ?? 0} />}
        listSearch={
          <ListSearch
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        wideLayout={true}
      />
    </Grid>
  );
};
