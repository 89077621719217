import { Dropdown } from '../../Dropdown/Dropdown';
import { OpenFormGridCol } from './OpenFormGrid';
import { setValueOptions } from '../OpenFormHooks/useOpenFormMethods';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type * as CL from '@design-system/component-library';
import type { CLDropdownItem } from '../../../common/types/componentLibrary.js';

export const OpenFormDropdown = ({
  name,
  items,
  onChange,
  ...props
}: Omit<CL.DropdownProps, 'ref' | 'selectedValue'> & {
  name: string;
  onChange?: (item?: CLDropdownItem) => void;
}) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    if (!getValues(name)) {
      const item = items?.[0];
      setValue(name, item?.value, setValueOptions);
      onChange?.(item);
    }
  }, [name, onChange, items, getValues, setValue]);

  return (
    <OpenFormGridCol colWidth={6}>
      <Dropdown
        {...props}
        items={items}
        selectedValue={getValues(name)}
        onValueChange={el => {
          const value = el.dataset.value;
          setValue(name, value, setValueOptions);
          onChange?.(items?.find(item => item.value === value));
        }}
      />
    </OpenFormGridCol>
  );
};
