import * as CL from '@design-system/component-library';
import { Dropdown } from '../../../common/react-hook-form/components/Dropdown.js';
import { GridColumn } from './GridColumn.js';
import { getLanguageOptions } from '../../../common/utils/billingAccountUtils.js';
import { invoiceDeliveryMethodMsg, invoiceLanguageMsg, t } from '../../../common/i18n/index.js';
import type { CLDropdownItem } from '../../../common/types/componentLibrary';

export const DeliveryMethodAndLanguageSelection = ({
  deliveryMethodOptions,
  disabled = false,
}: {
  deliveryMethodOptions: CLDropdownItem[];
  disabled?: boolean;
}) => {
  return (
    <CL.GridRow>
      <GridColumn className="of-billing-account-details form-column edit-field">
        <Dropdown
          name="billingAccount.deliveryMethod"
          id="deliveryMethod"
          items={deliveryMethodOptions}
          label={t.H272(invoiceDeliveryMethodMsg)}
          disabled={disabled}
        />
      </GridColumn>
      <GridColumn className="of-billing-account-details form-column edit-field">
        <Dropdown
          name="billingAccount.billLanguage"
          items={getLanguageOptions()}
          label={t.A7DR(invoiceLanguageMsg)}
          disabled={disabled}
        />
      </GridColumn>
    </CL.GridRow>
  );
};
