import * as CL from '@design-system/component-library';
import { SelectedPurposeOfUseOrContact } from '../../../common/enums.js';
import { useFormContext } from 'react-hook-form';
import type { CLSelectGroupProduct } from '../../../common/types/componentLibrary';

export interface PurposeSelectGroupProps {
  children: React.ReactElement[];
  selectedItem?: string;
  className?: string;
  fieldPrefix?: string;
}

export const PurposeOfUseSelectGroup = ({
  children,
  selectedItem,
  className,
  fieldPrefix = '',
}: PurposeSelectGroupProps) => {
  const { setValue } = useFormContext();
  const fieldName = `${fieldPrefix}selected`;

  const products: CLSelectGroupProduct[] = children
    .filter(child => !child.props.hidden)
    .map(child => ({
      id: child.props.id,
      selected: child.props.selected,
      name: child.props.title,
      icon: child.props.icon,
    }));

  const selectedChildComponent = children.find(child => child.props.id === selectedItem) || children[0];

  const setFieldValues = (value: string) => {
    setValue(fieldName, value, { shouldTouch: true });
    if (value === SelectedPurposeOfUseOrContact.CONTACT) {
      setValue(`${fieldPrefix}purposeOfUse`, undefined);
      setValue(`${fieldPrefix}costCenter`, '');
      setValue(`${fieldPrefix}employeeNumber`, '');
    } else if (value === SelectedPurposeOfUseOrContact.PURPOSEOFUSE) {
      setValue(`${fieldPrefix}contactId`, undefined);
      setValue(`${fieldPrefix}firstName`, '');
      setValue(`${fieldPrefix}lastName`, '');
      setValue(`${fieldPrefix}phoneNumber`, '');
      setValue(`${fieldPrefix}email`, '');
      setValue(`${fieldPrefix}costCenter`, '');
      setValue(`${fieldPrefix}employeeNumber`, '');
    }
  };

  return (
    <>
      <CL.SelectGroup
        className={className}
        name={fieldName}
        products={products}
        onChange={event => {
          if (event) {
            setFieldValues(String(event));
          }
        }}
        value={selectedItem}
      />
      {selectedChildComponent}
    </>
  );
};
