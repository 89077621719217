import * as CL from '@design-system/component-library';
import { paths } from '../../common/constants/pathVariables.js';
import { pushLicenseManagementGAEventToDataLayer } from '../../common/analytics';
import { t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';

export const LicenseManagementDisclaimerMfaRequired = () => {
  const navigate = useNavigate();

  return (
    <CL.Disclaimer
      disclaimerType="info"
      icon={<CL.Icon icon="lock" type="regular" aria-hidden="true" />}
      title={t.PAOF('Activate two-factor authentication')}
      text={t.PSY1(
        'Access to the Licence management portal requires two-factor authentication. You can activate two-factor authentication on the Company details and settings page.'
      )}
      visible
    >
      <div className="ds-margin-top--2">
        <CL.Button
          onClick={() => {
            pushLicenseManagementGAEventToDataLayer('opted_for_enabling_mfa');
            navigate(paths.COMPANY_INFO_SETTINGS);
          }}
        >
          {t.VIZE('Activate')}
        </CL.Button>
      </div>
    </CL.Disclaimer>
  );
};
