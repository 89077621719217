import * as CL from '@design-system/component-library';
import { ERROR_BANNER_ID } from './shoppingBasketUiUtils';
import { dsClass } from '../../common/constants/dsClasses';
import { t } from '../../common/i18n';
import type { ErrorData } from './types';

import './ErrorBanner.scss';

export interface ErrorBannerProps {
  errors: ErrorData[];
}

export const ErrorBanner = ({ errors }: ErrorBannerProps) => {
  const moreItems = errors.length > 1;

  const errorHeader = moreItems
    ? t.GAML('Check and correct the following {} fields:', errors.length.toString())
    : t.EPHQ('Check and correct the following field:');

  return errors.length > 0 ? (
    <div className="of-alert-disclaimer of-error of-with-horizontal-padding">
      <CL.Disclaimer
        id={ERROR_BANNER_ID}
        visible
        disclaimerType="alert"
        className={dsClass.MARGIN_VERTICAL_3}
        icon={<CL.Icon icon="error" aria-hidden="true" />}
      >
        <h5 className={dsClass.MARGIN_BOTTOM_3}>{errorHeader}</h5>
        <ul className={`${dsClass.MARGIN_BOTTOM_1} ${dsClass.PADDING_LEFT_1} ${dsClass.MARGIN_TOP_1}`}>
          {errors.map(error => {
            return (
              <li
                key={error.id}
                className={`${dsClass.MARGIN_BOTTOM_2} of-error-li ${moreItems ? 'ordered-list' : ''}`}
              >
                <CL.Link
                  className={`of-error-link ${dsClass.COLOR_RED_600} ${dsClass.MARGIN_0}`}
                  onClick={() => document.getElementById(error.id)?.scrollIntoView()}
                >
                  {error.message}
                  <CL.Icon className="of-alert-arrow" icon="arrow-right" size="m" color="red-600" />
                </CL.Link>
              </li>
            );
          })}
        </ul>
      </CL.Disclaimer>
    </div>
  ) : (
    <></>
  );
};
