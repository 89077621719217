import * as CL from '@design-system/component-library';
import type { Price } from '../../../generated/api/models.js';

export interface QuantityData extends Price {
  id: string;
  name: string;
  quantity?: number;
  value?: string;
}

export interface QuantityComponentProps {
  data: QuantityData;
  onUpdate: (data: QuantityData) => void;
}

export const QuantityComponent = (componentProps: QuantityComponentProps) => {
  const { data, onUpdate } = componentProps;
  const onChange = (quantity: number) => {
    onUpdate({ ...data, ...{ quantity } });
  };
  return (
    <div className="of-formik-quantity">
      <CL.Quantity currentValue={data.quantity} maxValue={999} minValue={0} onChange={onChange} />
    </div>
  );
};
