import * as CL from '@design-system/component-library';
import { Grid, GridWide } from '../Grid/Grid.js';
import { Link, useLocation } from 'react-router-dom';
import type { HTMLAttributeAnchorTarget, SyntheticEvent } from 'react';

import './LocalNavi.scss';

export interface LocalNaviItem {
  children: string;
  to: string;
  target?: HTMLAttributeAnchorTarget;
  hint?: boolean;
}

export const LocalNavi = (props: { categories: LocalNaviItem[]; disableSelected?: boolean; wide?: boolean }) => {
  const { pathname } = useLocation();
  const LocalNaviGrid = props.wide ? GridWide : Grid;
  const onClick = (selected: boolean) =>
    props.disableSelected && selected ? (e: SyntheticEvent) => e.preventDefault() : undefined;
  return (
    <LocalNaviGrid>
      <CL.TabNavigation className="of-local-navi">
        {props.categories.map(({ children, to, target, hint }) => {
          const selected = decodeURI(pathname) === to;
          const tabTitle = (
            <Link
              className={`of-local-navi--item ${selected ? 'of-local-navi--item__selected' : ''}`}
              to={to}
              target={target}
              aria-label={children}
              onClick={onClick(selected)}
            >
              {children}
              {hint && <CL.Icon icon="error" type="filled" color="warning" />}
            </Link>
          );
          return <CL.TabNavigationTab title={tabTitle} selected={selected} key={to} />;
        })}
      </CL.TabNavigation>
    </LocalNaviGrid>
  );
};
