import * as CL from '@design-system/component-library';
import { Link, generatePath } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses.js';
import { invoiceNumberMsg, openSupportCasesMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { SupportCaseHeader } from '../../generated/api/models.js';

import './OpenCasesNotification.scss';

export interface OpenCasesNotificationProps {
  supportCases: SupportCaseHeader[];
}

const MAX_SUPPORT_CASES = 5;

export const OpenCasesNotification = ({ supportCases }: OpenCasesNotificationProps) => {
  return (
    <div className={`${dsClass.MARGIN_TOP_3} ${dsClass.MARGIN_BOTTOM_3}`}>
      <CL.Disclaimer className="of-open-cases-notification" title={t.NPSZ(openSupportCasesMsg)} visible>
        {supportCases.slice(0, MAX_SUPPORT_CASES).map(supportCase => {
          const { supportCaseDisplayId, accountMasterId, invoiceNumber, featureType } = supportCase;
          return (
            <div key={supportCaseDisplayId}>
              <Link to={`${generatePath(paths.SUPPORT_CASE, { supportCaseDisplayId })}?mdmId=${accountMasterId}`}>
                {supportCaseDisplayId}
              </Link>{' '}
              | {t.HRWW(`${invoiceNumberMsg}:`)} {invoiceNumber} | {featureType}
            </div>
          );
        })}
        {supportCases.length > MAX_SUPPORT_CASES && (
          <p className="support-cases-link">
            <Link to={paths.SUPPORT_CASES}>{t.G089('See all support cases')}</Link>
          </p>
        )}
      </CL.Disclaimer>
    </div>
  );
};
