import { DropdownComponent } from '../FieldComponents/DropdownComponent.js';
import { Field } from 'formik';
import { requiredValidation } from '../validation.js';
import type { CLDropdownItem } from '../../types/componentLibrary';

export interface ProvidedDropdownProps {
  className?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  items: string[] | CLDropdownItem[];
  placeholderText?: string;
  selectedValue?: string;
  infoText?: string;
  helpText?: string;
  optionalText?: string;
  required?: boolean;
  integrated?: boolean;
}

/**
 * Represents dropdown field which is configured by unknown data
 * Always uses DropdownComponent as the underlying component
 */
export const ProvidedDropdown = (props: ProvidedDropdownProps) => (
  <Field {...props} validate={props.required ? requiredValidation : undefined} component={DropdownComponent} />
);
