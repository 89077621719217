import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { PostReviewRequest } from '../../generated/api/models.js';
import { review } from '../../selfservice/actions/index.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { CustomerOrder, OnlineOrderReview } from '../../generated/api/models.js';

import './ReviewOnlineOrderDialog.scss';

interface ReviewOnlineOrderDialogProps {
  approve: boolean;
  onlineOrderReview: OnlineOrderReview;
  customerOrder: CustomerOrder;
  errors?: CommonError[];
  isLoading?: boolean;
  ordererEmail?: string;

  onCloseDialog: () => void;
}

export const ReviewOnlineOrderDialog = ({
  onlineOrderReview,
  customerOrder,
  isLoading,
  approve,
  onCloseDialog,
  errors,
  ordererEmail,
}: ReviewOnlineOrderDialogProps) => {
  const dispatch = useDispatch();
  const [rejectReason, setRejectReason] = useState<string>();

  const systemErrors = errors ? errors.filter(err => err.type === CommonErrorType.SYSTEM) : undefined;

  return (
    <ConfirmationDialog
      header={approve ? t.BO8J('Confirm approval for the order') : t.PRH3('Reject a device agreement')}
      body={
        approve ? (
          <div>
            <p>
              {t.PW2I('Please make sure that the order and billing information is correct')}
              <br />
              <br />
              {t.JBMJ('Once the order has been confirmed, the order cannot be cancelled')}
            </p>
          </div>
        ) : (
          <div>
            <div>
              {t.RYLC('We will send notice of the rejection of the Device agreement to')}{' '}
              {ordererEmail ? (
                <a href={'mailto:' + ordererEmail}>{ordererEmail}</a>
              ) : (
                customerOrder.customerOrderContact
              )}
            </div>
            <div className="reject-reason">
              <CL.Input
                type="textarea"
                name="rejectReason"
                label={t.LT6X('Type your comments here..')}
                maxlength={255}
                onValueChange={event =>
                  event &&
                  setRejectReason(
                    (event as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>).target
                      .value
                  )
                }
              />
            </div>
          </div>
        )
      }
      isLoading={isLoading}
      onCloseDialog={onCloseDialog}
      onConfirm={() => {
        const validationError = undefined;
        dispatch(
          review(
            onlineOrderReview,
            PostReviewRequest.ReviewTypeEnum.ONLINE_ORDER,
            approve,
            rejectReason,
            validationError,
            customerOrder.customerOrderDisplayId
          )
        );
      }}
      disableConfirm={approve && onlineOrderReview.billingAccountId === undefined}
      errors={systemErrors}
    />
  );
};
