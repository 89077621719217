import * as CL from '@design-system/component-library';
import { OfferBadge as OfferBadgeSchema } from '../../generated/api/models.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { BadgeProps } from '@design-system/component-library';

import './OfferBadge.scss';

interface OfferBadgeProps {
  className?: string;
  offerBadge?: OfferBadgeSchema;
}

export const OfferBadge = (props: OfferBadgeProps) => {
  if (!props.offerBadge) {
    return null;
  }
  const { text, type } = props.offerBadge;

  const getBadgeColor = (badgeType: string): BadgeProps['color'] => {
    switch (badgeType) {
      case OfferBadgeSchema.TypeEnum.SALE:
        return 'warning';
      case OfferBadgeSchema.TypeEnum.SPECIAL:
        return 'primary';
      case OfferBadgeSchema.TypeEnum.OUT_OF_STOCK:
        return 'neutral-light';
      default:
        return 'secondary';
    }
  };

  const getBadgeClass = (badgeType: string): string => {
    switch (badgeType) {
      case OfferBadgeSchema.TypeEnum.SALE:
        return dsClass.BADGE_TYPE_SALE;
      case OfferBadgeSchema.TypeEnum.SPECIAL:
        return dsClass.BADGE_TYPE_SPECIAL;
      case OfferBadgeSchema.TypeEnum.OUT_OF_STOCK:
        return dsClass.BADGE_TYPE_OUT_OF_STOCK;
      default:
        return '';
    }
  };

  return (
    <div className={`of-offer-badge ${props.className}`}>
      <CL.Badge className={getBadgeClass(type)} color={getBadgeColor(type)} text={text} />
    </div>
  );
};
