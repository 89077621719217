import * as CL from '@design-system/component-library';
import type { CLColor } from '../../common/types/componentLibrary';

import './Icon.scss';

export interface IconAttrs extends Omit<CL.IconProps, 'color'> {
  color: CLColor;
}

export const Icon = ({ color, ...rest }: IconAttrs): JSX.Element => {
  return <CL.Icon {...rest} color={color} />;
};
