import { NumberRange, SubscriptionDetailsPbxSolution } from '../../generated/api/models.js';
import { dsClass } from '../../common/constants/dsClasses';
import {
  mobilePhoneNumberMsg,
  notInUseMsg,
  notSetMsg,
  numberConversionToBeDisplayedMsg,
  purposeOfUseMsg,
  selectedExtensionNumberingsMsg,
  t,
} from '../../common/i18n/index.js';
import type { CLDescriptionItem } from '../../common/types/componentLibrary';

const callerLineIdServiceString = (callerLineEnum: SubscriptionDetailsPbxSolution.CallerLineIdServiceEnum) => {
  const data: { [key in SubscriptionDetailsPbxSolution.CallerLineIdServiceEnum]?: string } = {};
  data[SubscriptionDetailsPbxSolution.CallerLineIdServiceEnum.ALWAYS] = t.B152('Internal and external calls');
  data[SubscriptionDetailsPbxSolution.CallerLineIdServiceEnum.EXTERNAL] = t.BLLL('External calls only');
  data[SubscriptionDetailsPbxSolution.CallerLineIdServiceEnum.NOT_IN_USE] = t.MUF5(notInUseMsg);
  return data[callerLineEnum] || t.BTMU(notSetMsg);
};

const extensionDialTargetString = (extensionEnum: SubscriptionDetailsPbxSolution.ExtensionDialTargetEnum) => {
  switch (extensionEnum) {
    case SubscriptionDetailsPbxSolution.ExtensionDialTargetEnum.MOBILE:
      return t.FRYN(mobilePhoneNumberMsg);
    case SubscriptionDetailsPbxSolution.ExtensionDialTargetEnum.PSTN:
      return t.X7W5('Fixed line number');
    default:
      return t.BTMU(notSetMsg);
  }
};

export const getPbxLiteFields = (
  mobilePbx: SubscriptionDetailsPbxSolution,
  numberFormatter: (numberRanges: NumberRange[], filterCondition: NumberRange.RangeTypeEnum) => JSX.Element[],
  subscriptionPurposeOfUse?: string
): CLDescriptionItem[] => [
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.QZVD('Reserved numbering space')}
          </label>
        </div>
        <div>{numberFormatter(mobilePbx.numberRanges, NumberRange.RangeTypeEnum.PSTN)}</div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.CUDY('Call destination when dialling extension')}
          </label>
        </div>
        <div>
          {mobilePbx.extensionDialTarget
            ? extensionDialTargetString(mobilePbx.extensionDialTarget)
            : t.IFIJ('No target')}
        </div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.FIS5(selectedExtensionNumberingsMsg)}
          </label>
        </div>
        <div>{numberFormatter(mobilePbx.numberRanges, NumberRange.RangeTypeEnum.EXTENSION)}</div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.Y8KX(purposeOfUseMsg)}
          </label>
        </div>
        <div>
          {subscriptionPurposeOfUse !== 'Online-tilaus' ? subscriptionPurposeOfUse : t.X0RT('No purpose of use set')}
        </div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.RV74('Prefixes when dialling')}
          </label>
        </div>
        {mobilePbx.mobileCallPrefix && (
          <div>
            {mobilePbx.mobileCallPrefix} - {t.GWCA('Mobile numbers')}
          </div>
        )}
        {mobilePbx.pstnCallPrefix && (
          <div>
            {mobilePbx.pstnCallPrefix} - {t.YAVU('Fixed line numbers')}
          </div>
        )}
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.O1MZ(numberConversionToBeDisplayedMsg)}
          </label>
        </div>
        {mobilePbx && <div>{callerLineIdServiceString(mobilePbx.callerLineIdService)}</div>}
      </>
    ),
  },
];
