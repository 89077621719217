import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { TableUrlParams } from '../Table';
import { allCompaniesMsg, pcsMsg, t } from '../../common/i18n';
import { getUserAccounts } from '../Header/dynamic/headerFunctions.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { HeaderUserAccount } from '../../common/types/authentication';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';

import './ConsolidatedListViewSelector.scss';

export interface CompanySelectorForListViewsProps {
  authenticatedUser?: RealAuthenticatedUserState;
}

const getUserAccountsWithAllOption = (authenticatedUser?: RealAuthenticatedUserState) => {
  const userAccounts = getUserAccounts(authenticatedUser);
  return [
    {
      accountMasterId: undefined,
      active: true,
      name: `${t.WW6V(allCompaniesMsg)} (${userAccounts.length} ${t.B3MG(pcsMsg)})`,
    },
    ...userAccounts,
  ];
};

export const ConsolidatedListViewSelector = ({ authenticatedUser }: CompanySelectorForListViewsProps) => {
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  if (!isMultiBiz(authenticatedUser)) {
    return null;
  }

  return (
    <div className="company-selector-for-list-views">
      <CompanySelector
        autoCompleteConfig={{ isSortable: false }}
        inputRef={inputRef}
        onInputChange={(_e: ChangeEvent | KeyboardEvent | MouseEvent, option: Partial<HeaderUserAccount>) => {
          const queryParams = new URLSearchParams(search);
          if (option?.accountMasterId) {
            queryParams.set('mdmId', option.accountMasterId);
          } else {
            queryParams.delete('mdmId');
          }
          queryParams.set(TableUrlParams.PAGE, '1');
          queryParams.set(TableUrlParams.OFFSET, '0');
          // Use setTimeout without delay to wait for the call stack to be empty in order to execute the callback.
          // Otherwise blur() will cause the selected company to remain the same regardless of user selection.
          setTimeout(() => {
            navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
            inputRef.current?.blur();
          });
        }}
        userAccounts={getUserAccountsWithAllOption(authenticatedUser)}
      />
    </div>
  );
};
