import * as CL from '@design-system/component-library';
import { RecordValueView } from '../Records/RecordValueView.js';
import { dsClass } from '../../../../../common/constants/dsClasses.js';
import { isDnsRecordEditable, isDnsRecordEditingAllowed } from '../../../dnsManagementUtils.js';
import { t } from '../../../../../common/i18n/index.js';
import type { CLTableRow } from '../../../../../common/types/componentLibrary';
import type { DnsRecordEditable, DnsRecordSupported } from '../../../dnsManagementUtils.js';

export const formatRecordListViewRow = (
  row: DnsRecordSupported,
  domainName: string,
  dnsManagementDisallowed: boolean,
  openEdit: (row: DnsRecordEditable) => void,
  openDeleteConfirmation: (row: DnsRecordSupported) => void
) =>
  ({
    name: row.name,
    ttl: String(row.ttl),
    type: row.type,
    values: <RecordValueView type={row.type} values={row.rawValues} />,
    actionButtons: !dnsManagementDisallowed && (
      <div key="view" className={`${dsClass.WHITE_SPACE_NOWRAP} of-dns-record-list-row-view`}>
        {isDnsRecordEditable(row) && isDnsRecordEditingAllowed(row, domainName) ? (
          <>
            <CL.Button
              color="light"
              className={`${dsClass.BORDER_STYLE_NONE} ${dsClass.PADDING_VERTICAL_0} ${dsClass.MARGIN_RIGHT_2}`}
              size="s"
              onClick={() => openEdit(row)}
            >
              <CL.Icon icon="edit" color="brand-blue" />
            </CL.Button>
            <CL.Button
              color="light"
              className={`${dsClass.BORDER_STYLE_NONE} ${dsClass.PADDING_VERTICAL_0}`}
              size="s"
              onClick={() => openDeleteConfirmation(row)}
            >
              <CL.Icon icon="delete" color="brand-blue" />
            </CL.Button>
          </>
        ) : (
          <CL.Tooltip
            className="of-dns-record-list-row-view__tooltip"
            i18n_tooltip_contentText={t.PGK9('Editing this record type is not possible on our service')}
            placement="left"
            triggerElement={<CL.Icon icon="information" className={dsClass.MARGIN_1} color="brand-blue" size="s" />}
          />
        )}
      </div>
    ),
  }) satisfies CLTableRow;
