import * as CL from '@design-system/component-library';
import { AuthenticationLevel } from '../../generated/api/authenticationLevel';
import { BlackListedAccountDisclaimer } from './BlackListedAccountDisclaimer';
import { DialogType } from '../../common/enums.js';
import { Dropdown } from '../Dropdown/Dropdown.js';
import { Grid } from '../Grid/Grid.js';
import { LicenseManagementAccountList } from './LicenseManagementAccountList/LicenseManagementAccountList';
import { LicenseManagementDisclaimerMfaRequired } from './LicenseManagementDisclaimerMfaRequired';
import {
  companySelectMsg,
  continueMsg,
  licenseManagementPortalMsg,
  licenseManagementRedirectFailedHeaderMsg,
  licenseManagementRedirectFailedMsg,
  licenseManagementRedirectInfoMsg,
  t,
} from '../../common/i18n/index.js';
import { fetchBillChannels, fetchCompanyInfo, fetchLicenseManagementAccounts } from '../../../src/common/fetch.js';
import { getPrimaryMdmId } from '../../common/utils/accountUtils';
import { getUserAccounts } from '../Header/dynamic/headerFunctions';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { pushLicenseManagementGAEventToDataLayer } from '../../common/analytics';
import { showDialog } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider';
import { useDispatch, useStore } from 'react-redux';
import { useEffect, useState } from 'react';
import type { CLDropdownItem } from '../../common/types/componentLibrary';
import type { LicenseManagementAccount } from '../../generated/api/licenseManagementAccount';
import type { State } from '../../selfservice/common/store.js';

import './LicenseManagement.scss';

export const LicenseManagement = () => {
  const { authenticatedUser } = useAuth();
  const [isCompanyBlackListed, setIsCompanyBlackListed] = useState(false);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [accounts, setAccounts] = useState<LicenseManagementAccount[] | undefined>(undefined);
  const [selectedCompany, setSelectedCompany] = useState(() => getPrimaryMdmId(authenticatedUser));
  const dispatch = useDispatch();
  const { featureFlags } = useStore<State>().getState().config;
  const is2FAAuthenticated = authenticatedUser?.authenticationLevel === AuthenticationLevel.TWO_FACTOR;

  useEffect(() => {
    pushLicenseManagementGAEventToDataLayer('page_visited');
  }, []);

  const showErrorDialog = () =>
    dispatch(
      showDialog({
        body: <>{t.NMRJ(licenseManagementRedirectFailedMsg)}</>,
        header: t.DT3P(licenseManagementRedirectFailedHeaderMsg),
        type: DialogType.GENERIC_INFO_DIALOG,
      })
    );

  const handleNoLicenseManagementAccountFoundError = async (accountMasterId: string) => {
    if (isFeatureEnabled(featureFlags.createInterworksAccount)) {
      try {
        const billChannels = await fetchBillChannels();
        dispatch(
          showDialog({
            type: DialogType.NEW_INTERWORKS_ACCOUNT_DIALOG,
            billChannels: billChannels,
            accountMasterId: accountMasterId,
          })
        );
        pushLicenseManagementGAEventToDataLayer('new_iw_account_form_presented');
      } catch (_) {
        showErrorDialog();
      }
    } else {
      showErrorDialog();
    }
  };

  const fetchAccounts = async (accountMasterId: string) => {
    setIsFetchingAccounts(true);
    setIsCompanyBlackListed(false);
    setAccounts(undefined);
    try {
      const companyInfo = await fetchCompanyInfo(accountMasterId);
      if (companyInfo.isBlacklistedFromInterworks) {
        setIsCompanyBlackListed(true);
        return;
      }
      const res = await fetchLicenseManagementAccounts(accountMasterId);
      setAccounts(res);

      if (res.length === 0) {
        await handleNoLicenseManagementAccountFoundError(accountMasterId);
      }
    } catch (error) {
      showErrorDialog();
    } finally {
      setIsFetchingAccounts(false);
    }
  };

  const companySelectionItems: CLDropdownItem[] = (getUserAccounts(authenticatedUser) || []).map(account => ({
    label: account.name,
    value: account.accountMasterId!,
  }));

  const primaryAccountId = getPrimaryMdmId(authenticatedUser);

  return (
    <Grid>
      <h2>{t.L866(licenseManagementPortalMsg)}</h2>
      <p>{t.M0RI(licenseManagementRedirectInfoMsg)}</p>
      {is2FAAuthenticated ? (
        <>
          <h3 className="ds-h3-book ds-margin-top--7">{t.P3C8(companySelectMsg)}</h3>
          <p>{t.ITR5('Select the company whose licences you want to manage.')}</p>
          <CL.Grid>
            <CL.GridRow>
              <CL.GridCol colWidthXS={4} colWidthS={4} colWidthL={6} className="ds-padding--0">
                <div className="of-license-management--company-selection">
                  <Dropdown
                    name="company-selection"
                    className="of-license-management--company-selection--dropdown ds-margin-bottom--0"
                    items={companySelectionItems}
                    onValueChange={listElement => {
                      setSelectedCompany(listElement.dataset.value!);
                    }}
                    selectedValue={primaryAccountId}
                  />
                  <CL.Button color="light" onClick={() => fetchAccounts(selectedCompany!)} loading={isFetchingAccounts}>
                    {t.I62A(continueMsg)}
                  </CL.Button>
                </div>
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
          <div className="ds-margin-top--5">
            {isCompanyBlackListed && <BlackListedAccountDisclaimer />}
            {accounts && accounts.length > 0 && (
              <LicenseManagementAccountList
                licenseManagementAccounts={accounts}
                onErrorNavigatingToLicenseManagementAccount={showErrorDialog}
                accountMasterId={selectedCompany!}
              />
            )}
          </div>
        </>
      ) : (
        <LicenseManagementDisclaimerMfaRequired />
      )}
    </Grid>
  );
};
