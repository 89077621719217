import * as CL from '@design-system/component-library';
import { type Choices } from '../../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { concat, getValue } from '../../OpenFormUtils.js';
import { selectMsg, t } from '../../../../common/i18n/index.js';
import { setValueOptions } from '../../OpenFormHooks/useOpenFormMethods.js';
import { useAbortController } from '../../OpenFormHooks/useAbortController.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../../../common/hooks/useDebounce.js';
import { useFormContext } from 'react-hook-form';
import { useOpenFormAsync } from '../../OpenFormProvider.js';
import classNames from 'classnames';
import type { ParameterizedSearchContactResult } from '../../../../generated/api/parameterizedSearchContactResult.js';

// eslint-disable-next-line @typescript-eslint/naming-convention
const Combobox = ({ Account, Id, FirstName, LastName, Email, MobilePhone }: ParameterizedSearchContactResult) => {
  const label = concat(concat(FirstName, LastName).join(' '), Account?.Name).join(' | ');
  return {
    id: Id!,
    value: Id!,
    label: label,
    html: (
      <div>
        <div>{label}</div>
        <div>{concat(Email, MobilePhone).join(' | ')}</div>
      </div>
    ),
  };
};

const toMap = (...input: (ParameterizedSearchContactResult | undefined)[]) =>
  new Map(input.filter(Boolean).map(c => [c!.Id, c]));

const validateSearchTerm = (searchTerm: string) => searchTerm.length !== 1;

export const OpenFormQuestionContact = ({
  colWidth = 6,
  disabled,
  required,
  label,
  name,
  choices,
  onSelect,
}: {
  colWidth?: CL.GridColProps['colWidth'];
  disabled: boolean;
  required: boolean;
  label: string;
  name: string;
  choices: Choices | string | undefined;
  onSelect?: (c?: ParameterizedSearchContactResult) => void;
}) => {
  const { register, setValue } = useFormContext();
  const async = useOpenFormAsync();
  const [_, setAbortController] = useAbortController();
  const [contacts, setContacts] = useState(toMap());
  const selectedValue = useMemo(() => getValue(choices), [choices]);
  const fetchContacts = useCallback<(searchTerm?: string | null) => void>(
    searchTerm =>
      validateSearchTerm((searchTerm = (searchTerm ?? '').trim())) &&
      setAbortController(
        async
          .fetchContacts({ searchTerm })
          .settled(({ searchRecords = [] }) => setContacts(toMap(...searchRecords, contacts.get(selectedValue!))))
          .failed(text => async.actions.notification({ text, type: 'warning' }))
          .cache(searchTerm)
          .execute(true).ctrl
      ),
    [async, selectedValue, setAbortController, contacts]
  );

  useEffect(() => {
    fetchContacts();
    register(name, { disabled, required });
    setValue(name, selectedValue, setValueOptions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={colWidth} className={classNames({ ['label--mandatory']: required })}>
        <CL.Combobox
          disabled={disabled}
          i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          items={useMemo(() => Array.from(contacts.values()).map(Combobox), [contacts])}
          label={label}
          selectedItemFormat="label"
          selectedValue={selectedValue}
          onOptionsShow={() => setContacts(toMap(contacts.get(selectedValue!), ...contacts.values()))}
          onValueChange={useDebounce(fetchContacts, 500)}
          onValueSelect={value => {
            const contact = value ? contacts.get(value) : undefined;
            onSelect ? onSelect(contact) : setValue(concat(name, 'contact').join('.'), contact);
            setValue(name, value, setValueOptions);
          }}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
