import * as CL from '@design-system/component-library';
import { ContactType } from '../../generated/api/models.js';
import { CostCenter, Email, Name, PhoneNumber } from '../../common/formik/index.js';
import { Reference } from '../../common/formik/Fields/Reference.js';
import {
  costCenterMsg,
  costCenterToolTipMsg,
  firstNameMsg,
  lastNameMsg,
  nameMsg,
  referenceIdMsg,
  referenceToolTipMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { CLDescriptionItem } from '../../common/types/componentLibrary';
import type { Contact } from '../../generated/api/models.js';

import './ContactForm.scss';

interface ContactFormProps {
  isEdit: boolean;
  contact?: Contact;
}

export const ContactForm = (props: ContactFormProps) => {
  const { isEdit, contact } = props;

  const nameDescriptionItems: CLDescriptionItem[] = [];
  const contactInfoDescriptionItems: CLDescriptionItem[] = [];
  const costCenterAndReferenceDescriptionItems: CLDescriptionItem[] = [];

  if (contact?.contactType === ContactType.COMMON_FUNCTION) {
    nameDescriptionItems.push({
      title: t.VGFI(nameMsg),
      description: <Name readonly={!isEdit} name="name" label="" />,
    });
  } else {
    nameDescriptionItems.push({
      title: t.AIK7(firstNameMsg),
      description: <Name readonly={!isEdit} name="firstName" label="" placeholder={t.AIK7(firstNameMsg)} />,
    });
    nameDescriptionItems.push({
      title: t.Y41S(lastNameMsg),
      description: <Name readonly={!isEdit} name="lastName" label="" placeholder={t.Y41S(lastNameMsg)} />,
    });
  }
  contactInfoDescriptionItems.push({
    title: t.V9K9('Mobile phone number (work)'),
    description: (
      <PhoneNumber
        readonly={!isEdit}
        name="phoneNumber"
        label=""
        placeholder={t.V9K9('Mobile phone' + ' number (work)')}
      />
    ),
  });
  contactInfoDescriptionItems.push({
    title: t.JYZO('Email (work)'),
    description: <Email readonly={!isEdit} name="email" label="" placeholder={t.JYZO('Email (work)')} />,
  });
  costCenterAndReferenceDescriptionItems.push({
    title: (
      <span>
        {t.QJUW(costCenterMsg)}
        <CL.Tooltip
          triggerElement={<CL.Icon color="blue-800" type="regular" icon="information" size="s" />}
          i18n_tooltip_contentText={t.UE8R(costCenterToolTipMsg)}
          className={dsClass.MARGIN_LEFT_3}
          placement="top"
        />
      </span>
    ),
    description: (
      <CostCenter
        readonly={!isEdit}
        label=""
        type="text"
        name="costCenter"
        labelTooltip=""
        placeholder={t.QJUW(costCenterMsg)}
      />
    ),
  });
  costCenterAndReferenceDescriptionItems.push({
    title: (
      <span>
        {t.CWR5(referenceIdMsg)}
        <CL.Tooltip
          triggerElement={<CL.Icon color="blue-800" type="regular" icon="information" size="s" />}
          i18n_tooltip_contentText={t.HBBD(referenceToolTipMsg)}
          className={dsClass.MARGIN_LEFT_3}
          placement="top"
        />
      </span>
    ),
    description: (
      <Reference readonly={!isEdit} label="" type="text" labelTooltip="" placeholder={t.CWR5(referenceIdMsg)} />
    ),
  });

  return (
    <div>
      <CL.Description
        className="of-contact-form__description of-contact-form__description-names"
        items={nameDescriptionItems}
        columns={2}
      />
      <CL.Description
        className="of-contact-form__description of-contact-form__description-contact-info"
        items={contactInfoDescriptionItems}
        columns={2}
      />
      <CL.Description
        className="of-contact-form__description of-contact-form__description-costcenter-and-reference"
        items={costCenterAndReferenceDescriptionItems}
        columns={2}
      />
    </div>
  );
};
