import * as CL from '@design-system/component-library';
import { t } from '../../../common/i18n/index.js';
import type { DeliveryMode } from '../DeviceCheckoutDeliveryDetailsUtils.js';

interface DeliveryModeSelectionProps {
  deliveryMode: DeliveryMode;
  onDeliveryModeChosen: (deliveryMode: DeliveryMode) => void;
  isEmployee?: boolean;
  availableDeliveryModes: DeliveryMode[];
}

interface DeliveryModeSelectorProps {
  deliveryMode: DeliveryMode;
  selectedDeliveryMode: DeliveryMode;
  onDeliveryModeChosen: (deliveryMode: DeliveryMode) => void;
  deliveryModeCount: number;
}

const DeliveryModeSelector = ({
  onDeliveryModeChosen,
  deliveryMode,
  deliveryModeCount,
  selectedDeliveryMode,
}: DeliveryModeSelectorProps) => (
  <CL.Button
    className={selectedDeliveryMode?.type === deliveryMode.type && deliveryModeCount > 1 ? 'selected' : ''}
    color="secondary"
    onClick={() => {
      if (onDeliveryModeChosen) {
        onDeliveryModeChosen(deliveryMode);
      }
    }}
    size="l"
  >
    <CL.Icon
      className="of-device-checkout-delivery-mode__list--icon"
      color="black"
      icon={deliveryMode.icon}
      type="light"
    />
    <span className="of-device-checkout-delivery-mode__list--description">{deliveryMode.createText()}</span>
  </CL.Button>
);

export const DeviceCheckoutDeliveryModeSelection = ({
  isEmployee,
  onDeliveryModeChosen,
  deliveryMode,
  availableDeliveryModes,
}: DeliveryModeSelectionProps) => (
  <div className="of-device-checkout-delivery-mode">
    {!isEmployee ? (
      <div className="of-device-checkout-delivery-mode__non-employee-info">
        {t.D99O('Orders placed before 12 PM on weekdays will be usually delivered on the next working day.')}
      </div>
    ) : (
      <div className="of-device-checkout-delivery-mode__employee-info">
        {t.JXNJ(
          'Order will be handled after approval. Orders placed before 12 am on weekdays will be usually delivered on the next working day.'
        )}
      </div>
    )}
    <div className="of-device-checkout-delivery-mode__list">
      {availableDeliveryModes.map(mode => (
        <DeliveryModeSelector
          key={mode.type}
          deliveryMode={mode}
          deliveryModeCount={availableDeliveryModes.length}
          onDeliveryModeChosen={onDeliveryModeChosen}
          selectedDeliveryMode={deliveryMode}
        />
      ))}
    </div>
  </div>
);
