import * as CL from '@design-system/component-library';
import { Email, PhoneNumber } from '../../../../common/react-hook-form/fields/index.js';
import { ImpactedUserEditableFields } from '../utils.js';
import { cancelMsg, numberMsg, saveMsg, t } from '../../../../common/i18n/index.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import type { CLTableRow } from '../../../../common/types/componentLibrary';
import type { ImpactedUser } from '../utils.js';

export const formatRecordListEditRow = (row: ImpactedUser, onCancelEdit: () => void, isSubmitting: boolean) =>
  ({
    userName: <span>{row.userName}</span>,
    mobile: <PhoneNumber name={ImpactedUserEditableFields.mobile} label={t.CCB1(numberMsg)} />,
    email: <Email name={ImpactedUserEditableFields.email} />,
    actionButtons: (
      <div key="edit" className={`${dsClass.PADDING_TOP_4} of-dns-record-list-row-edit__actions`}>
        <CL.Button id="submit" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          {t.NIWE(saveMsg)}
        </CL.Button>
        <CL.Button color="link" className={dsClass.MARGIN_LEFT_3} onClick={onCancelEdit} disabled={isSubmitting}>
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </div>
    ),
  }) satisfies CLTableRow;
