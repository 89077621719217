import { deepEqual } from '../../common/utils/objectUtils.js';
import { employeePromotionToProductGridProduct, toProductGridProduct } from '../common/cmsTemplatesUtils.js';
import { setDiscountsForOnlineModelHeaders } from '../../common/utils/priceUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useIdObserver } from '../../components/Impression/useIdObserver.js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Templates from '@elisa-luukku-sites/cms-templates';
import type { CLProductGridProductWithRequiredId } from '../../common/types/componentLibrary';
import type { OnlineModelHeader } from '../../generated/api/models.js';
import type { PromotionBlockData } from '../cmsSchema.js';
import type { State } from '../../selfservice/common/store.js';

import './PromotionBlock.scss';

interface PromotionBlockContainerProps extends PromotionBlockData {
  isEmployeePromotionBlock?: boolean;
}

const SiteTemplates = Templates.default;

export const PromotionBlockContainer = ({
  title,
  onlineModelHeaders,
  promotions = [],
  eCommerceEvents,
  isEmployeePromotionBlock = false,
}: PromotionBlockContainerProps) => {
  const navigate = useNavigate();
  const promotionOnlineModelHeaders: OnlineModelHeader[] = [];
  const products: CLProductGridProductWithRequiredId[] = [];
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const { authenticatedUser } = useAuth();
  promotions.map(promotion => {
    const onlineModelHeader = onlineModelHeaders.find(omh => omh.onlineModelCode === promotion.productGuid);
    if (onlineModelHeader) {
      promotionOnlineModelHeaders.push(onlineModelHeader);
    }
  });

  setDiscountsForOnlineModelHeaders(promotionOnlineModelHeaders, companyInfo, authenticatedUser).map(omh => {
    const promotionText = promotions.find(p => p.productGuid === omh?.onlineModelCode)?.promotionText;
    const productGridProduct = isEmployeePromotionBlock
      ? employeePromotionToProductGridProduct(authenticatedUser, omh)
      : toProductGridProduct(authenticatedUser, omh, promotionText);
    products.push(productGridProduct);
  });

  const onPromotionClick = (element: HTMLElement, event: React.SyntheticEvent) => {
    // This is not optimal because we are now tied to CL element structure and if that changes, this will break
    const id = element.getAttribute('aria-labelledby');
    const onlineModelHeader = promotionOnlineModelHeaders.find(omh => {
      return `${omh?.onlineModelCode}Name` === id;
    });
    const position = onlineModelHeader ? promotionOnlineModelHeaders.indexOf(onlineModelHeader) : 0;
    if (eCommerceEvents?.onClickListProduct && onlineModelHeader) {
      eCommerceEvents.onClickListProduct('PromotionBlock', [position], [onlineModelHeader]);
    }
    if (onlineModelHeader?.pagePath) {
      // Do not use the link click to navigate as that causes a full page reload
      event.preventDefault();
      navigate(onlineModelHeader.pagePath);
    }
  };

  const onImpression = (id: string) => {
    const onlineModelHeader = promotionOnlineModelHeaders.find(omh => {
      return omh?.onlineModelCode === id;
    });
    const position = onlineModelHeader ? promotionOnlineModelHeaders.indexOf(onlineModelHeader) : 0;
    if (eCommerceEvents?.onSeeListProduct && onlineModelHeader) {
      eCommerceEvents.onSeeListProduct('PromotionBlock', [position], [onlineModelHeader]);
    }
  };

  useIdObserver(
    products.map(p => p.productId),
    onImpression
  );

  return (
    <div className="of-promotion-block">
      <SiteTemplates.PromotionBlock title={title} products={products} onPromotionClick={onPromotionClick} />
    </div>
  );
};
