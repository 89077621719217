import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { getLanguageOptions } from '../../utils/billingAccountUtils.js';
import { languageMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { useController, useFormContext } from 'react-hook-form';
import type { CLDropdownItem } from '../../types/componentLibrary';

interface DeliveryMethodProps {
  className?: string;
  name: string;
  label?: string;
  placeholder?: string;
}

export const Language = ({ name, label = t.L3C5(languageMsg) }: DeliveryMethodProps) => {
  const languages = getLanguageOptions();
  const methods = useFormContext();
  const { control, setValue } = methods;
  const { field, formState } = useController({ name, control });
  const defaultValues = formState.defaultValues;
  const keys = name.split('.');
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), defaultValues);
  const defaultValue = languages.find(i => i.value === value);

  return (
    <AutoComplete<CLDropdownItem>
      className="large-min-height"
      inputRef={field.ref}
      options={languages}
      defaultOption={defaultValue}
      getUniqueId={i => i.value!}
      getDisplayValue={i => i.label!}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={label}
      config={{ isClearable: false, isSortable: false }}
    />
  );
};
