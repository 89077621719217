import * as CL from '@design-system/component-library';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { Link } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getRedeemPrice } from '../EppRedeem/EppRedeemUtils.js';
import { getSubscriptionStatus, isInExtensionPeriod } from '../../common/utils/subscriptionUtils.js';
import { isEppDeviceChangeAllowed, isEppDeviceSubscriptionExpiring } from './eppSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import type { Subscription, SubscriptionAction } from '../../generated/api/models.js';

export const DeviceSubscriptionExpirationNotification = ({
  subscription,
  subscriptionActions,
}: {
  subscription: Subscription;
  subscriptionActions?: SubscriptionAction[];
}) => {
  const isExpiring = isEppDeviceSubscriptionExpiring(subscription);
  const isDeviceChangeAllowed = isEppDeviceChangeAllowed(subscription);
  const hasPendingActions = getSubscriptionStatus(subscription, subscriptionActions).pendingActions;

  if (isExpiring && isDeviceChangeAllowed && !hasPendingActions) {
    const header = isInExtensionPeriod(subscription)
      ? t.XMCF('Your device is on the extension period')
      : t.VJMT(
          'Your device contract will end on {}',
          formatTimestampToUTCDDMMYYYY(subscription.details?.commitmentEndDate) ?? ''
        );
    const eppModificationPrices = subscription.details?.device?.eppModificationPrices;

    return (
      <div className={dsClass.PADDING_BOTTOM_4}>
        <CL.Disclaimer title={header} icon={<CL.Icon type="filled" icon="information" color="orange" />} visible>
          <div className={dsClass.TEXT_S}>
            {t.XPIK(`You can order a new device from your company's`)}{' '}
            <Link className={dsClass.MARGIN_RIGHT_0} to={paths.EMPLOYEE_DEVICE_STORE}>
              {t.NFGH('catalog')}
            </Link>
            {'. '}
            {eppModificationPrices &&
              t.HPSN(
                'A returning package for your old device will be shipped with the new device. While placing the order for your new device, you can also redeem your current device for {}.',
                getRedeemPrice(EppRedeemTerminateRequestType.EMPLOYEE_REDEEM, eppModificationPrices)
              )}
          </div>
        </CL.Disclaimer>
      </div>
    );
  }
  return null;
};
