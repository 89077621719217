import * as CL from '@design-system/component-library';
import { debitOrCreditCardMsg, invoiceMsg, t } from '../../common/i18n/index.js';

import './SelectPaymentMethod.scss';

export enum PaymentMethod {
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
  CARD = 'CARD',
}

const OptionId = {
  BILLING_ACCOUNT_PAYMENT: 'billing-account-payment',
  CARD_PAYMENT: 'card-payment',
} as const;

const getOptionsV2 = (isEligibleForCardPayment: boolean) => [
  {
    icon: <CL.Icon icon="invoice" size="xl" />,
    id: OptionId.BILLING_ACCOUNT_PAYMENT,
    name: t.JF65(invoiceMsg),
    description: t.GRAL('Email invoice, E-invoice or Paper invoice'),
  },
  {
    icon: <CL.Icon icon="money" size="xl" />,
    id: OptionId.CARD_PAYMENT,
    name: t.UKMC(debitOrCreditCardMsg),
    description: 'Visa, Mastercard',
    disabled: !isEligibleForCardPayment,
  },
];

interface SelectPaymentMethodProps {
  paymentMethod: PaymentMethod;
  onUpdatePaymentMethod: (paymentMethod: PaymentMethod) => void;
  isEligibleForCardPayment?: boolean;
}

export const SelectPaymentMethod = ({
  paymentMethod,
  onUpdatePaymentMethod,
  isEligibleForCardPayment = true,
}: SelectPaymentMethodProps) => {
  return (
    <CL.SelectGroup
      label=""
      value={
        paymentMethod === PaymentMethod.BILLING_ACCOUNT
          ? OptionId.BILLING_ACCOUNT_PAYMENT
          : isEligibleForCardPayment
            ? OptionId.CARD_PAYMENT
            : undefined
      }
      products={getOptionsV2(isEligibleForCardPayment)}
      onChange={id => {
        if (id === OptionId.BILLING_ACCOUNT_PAYMENT) {
          onUpdatePaymentMethod(PaymentMethod.BILLING_ACCOUNT);
        } else if (isEligibleForCardPayment) {
          onUpdatePaymentMethod(PaymentMethod.CARD);
        }
      }}
      className="of-select-payment-method__tile"
      id="choose-payment-methods"
    />
  );
};
