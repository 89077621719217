import * as CL from '@design-system/component-library';
import * as React from 'react';
import classNames from 'classnames';
import type { CLSelectGroupProduct } from '../../common/types/componentLibrary';

import './SelectGroup.scss';

export interface SelectGroupProps {
  children: React.ReactElement[];
  selectedIndex?: number;
  className?: string;
  label?: string;
}

export const SelectGroup = (props: SelectGroupProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(props.selectedIndex || 0);

  const products: CLSelectGroupProduct[] = props.children.map((child, index) => ({
    id: index.toString(),
    value: `${index}`, // propType in CL is string
    selected: index === selectedIndex,
    name: child.props.title,
    icon: child.props.icon,
  }));

  return (
    <>
      <CL.SelectGroup
        className={classNames('of-selectgroup', props.className)}
        products={products}
        onChange={value => {
          if (value) {
            setSelectedIndex(parseInt(String(value), 10));
          }
        }}
        value={products.find(i => i.selected)?.id}
      />
      {props.children[selectedIndex]}
    </>
  );
};
