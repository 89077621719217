import * as CL from '@design-system/component-library';
import { PickupPointSearch } from './PickupPointSearch.js';
import { deliveryMethodMsg, t } from '../../../common/i18n/index.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { useSelector } from 'react-redux';
import type { CommonError } from '../../../common/types/errors.js';
import type {
  DeliveryMethodDetails,
  DeviceCheckoutDeliveryDetailsType,
} from '../DeviceCheckoutDeliveryDetailsUtils.js';
import type { PickupPoint } from '../../../generated/api/pickupPoint.js';
import type { State } from '../../../selfservice/common/store.js';

interface DeliveryMethodSelectionProps {
  deliveryMethods: DeliveryMethodDetails[];
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  saveDeliveryDetailsToState: (
    deliveryDetails: DeviceCheckoutDeliveryDetailsType,
    validationErrors?: CommonError[]
  ) => void;
  handlePickupPointSelected: (pickupPoint: PickupPoint) => void;
  selectedDeliveryMethod?: DeliveryMethodDetails;
  footerNote?: string;
  isEmployee?: boolean;
}

interface DeliveryMethodSelectorProps extends Omit<DeliveryMethodSelectionProps, 'handlePickupPointSelected'> {
  deliveryMethod: DeliveryMethodDetails;
}

const DeliveryMethodSelector = ({
  deliveryDetails,
  isEmployee,
  deliveryMethod,
  saveDeliveryDetailsToState,
  deliveryMethods,
}: DeliveryMethodSelectorProps) => {
  const updateDeliveryDetails = (method: DeliveryMethodDetails) => {
    saveDeliveryDetailsToState({
      address: deliveryDetails.address,
      addressType: deliveryDetails.addressType,
      deliveryMethodDescription: method.createDescription(),
      deliveryMethodType: method.type,
      deliveryPrice: method.price,
      recipientName: deliveryDetails.recipientName,
      recipientPhoneNumber: deliveryDetails.recipientPhoneNumber,
      pickupPoint: deliveryDetails.pickupPoint,
      shipmentType: deliveryDetails.shipmentType,
    });
  };

  return (
    <CL.Button
      className={
        deliveryMethod.type === deliveryDetails.deliveryMethodType && deliveryMethods.length > 1 ? 'selected' : ''
      }
      color="secondary"
      onClick={() => updateDeliveryDetails(deliveryMethod)}
    >
      <div className={`of-device-checkout-delivery-details__list--icon ${deliveryMethod.image}`} />
      <div className="of-device-checkout-delivery-details__list--container">
        <div className="of-device-checkout-delivery-details__list--shipping-type">
          {deliveryMethod.createDescription()}
        </div>
        <div className="of-device-checkout-delivery-details__list--notes">{deliveryMethod.notes}</div>
        {!isEmployee && (
          <div className="of-device-checkout-delivery-details__list--price">{formatSum(deliveryMethod.price)}</div>
        )}
      </div>
    </CL.Button>
  );
};

export const DeviceCheckoutDeliveryMethodSelection = ({
  selectedDeliveryMethod,
  deliveryMethods,
  deliveryDetails,
  saveDeliveryDetailsToState,
  isEmployee,
  handlePickupPointSelected,
  footerNote,
}: DeliveryMethodSelectionProps) => {
  const defaultPostalCode = useSelector((state: State) => state.deviceCheckout?.deliveryDetails?.address?.postalCode);
  return (
    <div className="of-device-checkout-delivery-details__list">
      <div>{t.G0QN(deliveryMethodMsg)}:</div>
      <div className="of-device-checkout-delivery-details__list__method-list">
        {deliveryMethods.map(method => (
          <DeliveryMethodSelector
            key={method.id}
            deliveryDetails={deliveryDetails}
            isEmployee={isEmployee}
            deliveryMethod={method}
            saveDeliveryDetailsToState={saveDeliveryDetailsToState}
            deliveryMethods={deliveryMethods}
          />
        ))}
      </div>

      {selectedDeliveryMethod?.searchService && (
        <PickupPointSearch
          service={selectedDeliveryMethod.searchService}
          onChangePostalCode={() => {
            saveDeliveryDetailsToState({
              ...deliveryDetails,
              pickupPoint: undefined,
            });
          }}
          selectedPickupPoint={deliveryDetails.pickupPoint}
          onSelectPickupPoint={handlePickupPointSelected}
          defaultPostalCode={defaultPostalCode}
        />
      )}

      {footerNote && (
        <span className="of-device-checkout-delivery-details__disclaimer of-device-checkout-delivery-details__disclaimer--grey">
          {footerNote}
        </span>
      )}
    </div>
  );
};
